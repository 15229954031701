import { Box, Icon, BoxProps } from 'components';

export type ModalCloseButtonProps = {
  onClose: () => void;
  margin?: BoxProps['margin'];
};

export const ModalCloseButton = ({ onClose, margin }: ModalCloseButtonProps): JSX.Element => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    flex="0 0 20px"
    width="20px"
    height="20px"
    cursor="pointer"
    margin={margin}
    onClick={onClose}
  >
    <Icon iconName="Cross" color="blue.900" size={11} />
  </Box>
);
