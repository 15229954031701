/**
 * Convert a number into percentage notation multiplying input value by a hundred. User can restrict the range of accepted values
 * by setting `normalize` param to `true`.
 * @param {number} percentValue the value to convert
 * @param {boolean} [normalize=false] if true, enforce 0... 1 as valid range.
 * @returns {number} the value converted into a percentage
 */
export const convertPercentValue = (percentValue: number, normalize = false): number => {
  if (percentValue < 0 && normalize) {
    return 0;
  }

  if (percentValue > 1 && normalize) {
    return 100;
  }

  return percentValue * 100;
};
