import { FunctionComponent, useMemo } from 'react';
import { useTheme } from 'styled';
import { useIntl } from 'providers';
import { Box, Select, Text, Input, Checkbox, Stack, Button } from 'components';
import {
  MAIN_DATE_RANGE_OPTIONS,
  COMPARISON_DATE_RANGE_OPTIONS,
  MAIN_DATE_RANGE_COLOR,
  COMPARISON_DATE_RANGE_COLOR,
} from '../DatePicker.config';
import {
  MainDateRangeOption,
  ComparisonDateRangeOption,
  FocusedDateInputType,
  DatePickerControllerShape,
} from '../DatePicker.types';
import { convertDateToString, shoulConfirmdButtonBeDisabled, getTranslatedOptions } from './DatePickerOptions.utils';

const FIELDS_COLUMN_WIDTH = '290px';

const INPUT_MARGIN_FROM_LABEL = '10px';

const Label: FunctionComponent = ({ children }) => (
  <Text fontWeight="bold" fontSize="2xs" color="light" textTransform="uppercase">
    {children}
  </Text>
);

export type DatePickerOptionsProps = {
  onMainDateRangeOptionChange: DatePickerControllerShape['onMainDateRangeOptionChange'];
  onComparisonDateRangeOptionChange: DatePickerControllerShape['onComparisonDateRangeOptionChange'];
  onComparisonActiveChange: DatePickerControllerShape['onComparisonActiveChange'];
  mainDateRangeOptionValue: DatePickerControllerShape['state']['mainDateRangeOptionValue'];
  comparisonDateRangeOptionValue: DatePickerControllerShape['state']['comparisonDateRangeOptionValue'];
  focusedDateInput: DatePickerControllerShape['state']['focusedDateInput'];
  mainDateRange: DatePickerControllerShape['state']['mainDateRange'];
  comparisonDateRange: DatePickerControllerShape['state']['comparisonDateRange'];
  isComparisonActive: DatePickerControllerShape['state']['isComparisonActive'];
  isComparisonDisabled: DatePickerControllerShape['state']['isComparisonDisabled'];
  periodOptions?: Array<MainDateRangeOption>;
  comparisonPeriodOptions?: Array<ComparisonDateRangeOption>;
  onInputFocusChange: (focusedInput: FocusedDateInputType) => void;
  onConfirm?: () => void;
  onCancel?: () => void;
};

export const DatePickerOptions = ({
  mainDateRangeOptionValue,
  onMainDateRangeOptionChange,
  onComparisonDateRangeOptionChange,
  onComparisonActiveChange,
  onInputFocusChange,
  focusedDateInput,
  mainDateRange,
  comparisonDateRange,
  comparisonDateRangeOptionValue,
  isComparisonActive,
  isComparisonDisabled,
  onConfirm,
  onCancel,
  periodOptions,
  comparisonPeriodOptions,
}: DatePickerOptionsProps): JSX.Element => {
  const { colors } = useTheme();
  const { formatMessage } = useIntl();

  const mainDateRangeTranslatedOptions = useMemo(() => {
    const filteredOptions = MAIN_DATE_RANGE_OPTIONS.filter((option) => periodOptions?.includes(option.value) ?? true);
    return getTranslatedOptions(filteredOptions, (value) => formatMessage({ id: value }));
  }, [formatMessage, periodOptions]);

  const comparisonDateRangeTranslatedOptions = useMemo(() => {
    const filteredOptions = COMPARISON_DATE_RANGE_OPTIONS.filter(
      (option) => comparisonPeriodOptions?.includes(option.value) ?? true
    );
    return getTranslatedOptions(filteredOptions, (value) => formatMessage({ id: value }));
  }, [formatMessage, comparisonPeriodOptions]);

  return (
    <Box height="100%" display="flex" flexDirection="column" justifyContent="space-between">
      <Stack spacing="20px" direction="column">
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <Label>{formatMessage({ id: 'global.period' })}</Label>
          <Box width={FIELDS_COLUMN_WIDTH} marginLeft={INPUT_MARGIN_FROM_LABEL}>
            <Select
              options={mainDateRangeTranslatedOptions}
              onChange={(value): void => onMainDateRangeOptionChange(value)}
              value={mainDateRangeOptionValue}
              width="100%"
              maxWidth="100%"
            />
          </Box>
        </Box>
        <Box w="100%" display="flex" alignItems="center" justifyContent="flex-end">
          <Label>{formatMessage({ id: 'global.from' })}</Label>
          <Box display="flex" alignItems="center" width={FIELDS_COLUMN_WIDTH} mL={INPUT_MARGIN_FROM_LABEL}>
            <Input
              readOnly
              spacing="md"
              width="130px"
              value={convertDateToString(mainDateRange.startDate)}
              mR={INPUT_MARGIN_FROM_LABEL}
              onFocus={(): void => onInputFocusChange('startDateMain')}
              style={focusedDateInput === 'startDateMain' ? { borderColor: colors[MAIN_DATE_RANGE_COLOR] } : undefined}
            />
            <Label>{formatMessage({ id: 'global.to' })}</Label>
            <Input
              readOnly
              spacing="md"
              width="130px"
              value={convertDateToString(mainDateRange.endDate)}
              mL={INPUT_MARGIN_FROM_LABEL}
              onFocus={(): void => onInputFocusChange('endDateMain')}
              style={focusedDateInput === 'endDateMain' ? { borderColor: colors[MAIN_DATE_RANGE_COLOR] } : undefined}
            />
          </Box>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <Label>{formatMessage({ id: 'datepicker.compare_to' })}</Label>
          <Box display="flex" width={FIELDS_COLUMN_WIDTH} marginLeft={INPUT_MARGIN_FROM_LABEL} alignItems="center">
            <Checkbox
              checked={isComparisonActive}
              disabled={isComparisonDisabled}
              onChange={(event): void => onComparisonActiveChange(event.target.checked)}
            />
            <Select
              isDisabled={!isComparisonActive}
              options={comparisonDateRangeTranslatedOptions}
              onChange={(value): void => onComparisonDateRangeOptionChange(value)}
              value={comparisonDateRangeOptionValue}
              width="100%"
              maxWidth="100%"
              mL="10px"
            />
          </Box>
        </Box>
        {isComparisonActive && (
          <Box w="100%" display="flex" alignItems="center" justifyContent="flex-end">
            <Label>{formatMessage({ id: 'global.from' })}</Label>
            <Box display="flex" alignItems="center" width={FIELDS_COLUMN_WIDTH} mL={INPUT_MARGIN_FROM_LABEL}>
              <Input
                readOnly
                spacing="md"
                width="130px"
                value={convertDateToString(comparisonDateRange?.startDate)}
                mL={INPUT_MARGIN_FROM_LABEL}
                mR={INPUT_MARGIN_FROM_LABEL}
                onFocus={(): void => onInputFocusChange('startDateComparison')}
                style={
                  focusedDateInput === 'startDateComparison'
                    ? { borderColor: colors[COMPARISON_DATE_RANGE_COLOR] }
                    : undefined
                }
              />
              <Label>{formatMessage({ id: 'global.to' })}</Label>
              <Input
                readOnly
                spacing="md"
                width="130px"
                value={convertDateToString(comparisonDateRange?.endDate)}
                mL={INPUT_MARGIN_FROM_LABEL}
                onFocus={(): void => onInputFocusChange('endDateComparison')}
                style={
                  focusedDateInput === 'endDateComparison'
                    ? { borderColor: colors[COMPARISON_DATE_RANGE_COLOR] }
                    : undefined
                }
              />
            </Box>
          </Box>
        )}
      </Stack>
      <Box display="flex" justifyContent="flex-end" marginTop="20px">
        <Button variant="transparent" onClick={onCancel}>
          {formatMessage({ id: 'global.cancel' })}
        </Button>
        <Button
          disabled={shoulConfirmdButtonBeDisabled(mainDateRange, comparisonDateRange, isComparisonActive)}
          hasShadow
          onClick={onConfirm}
        >
          {formatMessage({ id: 'global.apply' })}
        </Button>
      </Box>
    </Box>
  );
};
