import * as D from 'io-ts/Decoder';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import { HttpErrorResponse } from '../HttpErrorResponse';

/**
 * @description
 * Accept a promise, a codec and return a lazy-evaluated TaskEither
 */
export function lazyFetch<T>(
  fetch: () => Promise<Response>,
  decoder: D.Decoder<D.DecodeError, T>
): TE.TaskEither<D.DecodeError, T> {
  return pipe(
    TE.tryCatch(
      () =>
        fetch().then((res) => {
          if (!res.ok) {
            throw new HttpErrorResponse(`fetch failed with status: ${res.status}`, res.status);
          }
          return res.json();
        }),
      (error) => {
        throw error;
      }
    ),
    TE.chain((x) => TE.fromEither(decoder.decode(x)))
  );
}
