import { styled, theme, BaseProps, getBaseProps } from 'styled';

type Props = {
  level?: keyof Pick<HTMLElementTagNameMap, 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'>;
} & BaseProps;

const config = {
  sizes: {
    h1: theme.typography.fontSizes['2xl'],
    h2: theme.typography.fontSizes.xl,
    h3: theme.typography.fontSizes.lg,
    h4: theme.typography.fontSizes.md,
    h5: theme.typography.fontSizes.sm,
    h6: theme.typography.fontSizes.xs,
  },
};

const HeadingProxy = styled.h1<Props>(({ level = 'h1', fontSize, ...rest }) => ({
  ...getBaseProps(rest),
  ...(!fontSize ? { fontSize: config.sizes[level] } : undefined),
}));

export { HeadingProxy };
export type { Props };
