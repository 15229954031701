import { useReducer, useCallback, useRef, useEffect, ChangeEventHandler } from 'react';
import { ACTION_TYPE, State, SelectReducer, SelectReducerType } from './useSelect.reducer';
import { GetListItemPropsType, GetToggleButtonProps, GetListProps, RefKey } from '../types';
import { getListItemId } from '../utils';
/**
 * Nice features to implement:
 * - hide items that has been selected from the list, but this can also be done from oustide
 */
export type UseSelectShape<T> = State<T> & {
  selectItem: (item: T) => void;
  removeSelectedItem: () => void;
  highlightItem: (item?: T) => void;
  toggleIsOpen: (isOpen?: boolean) => void;
  getToggleButtonProps: GetToggleButtonProps;
  getListItemProps: GetListItemPropsType<T>;
  getListProps: GetListProps;
};
export type UseSelectParams<T> = {
  refKey?: RefKey;
  defaultSelectedItem?: State<T>['selectedItem'];
  defaultHighlighteIndex?: State<T>['highlightedIndex'];
  items: Array<T>;
  disableToggleOnSelect?: boolean;
};
export const useSelect = <T = unknown>({
  refKey = 'ref',
  items,
  defaultSelectedItem,
  defaultHighlighteIndex,
  disableToggleOnSelect = false,
}: UseSelectParams<T>): UseSelectShape<T> => {
  // make sure not to have duplicate values
  const uniqueItems = [...new Set(items)];
  const [state, dispatch] = useReducer<SelectReducerType<T>>(SelectReducer, {
    isOpen: false,
    items: uniqueItems,
    selectedItem: defaultSelectedItem,
    highlightedIndex: defaultHighlighteIndex,
  });
  const { selectedItem, highlightedIndex, isOpen, items: StateIems } = state;
  const toggleRef = useRef<HTMLButtonElement>(null);
  const listRef = useRef<HTMLUListElement>(null);
  const listItemsRefs = useRef<{ [key: string]: HTMLElement }>({});
  const toggleIsOpen = useCallback(
    (open?): void => dispatch({ type: ACTION_TYPE.TOGGLE_OPEN, payload: { isOpen: open } }),
    [dispatch]
  );
  const highlightItem = useCallback(
    (item?): void => dispatch({ type: ACTION_TYPE.HIGHLIGHT_ITEM, payload: { item } }),
    [dispatch]
  );
  const highlightNextItem = useCallback((): void => dispatch({ type: ACTION_TYPE.HIGHLIGHT_NEXT_ITEM }), [dispatch]);
  const highlightPrevItem = useCallback((): void => dispatch({ type: ACTION_TYPE.HIGHLIGHT_PREV_ITEM }), [dispatch]);
  const selectItem = useCallback(
    (item: T): void => dispatch({ type: ACTION_TYPE.SELECT_ITEM, payload: { item } }),
    [dispatch]
  );
  const removeSelectedItem = useCallback((): void => dispatch({ type: ACTION_TYPE.REMOVE_SELECTED_ITEM }), [dispatch]);
  const getListProps: GetListProps = useCallback(() => {
    return {
      [refKey]: listRef,
      role: 'listbox',
      tabIndex: -1,
      onKeyDown: (e): void => {
        switch (e.key) {
          case 'ArrowDown':
            highlightNextItem();
            break;
          case 'ArrowUp':
            highlightPrevItem();
            break;
          case 'Enter':
            selectItem(items[highlightedIndex || 0]);
            toggleIsOpen();
            break;
        }
      },
    };
  }, [refKey, highlightNextItem, selectItem, items, highlightedIndex, highlightPrevItem, toggleIsOpen]);
  const getToggleButtonProps: GetToggleButtonProps = useCallback(() => {
    return {
      onClick: (): void => {
        toggleIsOpen();
      },
      'aria-haspopup': 'listbox',
      'aria-expanded': isOpen,
    };
  }, [toggleIsOpen, isOpen]);
  const getListItemProps: GetListItemPropsType<T> = useCallback(
    ({ item }) => {
      const listItemId = getListItemId(item);
      return {
        [refKey]: (ref: HTMLLIElement): void => {
          listItemsRefs.current[listItemId] = ref;
        },
        role: 'option',
        'aria-selected': false,
        id: listItemId,
        onClick: (): void => {
          selectItem(item);
          if (!disableToggleOnSelect) {
            toggleIsOpen();
          }
        },
        onMouseEnter: (): void => {
          highlightItem(item);
          listItemsRefs.current[listItemId].setAttribute('aria-selected', 'true');
        },
        onMouseLeave: (): void => {
          highlightItem();
          listItemsRefs.current[listItemId].setAttribute('aria-selected', 'false');
        },
        onKeyDown: (): void => {
          highlightItem((highlightedIndex || 0) + 1);
        },
      };
    },
    [refKey, selectItem, toggleIsOpen, highlightItem, disableToggleOnSelect, highlightedIndex]
  );
  const outsideClickHandler: ChangeEventHandler<HTMLElement> = useCallback(
    (event) => {
      if (listRef && listRef.current && event.target && isOpen) {
        if (!listRef?.current?.contains(event.target)) {
          toggleIsOpen(false);
          highlightItem();
        }
      }
    },
    [isOpen, highlightItem, toggleIsOpen]
  );
  useEffect(() => {
    window.addEventListener('click', outsideClickHandler as unknown as EventListenerObject);
    return (): void => window.removeEventListener('click', outsideClickHandler as unknown as EventListenerObject);
  }, [outsideClickHandler]);
  useEffect(() => {
    if (!isOpen) {
      listItemsRefs.current = {};
    }
    if (isOpen) {
      toggleRef.current?.blur();
      listRef.current?.focus();
    }
    // auto scroll to selected element when opening the modal
    if (isOpen && selectedItem) {
      const listItemId = getListItemId(selectedItem);
      if (listItemsRefs.current[listItemId] && Boolean(listItemsRefs.current[listItemId].scrollIntoView)) {
        listItemsRefs.current[listItemId].scrollIntoView({
          behavior: 'auto',
          block: 'nearest',
          inline: 'nearest',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);
  return {
    selectedItem,
    highlightedIndex,
    selectItem,
    isOpen,
    items: StateIems,
    toggleIsOpen,
    getToggleButtonProps,
    getListItemProps,
    getListProps,
    highlightItem,
    removeSelectedItem,
  };
};
