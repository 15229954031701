import { FSA } from 'utilityTypes';

const AUTH_ACTION_TYPES = {
  SIGNIN: '@auth/SIGNIN',
  SIGNOUT: '@auth/SIGNOUT',
  VALIDATE: '@auth/VALIDATING',
  SIGNIN_ERROR: '@auth/ERROR',
} as const;

// Sign in
interface SignIn extends FSA<string> {
  type: typeof AUTH_ACTION_TYPES.SIGNIN;
  payload: string;
}

const signIn = (username: string): SignIn => ({ type: AUTH_ACTION_TYPES.SIGNIN, payload: username });

// Sign out
interface SignOut extends FSA {
  type: typeof AUTH_ACTION_TYPES.SIGNOUT;
}

const signOut = (): SignOut => ({ type: AUTH_ACTION_TYPES.SIGNOUT });

// Validate
interface Validate extends FSA {
  type: typeof AUTH_ACTION_TYPES.VALIDATE;
}

const validate = (): Validate => ({ type: AUTH_ACTION_TYPES.VALIDATE });

// Error
interface Error extends FSA {
  type: typeof AUTH_ACTION_TYPES.SIGNIN_ERROR;
}

const error = (): Error => ({ type: AUTH_ACTION_TYPES.SIGNIN_ERROR });

type AuthActions = SignIn | SignOut | Validate | Error;

// State and reducer
type AuthenticatedState = {
  authenticated: true;
  username: string;
  isValidating: boolean;
};

type NotAuthenticatedState = {
  authenticated: false;
  username?: never;
  isValidating: boolean;
};

type AuthState = AuthenticatedState | NotAuthenticatedState;

const authReducer = (state: AuthState, action: AuthActions): AuthState => {
  switch (action.type) {
    case AUTH_ACTION_TYPES.VALIDATE:
      return { authenticated: false, isValidating: true };
    case AUTH_ACTION_TYPES.SIGNIN_ERROR:
      return { authenticated: false, isValidating: false };
    case AUTH_ACTION_TYPES.SIGNIN:
      return { authenticated: true, username: action.payload, isValidating: false };
    case AUTH_ACTION_TYPES.SIGNOUT:
      return { authenticated: false, isValidating: false };
    default:
      return state;
  }
};

export default authReducer;
export { signIn, signOut, validate, error };
