import { theme } from 'styled';

const GROUP_COLORS = [theme.colors['cyan.500'], theme.colors['purple.500'], theme.colors['orange.400']];

const getComparisonGroupColor = (groupId: number, groupColors = GROUP_COLORS): string => {
  if (groupId <= 0) {
    return groupColors[0];
  }

  if (groupId > groupColors.length) {
    return groupColors[groupColors.length - 1];
  }

  return groupColors[groupId - 1];
};

export { getComparisonGroupColor, GROUP_COLORS };
