import { Modal, ModalProps } from '../modal';
import { Box } from '../box';
import { Button } from '../button';

interface AlertProps {
  isOpen: ModalProps['isOpen'];
  onClose: ModalProps['onClose'];
  onConfirm: () => void;
  content: string | JSX.Element;
  cancelLabel: string;
  confirmLabel: string;
}

const ALERT_MAX_WIDTH = 420;

const Alert = ({
  isOpen,
  onClose = (): void => {},
  onConfirm = (): void => {},
  content,
  cancelLabel,
  confirmLabel,
}: AlertProps): JSX.Element => (
  <Modal isOpen={isOpen} onClose={onClose} maxWidth={`${ALERT_MAX_WIDTH}px`}>
    <Box display="flex" flexDirection="column" justifyContent="center" height="140px">
      {content}
      <Box display="flex" justifyContent="space-between" mT="auto">
        <Button variant="transparent" onClick={onClose}>
          {cancelLabel}
        </Button>
        <Button onClick={onConfirm}>{confirmLabel}</Button>
      </Box>
    </Box>
  </Modal>
);

export { Alert };
