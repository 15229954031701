import ReactDOM from 'react-dom';
import { App } from './App';
import { AppProviders } from './App.providers';
import startMockServer from './startMockServer';
import reportWebVitals from './reportWebVitals';
import moment from 'moment';
import { StrictMode } from 'react';

if (window.APP_LOCALE === 'it') {
  require('moment/locale/it');
  moment.updateLocale('it', null);
}

if (window.APP_LOCALE === 'es') {
  require('moment/locale/es');
  moment.updateLocale('es', null);
}

if (window.APP_LOCALE === 'pt') {
  require('moment/locale/pt');
  moment.updateLocale('pt', null);
}

if (window.APP_LOCALE === 'el') {
  require('moment/locale/el');
  moment.updateLocale('el', null);
}

if (window.APP_LOCALE === 'fr') {
  require('moment/locale/fr');
  moment.updateLocale('fr', null);
}

startMockServer();

ReactDOM.render(
  <StrictMode>
    <AppProviders>
      <App />
    </AppProviders>
  </StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
