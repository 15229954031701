import { css, styled } from 'styled';

export const Tooltip = styled.div<{ backgroundColor: string; boxShadow: string; fontSize: number; color: string }>(
  ({ backgroundColor, boxShadow, fontSize, color }) => css`
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    background: ${backgroundColor};
    box-shadow: ${boxShadow};

    padding: 2px 7px;
    & > span {
      color: ${color};
      font-size: ${fontSize}px;
    }
  `
);

export const MapWrapper = styled.div`
  height: 100%;
  position: relative;
  min-height: 600px;
  & > svg {
    width: 100%;
    height: 100%;
  }
`;
