import { FunctionComponent } from 'react';
import { theme, BaseProps } from 'styled';
import { Box } from '../box';

export type HeaderProps = BaseProps;

const config: BaseProps = {
  bgColor: theme.colors.white,
  fontFamily: theme.typography.fontFamilies.default,
  borderBottom: `1px solid ${theme.colors['gray.300']}`,
  p: '20px',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  justifyContent: { initial: 'start', sm: 'center' },
};

const Header: FunctionComponent<HeaderProps> = ({ children, ...rest }) => (
  <Box {...config} {...rest}>
    {children}
  </Box>
);

export { Header };
