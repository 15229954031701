import { useState } from 'react';
import { useQuery } from 'react-query';

import { http } from 'utils/http-request';
import { getApiBasePath } from 'providers';

import { remoteSearchResponse, RemoteSearchResponse, standardizeResponse } from './useRemoteSearch.decoder';
import { withCodec } from 'utils/decoder/withCodec';

type FetchQuery = { target?: string; text: string; endpoint: string };

type UseRemoteSearch = RemoteStates<unknown>;

type UseRemoteSearchParams = {
  enabled?: boolean;
  key: string;
  target?: string;
  text: string;
  endpoint: string;
};

const fetchQuery = async ({ target, text, endpoint }: FetchQuery): Promise<Response> =>
  http.get`/${endpoint}`({
    domain: getApiBasePath('BRANDS'),
    query: new URLSearchParams({
      text,
      ...(target ? { target } : undefined),
    }),
  });

const remoteSearch = withCodec<FetchQuery, RemoteSearchResponse>(remoteSearchResponse)(fetchQuery);

const useRemoteSearch = ({ enabled = false, target, text, key, endpoint }: UseRemoteSearchParams): UseRemoteSearch => {
  const [retry, setRetry] = useState();
  const { data, error, isFetching } = useQuery(
    [`remote-search-${key}`, retry, text, enabled],
    () => remoteSearch({ target, text, endpoint }),
    {
      enabled,
    }
  );

  const res = data ? standardizeResponse(data) : null;

  return {
    data: data ? res?.data.values : null,
    error,
    onRetry: (): React.Dispatch<React.SetStateAction<undefined>> => setRetry,
    isLoading: isFetching,
  };
};

export { useRemoteSearch };
