import { styled, css } from 'styled';

type StyledDatePickerProps = {
  isComparisonActive: boolean;
};

export const DatePicker = styled('div')<StyledDatePickerProps>(
  ({ theme: { colors, typography }, isComparisonActive }) => css`
    // THIS WAS PORTED FROM MANAGER
    // TODO: refactor
    .CalendarDay__custom_inner {
      display: table;
      width: 100%;
      height: 100%;
      border-collapse: collapse;

      > div {
        margin: 0;
        padding: 0;
        display: table-cell;
        vertical-align: middle;
      }
    }

    .CalendarDay__comparison_day {
      background-color: ${colors['orange.400']}; // TODO: replace with primary
      color: ${typography.colors['default']};
    }

    .CalendarDay__main_day {
      background-color: ${colors['blue.500']}; // TODO: replace with primary
      color: ${colors['white']};
    }

    .CalendarDay__default {
      border: 1px solid ${colors['gray.300']}; // TODO: replace with input border color

      &:hover {
        position: relative;

        &:after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: ${isComparisonActive ? colors['orange.400'] : colors['blue.500']};
          opacity: 0.4;
        }
      }
    }

    .CalendarDay__selected,
    .CalendarDay__selected_span {
      background-color: ${isComparisonActive ? colors['orange.400'] : colors['blue.500']};
      border-color: ${isComparisonActive ? colors['orange.400'] : colors['blue.500']};
      color: ${isComparisonActive ? typography.colors.default : colors['white']};

      &:hover {
        background-color: ${isComparisonActive ? colors['orange.400'] : colors['blue.500']};
        &:after {
          background-color: ${colors['white']};
        }
      }

      .CalendarDay__custom_inner {
        position: relative;

        &.CalendarDay__comparison_day.CalendarDay__main_day {
          background-color: transparent;
          color: ${isComparisonActive ? typography.colors.default : colors['white']};

          &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 4px;
            background-color: ${isComparisonActive ? colors['blue.500'] : colors['orange.400']};
          }
        }
      }
    }

    .WithQuotes {
      .FieldLabel {
        margin-right: 0;
      }

      .Checkbox__Value {
        margin-left: 8px;
      }
    }
  `
);
