import { MainDateRangeOption, DateRange, ComparisonDateRangeOption, FormattedDateRange } from './DatePicker.types';
import moment from 'moment';

export const getDateRangeByOptionType = (option: MainDateRangeOption): DateRange => {
  let startDate = null;
  let endDate = null;

  switch (option) {
    default:
      break;
    case 'yesterday':
      startDate = moment().subtract(1, 'days');
      endDate = moment().subtract(1, 'days');
      break;

    case 'last7days':
      startDate = moment().subtract(7, 'days');
      endDate = moment().subtract(1, 'days');
      break;
    case 'lastweek':
      startDate = moment().weekday(0).subtract(7, 'days');
      endDate = moment().weekday(0).subtract(1, 'days');
      break;

    case 'last30days':
      startDate = moment().subtract(30, 'days');
      endDate = moment().subtract(1, 'days');
      break;

    case 'last90days':
      startDate = moment().subtract(90, 'days');
      endDate = moment().subtract(1, 'days');
      break;

    case 'lastmonth':
      startDate = moment().date(1).subtract(1, 'month');
      endDate = moment().date(1).subtract(1, 'days');
      break;
  }

  return { startDate, endDate };
};

export const getComparisonDateRangeByOptionType = (
  option: ComparisonDateRangeOption,
  currentDateRange: DateRange
): DateRange => {
  let startDate = currentDateRange.startDate || null;
  let endDate = currentDateRange.endDate || null;

  if (!startDate || !endDate) {
    return { startDate, endDate };
  }

  const rangeDays = endDate.diff(startDate, 'days');

  switch (option) {
    default:
      break;
    case 'previousperiod':
      endDate = startDate.clone().subtract(1, 'days');
      startDate = endDate.clone().subtract(rangeDays, 'days');
      break;

    case '52weeksbefore':
      startDate = startDate.clone().subtract(52, 'weeks');
      endDate = endDate.clone().subtract(52, 'weeks');
      break;
  }

  return { startDate, endDate };
};

export const formatDateRange = (dateRange: DateRange | null, format = 'Y-MM-DD'): FormattedDateRange => ({
  startDate: dateRange?.startDate?.format(format) || null,
  endDate: dateRange?.endDate?.format(format) || null,
});
