import { createContext } from 'react';
import { FiltersState, DateRange } from './FiltersProvider.reducer';

export type FiltersContextShape = {
  filters: FiltersState;
  addGroupIds: (groupIds: FiltersState['group_ids']) => void;
  removeGroupIds: (groupIds: FiltersState['group_ids']) => void;
  setGroupIds: (groupIds: FiltersState['group_ids']) => void;
  setBrandId: (brandId: FiltersState['brand_id']) => void;
  setDateRange: (dateRange: DateRange) => void;
  setComparisonDateRange: (dateRange: DateRange) => void;
  setDatePickerState: (state: FiltersState['datePickerState']) => void;
  getComparisonGroupColor: (groupId: number) => string;
};

export const FiltersContext = createContext<FiltersContextShape | undefined>(undefined);
