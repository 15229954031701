import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import isInclusivelyBeforeDay from 'react-dates/lib/utils/isInclusivelyBeforeDay';
import isInclusivelyAfterDay from 'react-dates/lib/utils/isInclusivelyAfterDay';
import { DayPickerRangeController } from 'react-dates';
import moment from 'moment';
import * as S from './DatePickerCalendar.theme';
import { DayPickerRangeControllerShape, DatePickerControllerShape } from '../DatePicker.types';
import { DEFAULT_NUMBER_OF_VISIBLE_MONTHS } from '../DatePicker.config';

moment.locale(window.APP_LOCALE);

type DatePickerCalendarProps = {
  onDatesChange: DayPickerRangeControllerShape['onDatesChange'];
  onFocusChange: DayPickerRangeControllerShape['onFocusChange'];
  focusedInput: DatePickerControllerShape['state']['focusedCalendarInput'];
  activeDateRange: DatePickerControllerShape['state']['activeDateRangeType'];
  mainDateRange: DatePickerControllerShape['state']['mainDateRange'];
  comparisonDateRange: DatePickerControllerShape['state']['comparisonDateRange'];
  numberOfVisibleMonths: number;
};

export const DatePickerCalendar = ({
  activeDateRange,
  onDatesChange,
  focusedInput,
  onFocusChange,
  mainDateRange,
  comparisonDateRange,
  numberOfVisibleMonths = DEFAULT_NUMBER_OF_VISIBLE_MONTHS,
}: DatePickerCalendarProps): JSX.Element => {
  const { startDate: startDateComparison = null, endDate: endDateComparison = null } = comparisonDateRange || {};
  const { startDate, endDate } = mainDateRange;
  const dateRange = activeDateRange === 'comparison' && comparisonDateRange ? comparisonDateRange : mainDateRange;

  return (
    <S.DatePicker
      isComparisonActive={Boolean(startDateComparison || endDateComparison) && activeDateRange === 'comparison'}
    >
      <DayPickerRangeController
        hideKeyboardShortcutsPanel
        minimumNights={0}
        startDate={dateRange.startDate}
        endDate={dateRange.endDate}
        onDatesChange={onDatesChange}
        focusedInput={focusedInput}
        onFocusChange={onFocusChange}
        numberOfMonths={numberOfVisibleMonths}
        isOutsideRange={(day): boolean => !isInclusivelyBeforeDay(day, moment())}
        initialVisibleMonth={(): moment.Moment => moment().subtract(numberOfVisibleMonths - 1, 'months')}
        orientation="horizontal"
        renderDayContents={(day): JSX.Element => {
          // THIS WAS PORTED FROM MANAGER
          // TODO: refactor
          const isComparisonDay =
            startDateComparison &&
            endDateComparison &&
            isInclusivelyAfterDay(day, startDateComparison) &&
            isInclusivelyBeforeDay(day, endDateComparison);

          const isMainDateRangeDay =
            startDate && endDate && isInclusivelyAfterDay(day, startDate) && isInclusivelyBeforeDay(day, endDate);

          return (
            <div
              className={`CalendarDay__custom_inner ${isComparisonDay ? 'CalendarDay__comparison_day' : ''} ${
                isMainDateRangeDay ? 'CalendarDay__main_day' : ''
              }`}
            >
              <div>{day.format('D')}</div>
            </div>
          );
        }}
      />
    </S.DatePicker>
  );
};
