import { FunctionComponent } from 'react';
import { Card, CardProps } from 'components/card';
import { Colors } from 'styled';
import { LiteralUnion } from 'utilityTypes';

export type ModalContentProps = {
  minHeight?: CardProps['minHeight'];
  minWidth?: CardProps['minWidth'];
  maxWidth?: CardProps['maxWidth'];
  maxHeight?: CardProps['maxHeight'];
  height?: CardProps['height'];
  width?: CardProps['width'];
  bgColor?: LiteralUnion<Colors, string>;
  fullScreenOnMobile?: boolean;
};

export const ModalContent: FunctionComponent<ModalContentProps> = ({
  children,
  minWidth = { initial: 'auto', xs: '350px', sm: '400px' },
  maxWidth = '100%',
  minHeight = '250px',
  maxHeight,
  width = { initial: '100%', xs: 'auto' },
  height,
  bgColor = 'white',
  fullScreenOnMobile = false,
}) => (
  <Card
    p="0"
    m="0"
    height={
      fullScreenOnMobile
        ? { initial: '100vh', xs: 'auto' || (typeof height === 'object' ? { ...height } : height) }
        : height
    }
    width={
      fullScreenOnMobile
        ? { initial: '100vw', xs: 'auto' || (typeof width === 'object' ? { ...width } : width) }
        : width
    }
    minHeight={minHeight}
    minWidth={minWidth}
    maxWidth={maxWidth}
    maxHeight={maxHeight}
    bgColor={bgColor}
    borderRadius={fullScreenOnMobile ? { initial: '0', xs: '8px' } : '8px'}
  >
    {children}
  </Card>
);
