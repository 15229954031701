import { http } from 'utils/http-request';
import { getApiBasePath } from 'providers';
import { withCodec } from 'utils/decoder/withCodec';

import { FilterTypesDecoder } from './FilterTypesProvider.codec';

export const fetchFilterTypes = async (): Promise<Response> =>
  http.get`/filter_types.json`({
    domain: getApiBasePath('BRANDS'),
  });

export const getFilterTypes = withCodec(FilterTypesDecoder)(fetchFilterTypes);
