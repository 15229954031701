import 'whatwg-fetch';
import { G } from 'enums/global';
import { RequestHandlerParams, HTTP_METHODS, ContentTypes } from './types';

export const handleRequestMethod = async ({
  method,
  path,
  body,
  mediaType = 'application/json',
  headers,
  query,
  needsAuth = true,
  domain = '',
  authTokenProvider,
}: RequestHandlerParams): Promise<Response> => {
  const uri = method === HTTP_METHODS.GET ? `${domain}${path}?${query ?? ''}` : `${domain}${path}`;

  // Build headers
  const requestHeaders = new Headers();
  requestHeaders.append('Accept-Language', getLocale());
  requestHeaders.append('Content-Type', mediaType);

  if (headers) {
    /*
     * https://developer.mozilla.org/en-US/docs/Web/API/Headers/entries
     * The Headers.entries() method returns an iterator allowing to go through all key/value pairs contained in this object.
     * The both the key and value of each pairs are ByteString objects.
     * append('Content-Type', mediaType)
     */
    for (const keys of headers.entries()) {
      requestHeaders.append(keys[0], keys[1]);
    }
  }

  if (needsAuth) {
    const token = authTokenProvider ? authTokenProvider() : defaultTokenProvider();
    addAuthorizationHeader(requestHeaders, token);
  }

  const isCreateUpdate = method === HTTP_METHODS.PUT || method === HTTP_METHODS.POST;

  return window.fetch(uri, {
    method,
    headers: requestHeaders,
    ...(isCreateUpdate ? { body: getBodyByMediaType(body || {}, mediaType) } : undefined),
  });
};

export const getBodyByMediaType = (body: Record<string, unknown> | FormData, mediaType: ContentTypes): BodyInit => {
  if (body instanceof FormData && mediaType === 'multipart/form-data') {
    return body;
  }

  const content = { ...body };
  return JSON.stringify(content, (x, v) => (x !== '_ref' ? v : undefined));
};

/**
 * Create a valid path starting from a `TemplateStringsArray`.
 *
 * @example
 * ```ts
 * // apply to your function
 * const fn = (strings: TemplateStringsArray, ...args: (string | number)[]): string => buildPath(strings, args);
 *
 * // prints out as /path/with/1/variable/
 * const pathWithNumber = fn`/path/with/${1}/variable/`;
 * ```
 *
 * @param path - the strings part of the path.
 * @param args - the dynamic parts of the path.
 * @returns - a path built interpolating static strings and dynamic parts.
 */
export const buildPath = (path: TemplateStringsArray, args?: (string | number)[]): string => {
  if (args) {
    return path.reduce((acc, currentString, index) => acc + currentString + (args[index] || ''), '');
  }

  return path.toString();
};

/**
 * Add authorization to request headers
 * @param headers - existing headers
 * @param authToken - the auth token as string
 *
 * @returns The headers injected within the authentication token.
 */
export const addAuthorizationHeader = (headers: Headers, authToken: string): void =>
  headers.append('Authorization', `${authToken}`);

/**
 * Provide a default auth token string.
 *
 * @returns The default token auth string.
 */
export const defaultTokenProvider = (): string => `Token token="${localStorage.getItem(G.localStorage.AUTH_TOKEN)}"`;

export const getLocale = (): string => window.APP_LOCALE || window.navigator.userLanguage || window.navigator.language;

export const getInputTypeByFilterKey = (filter: string): string => {
  switch (filter) {
    // TODO: convert to enum in the next refactoring pr
    case 'geo_postal_code':
      return 'number';
    default:
      return 'text';
  }
};
