import { pipe } from 'fp-ts/function';
import { isRight } from 'fp-ts/Either';
import * as D from 'io-ts/Decoder';

const remoteSearchResponse = D.struct({
  success: D.boolean,
  info: D.array(D.string),
  data: D.union(
    D.struct({ cities: D.array(D.string) }),
    D.struct({ postal_codes: D.array(D.string) }),
    D.struct({ regions: D.array(D.string) }),
    D.struct({ areas: D.array(D.string) })
  ),
});

const parsedResponseDecoder = D.struct({
  success: D.boolean,
  info: D.array(D.string),
  data: D.struct({ values: D.array(D.string) }),
});

export type RemoteSearchResponse = D.TypeOf<typeof remoteSearchResponse>;
export type ParsedResponseDecoder = D.TypeOf<typeof parsedResponseDecoder>;

/**
 * Parse a jsonResponse and seek if data contains any known keys.
 * At the current state, only geographical area keys are known, so this
 * would be probably subject to change in the future.
 */
const parseRemoteSearch: D.Decoder<unknown, ParsedResponseDecoder> = pipe(
  remoteSearchResponse,
  D.parse((jsonResponse) => {
    if ('cities' in jsonResponse.data) {
      const { data, ...rest } = jsonResponse;
      return D.success({ ...rest, data: { values: data.cities } });
    }

    if ('postal_codes' in jsonResponse.data) {
      const { data, ...rest } = jsonResponse;
      return D.success({ ...rest, data: { values: data.postal_codes } });
    }

    if ('regions' in jsonResponse.data) {
      const { data, ...rest } = jsonResponse;
      return D.success({ ...rest, data: { values: data.regions } });
    }

    if ('areas' in jsonResponse.data) {
      const { data, ...rest } = jsonResponse;
      return D.success({ ...rest, data: { values: data.areas } });
    }

    return D.failure(remoteSearchResponse, 'remoteSearchResultParser');
  })
);

/**
 * Provide a way to easily access to remote search results, by returning a standardized result.
 */
const standardizeResponse = (jsonResponse: RemoteSearchResponse): ParsedResponseDecoder | null => {
  const parsedRes = parseRemoteSearch.decode(jsonResponse);

  if (isRight(parsedRes)) {
    return parsedRes.right;
  }

  return null;
};

export { remoteSearchResponse, standardizeResponse };
