import { BenchmarkThreshold } from 'components/market-benchmark';

/**
 * Return the percentage by range.
 * @param {number} the percentage value to show in ProgressBar component
 * @param {number} the maximum value that define the ProgressBar range
 * @param {BenchmarkThreshold} the values of benchmark thresholds
 * @returns {number} the value to render in ProgressBar component in decimal format
 */

export const renderPercentageByRange = (
  n: number,
  max: number,
  { minThreshold, midThreshold, maxThreshold }: BenchmarkThreshold
): number => {
  if (max <= minThreshold) {
    return n / minThreshold;
  }

  if (max <= midThreshold) {
    return n / midThreshold;
  }

  return n / maxThreshold;
};
