import { FormEvent, useReducer } from 'react';
import { useIntl } from 'providers';

import { G } from 'enums/global';
import { useDisclosure } from 'hooks';
import { Box, Modal, Icon, Input, Button } from 'components';

import { APP_SETTINGS } from '../../App.settings';

import { OverrideFab } from './OverrideFab';
import envOverrideReducer, { setBrandsProvider, setStatisticsProvider, EnvOverrideState } from './EnvOverride.reducer';

const EnvOverrideForm = (): JSX.Element | null => {
  const initialState: EnvOverrideState = {
    brandsProvider: localStorage.getItem(G.localStorage.UALA_BRANDS_PROVIDER) || 'https://env1-api.uala.it/brands/v1',

    statisticsProvider:
      localStorage.getItem(G.localStorage.UALA_STATISTICS_PROVIDER) || 'https://env10-api.uala.it/statistics/v1',
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { formatMessage } = useIntl();
  const [state, dispatch] = useReducer(envOverrideReducer, initialState);

  if (!APP_SETTINGS.UALA_ALLOW_PARAMS_CUSTOMIZATION) {
    return null;
  }

  const handleBrandsProviderChange = (val: string): void => {
    dispatch(setBrandsProvider(val));
  };

  const handleStatisticsProviderChange = (val: string): void => {
    dispatch(setStatisticsProvider(val));
  };

  const updateOrRemoveFromStorage = (key: string, value?: string): void => {
    if (value) {
      localStorage.setItem(key, value);
    }

    if (!value && key in localStorage) {
      localStorage.removeItem(key);
    }
  };

  const handleEnvOverrideSubmit = (event: FormEvent): void => {
    event.preventDefault();
    const isOverrideActive = Boolean(state.brandsProvider || state.statisticsProvider);

    updateOrRemoveFromStorage(G.localStorage.UALA_BRANDS_PROVIDER, state.brandsProvider);
    updateOrRemoveFromStorage(G.localStorage.UALA_STATISTICS_PROVIDER, state.statisticsProvider);
    updateOrRemoveFromStorage(G.localStorage.UALA_OVERRIDE_PARAMS, String(isOverrideActive));

    onClose();
  };

  return (
    <>
      <OverrideFab onClick={onOpen}>
        <Icon iconName="Gear" color="white" />
      </OverrideFab>
      <Modal isOpen={isOpen} onClose={onClose} title="Env Settings" headerWithBorder>
        <Box
          as="form"
          onSubmit={handleEnvOverrideSubmit}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          gap="12px"
        >
          <Input
            name="compaby-dashboard-ep"
            placeholder="UALA_BRANDS_PROVIDER"
            onChange={handleBrandsProviderChange}
            defaultValue={state.brandsProvider}
          />
          <Input
            name="statistics-ep"
            placeholder="UALA_STATISTICS_PROVIDER"
            onChange={handleStatisticsProviderChange}
            defaultValue={state.statisticsProvider}
          />
          <Button type="submit">{formatMessage({ id: 'global.apply' })}</Button>
        </Box>
      </Modal>
    </>
  );
};

export { EnvOverrideForm };
