import { Button, Text, Icon, Box } from 'components';
import { useIntl } from 'providers';

type DateRangeButtonProps = {
  dateRange: {
    startDate: string;
    endDate: string;
  };
  comparisonDateRange?: {
    startDate?: string;
    endDate?: string;
  };
  onClick?: () => void;
};

export const DateRangeButton = ({
  dateRange,
  comparisonDateRange,
  onClick = (): void => {},
}: DateRangeButtonProps): JSX.Element => {
  const { formatMessage } = useIntl();

  return (
    <Box
      display="flex"
      flexDirection={{ initial: 'column', sm: 'row' }}
      alignItems={{ initial: 'flex-start', sm: 'center' }}
    >
      <Button tone="light" variant="outline" size="sm" onClick={onClick}>
        <Text mR="5px" color="xlight" fontSize="2xs">
          {formatMessage({ id: 'global.from' })}
        </Text>
        <Text color="default" fontSize="md" fontWeight="normal" letterSpacing="0">
          {dateRange.startDate}
        </Text>
        <Text mL="20px" mR="5px" color="xlight" fontSize="2xs">
          {formatMessage({ id: 'global.to' })}
        </Text>
        <Text color="default" fontSize="md" fontWeight="normal" letterSpacing="0">
          {dateRange.endDate}
        </Text>
        <Box mL="10px">
          <Icon iconName="Selector" size={11} />
        </Box>
      </Button>
      {comparisonDateRange && comparisonDateRange.startDate && comparisonDateRange.endDate && (
        <Box display="flex" alignItems="center" mL="10px" mT={{ initial: '15px', sm: '0' }}>
          <Text textTransform="uppercase" fontWeight="bold" color="light" fontSize="2xs" letterSpacing="1px">
            vs {formatMessage({ id: 'global.from' })} {comparisonDateRange.startDate}{' '}
            {formatMessage({ id: 'global.to' })} {comparisonDateRange.endDate}
          </Text>
        </Box>
      )}
    </Box>
  );
};
