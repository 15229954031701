import { G } from 'enums/global';

const clearClientSession = (): void => {
  localStorage.removeItem(G.localStorage.AUTH_TOKEN);
  localStorage.removeItem(G.localStorage.USERNAME);
};

const registerClientSession = (token: string, name: string): void => {
  localStorage.setItem(G.localStorage.AUTH_TOKEN, token);
  localStorage.setItem(G.localStorage.USERNAME, name);
};

const getClientSession = (): { token: string | null; username: string | null } => {
  const token = localStorage.getItem(G.localStorage.AUTH_TOKEN);
  const username = localStorage.getItem(G.localStorage.USERNAME);
  return { token, username };
};

export { clearClientSession, registerClientSession, getClientSession };
