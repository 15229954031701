import { COLORS, BOX_SHADOWS, MEDIA, TYPOGRAPHY, BREAKPOINTS } from './tokens';

export const theme = {
  colors: COLORS,
  boxShadows: BOX_SHADOWS,
  media: MEDIA,
  typography: TYPOGRAPHY,
  breakpoints: BREAKPOINTS,
} as const;

export type Theme = typeof theme;
