import { APP_SETTINGS } from '../../App.settings';
import { G } from 'enums/global';

export const UALA_SUBDOMAINS = {
  BRANDS: 'brands',
  STATISTICS: 'statistics',
  MANAGER: 'api',
} as const;

// Should match all possible *_PROVIDER defined in G.localStorage
export type KNOWN_DATA_PROVIDERS = 'UALA_BRANDS_PROVIDER' | 'UALA_STATISTICS_PROVIDER';

type DOMAIN_KEY = `UALA_${keyof typeof UALA_SUBDOMAINS}_PROVIDER`;

export const getApiBasePath = (subdomain: keyof typeof UALA_SUBDOMAINS): string => {
  const domainKey = `UALA_${subdomain}_PROVIDER` as DOMAIN_KEY;
  const isOverrideActive = !!window.localStorage.getItem(G.localStorage.UALA_OVERRIDE_PARAMS);
  const readCustomConfig = Boolean(
    APP_SETTINGS.UALA_ALLOW_PARAMS_CUSTOMIZATION &&
      window.localStorage &&
      isOverrideActive &&
      domainKey in G.localStorage
  );

  if (readCustomConfig) {
    return window.localStorage.getItem(G.localStorage[domainKey]) || '';
  }

  return `https://${window.location.hostname.replace(
    /(.*)\.(.*)\.(.*)$/gi,
    `${APP_SETTINGS.UALA_API_SUBDOMAIN}.$2.$3`
  )}/${subdomain.toLowerCase()}/v1`;
};
