import * as D from 'io-ts/Decoder';

const brandDecoder = D.struct({
  id: D.number,
  type: D.string,
  data: D.UnknownRecord,
});

export const createSessionDecoder = D.struct({
  data: D.struct({
    id: D.number,
    email: D.string,
    full_name: D.string,
    auth_token: D.string,
    resources: D.array(brandDecoder),
  }),
});

export type CreateSessionData = D.TypeOf<typeof createSessionDecoder>;

export const destroySessionDecoder = D.struct({
  info: D.array(D.string),
  data: D.struct({}),
});

export type DestroySessionData = D.TypeOf<typeof destroySessionDecoder>;
