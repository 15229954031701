import { HttpGetMethod, HttpMethod, HttpPostMethod, HTTP_METHODS } from './types';
import { handleRequestMethod, buildPath } from './utils';

const __get =
  (path: TemplateStringsArray, ...args: (string | number)[]) =>
  (config: HttpGetMethod): Promise<Response> =>
    handleRequestMethod({
      method: HTTP_METHODS.GET,
      path: buildPath(path, args),
      ...config,
    });

const __post =
  (path: TemplateStringsArray, ...args: (string | number)[]) =>
  (config: HttpPostMethod): Promise<Response> =>
    handleRequestMethod({
      method: HTTP_METHODS.POST,
      path: buildPath(path, args),
      ...config,
    });

const __delete =
  (path: TemplateStringsArray, ...args: (string | number)[]) =>
  (config?: HttpMethod): Promise<Response> =>
    handleRequestMethod({
      method: HTTP_METHODS.DELETE,
      path: buildPath(path, args),
      ...config,
    });

const __put =
  (path: TemplateStringsArray, ...args: (string | number)[]) =>
  (config: HttpPostMethod): Promise<Response> =>
    handleRequestMethod({
      method: HTTP_METHODS.PUT,
      path: buildPath(path, args),
      ...config,
    });

const http = {
  get: __get,
  post: __post,
  delete: __delete,
  put: __put,
};

export { http };
