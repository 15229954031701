import { LanguageSwitcher as LanguageSwitcherComponent, LanguageSwitcherProps } from './LanguageSwitcher';
import { useIntlContext } from 'providers';

const locales: LanguageSwitcherProps['options'] = [
  { flag: 'it', value: 'it' },
  { flag: 'gb', value: 'en' },
  { flag: 'gr', value: 'el' },
  { flag: 'fr', value: 'fr' },
  { flag: 'es', value: 'es' },
  { flag: 'pt', value: 'pt' },
];

export const LanguageSwitcher = (): JSX.Element => {
  const { switchLanguage, state } = useIntlContext();
  return (
    <LanguageSwitcherComponent
      position={'bottom'}
      defaultValue={state.locale}
      options={locales}
      onChange={switchLanguage}
    />
  );
};
