import { lazy, Suspense } from 'react';

import { useAuth } from 'providers';

import { Box, Loader } from 'components';

const AuthenticatedApp = lazy(() => import(/* webpackPrefetch: true */ './App.authenticated'));
const UnauthenticatedApp = lazy(() => import('./App.unauthenticated'));

export function App(): JSX.Element {
  const { authenticated, isValidating } = useAuth();

  const renderLoader = (): JSX.Element => (
    <Box
      data-testid="app-loading"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      height="100vh"
      width="100vw"
      alignItems="center"
    >
      <Loader />
    </Box>
  );

  return (
    <Suspense fallback={renderLoader()}>
      {isValidating ? renderLoader() : authenticated ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </Suspense>
  );
}
