import * as D from 'io-ts/Decoder';

export const FilterTypeDecoder = D.struct({
  created_at: D.string,
  description: D.nullable(D.string),
  id: D.number,
  key: D.string,
  name: D.string,
  updated_at: D.string,
});

export type FilterType = D.TypeOf<typeof FilterTypeDecoder>;

export const FilterTypesDecoder = D.struct({
  data: D.struct({
    filter_types: D.array(FilterTypeDecoder),
    filter_types_count: D.number,
  }),
});

export type FilterTypes = D.TypeOf<typeof FilterTypesDecoder>;
