import { PaddingProps } from '../types';
import { css, SerializedStyles } from 'styled';
import { resolveResponsiveProp } from './resolveResponsiveProp';

export const getPaddingProps = (props: PaddingProps): SerializedStyles => {
  const {
    padding,
    p,
    paddingX,
    pX,
    paddingY,
    pY,
    pB,
    paddingBottom,
    pL,
    paddingLeft,
    pR,
    paddingRight,
    pT,
    paddingTop,
  } = props;

  return css`
    ${p || padding ? resolveResponsiveProp({ propName: 'padding', value: p || padding }) : undefined}
    ${pT || paddingTop ? resolveResponsiveProp({ propName: 'padding-top', value: pT || paddingTop }) : undefined}
    ${pR || paddingRight ? resolveResponsiveProp({ propName: 'padding-right', value: pR || paddingRight }) : undefined}
    ${pB || paddingBottom
      ? resolveResponsiveProp({ propName: 'padding-bottom', value: pB || paddingBottom })
      : undefined}
    ${pL || paddingLeft ? resolveResponsiveProp({ propName: 'padding-left', value: pL || paddingLeft }) : undefined}
    ${pX || paddingX
      ? css`
          ${resolveResponsiveProp({ propName: 'padding-left', value: pX || paddingX })};
          ${resolveResponsiveProp({ propName: 'padding-right', value: pX || paddingX })}
        `
      : undefined}
    ${pY || paddingY
      ? css`
          ${resolveResponsiveProp({ propName: 'padding-top', value: pY || paddingY })};
          ${resolveResponsiveProp({ propName: 'padding-bottom', value: pY || paddingY })}
        `
      : undefined}
  `;
};
