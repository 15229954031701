import { Modal, Box, Text, Button } from 'components';
import { useIntl } from 'providers';

type Props = {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

const LogoutConfirmModal = ({ isOpen, onCancel, onConfirm }: Props): JSX.Element => {
  const { formatMessage } = useIntl();

  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <Box display="flex" flexDirection="column" justifyContent="center" height="140px">
        <Text align="center">{formatMessage({ id: 'session.confirm_logout' })}</Text>
        <Box display="flex" justifyContent="space-between" mT="auto">
          <Button variant="transparent" onClick={onCancel}>
            {formatMessage({ id: 'global.cancel' })}
          </Button>
          <Button onClick={onConfirm}>{formatMessage({ id: 'global.confirm' })}</Button>
        </Box>
      </Box>
    </Modal>
  );
};

export { LogoutConfirmModal };
