import { Box } from 'components';
import * as S from './RadialProgressBar.theme';

export const RadialProgressBar: (p: { percentage: number; label: string }) => JSX.Element = ({ percentage, label }) => {
  const deg = (Math.round(percentage) * 360) / 100;
  return (
    <>
      <S.Progress>
        <Box display="flex" flexDirection="column">
          <S.Percentage percentage={percentage}>{Math.round(percentage)}</S.Percentage>
          <S.Label>{label}</S.Label>
        </Box>
        <S.Overlay />
        <S.ShadowLeft />
        <S.ShadowRight />
        <S.Left deg={deg} />
        <S.Right deg={deg} />
      </S.Progress>
    </>
  );
};
