import { FunctionComponent } from 'react';
import { StyledLoaderProps, Ring, Roller } from './Loader.theme';
import { Box } from 'components/box';
import { SpacingProps } from 'styled';

export type LoaderProps = StyledLoaderProps &
  SpacingProps & {
    type?: 'ring' | 'roller';
  };

type LoaderComponentProps = StyledLoaderProps & {
  type: LoaderProps['type'];
};

const LoaderComponent: FunctionComponent<LoaderComponentProps> = ({ type, size, color }) => {
  switch (type) {
    case 'ring':
      return <Ring size={size} color={color} />;
    case 'roller':
    default:
      return (
        <Box display="flex" justifyContent="center" alignItems="center" w={`${size}px`} h={`${size}px`}>
          <Roller size={size} color={color} />
        </Box>
      );
  }
};

export const Loader: FunctionComponent<LoaderProps> = ({ type = 'roller', size = 50, color = 'blue.500', ...rest }) => (
  <Box display="inline-block" position="relative" {...rest}>
    <LoaderComponent type={type} size={size} color={color} />
  </Box>
);
