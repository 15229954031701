import { css, resolveResponsiveProp, SerializedStyles, GridProps } from 'styled';

export const getGridProps = (props: GridProps): SerializedStyles => {
  const {
    grid,
    gridRowEnd,
    gridTemplate,
    gridTemplateColumns,
    gridTemplateAreas,
    gridRow,
    gridColumnStart,
    gridColumn,
    gridAutoRows,
    gridAutoFlow,
    gridAutoColumns,
    gridArea,
    gridTemplateRows,
    gridColumnEnd,
    gridRowStart,
    gap,
  } = props;

  return css`
    ${grid ? resolveResponsiveProp({ propName: 'grid', value: grid }) : undefined}
    ${gridRowEnd ? resolveResponsiveProp({ propName: 'grid-rowEnd', value: gridRowEnd }) : undefined}
    ${gridTemplate ? resolveResponsiveProp({ propName: 'grid-template', value: gridTemplate }) : undefined}
    ${gridTemplateColumns
      ? resolveResponsiveProp({ propName: 'grid-template-columns', value: gridTemplateColumns })
      : undefined}
    ${gridTemplateAreas
      ? resolveResponsiveProp({ propName: 'grid-template-treas', value: gridTemplateAreas })
      : undefined}
    ${gridRow ? resolveResponsiveProp({ propName: 'grid-row', value: gridRow }) : undefined}
    ${gridColumn ? resolveResponsiveProp({ propName: 'grid-column', value: gridColumn }) : undefined}
    ${gridAutoRows ? resolveResponsiveProp({ propName: 'grid-auto-rows', value: gridAutoRows }) : undefined}
    ${gridAutoFlow ? resolveResponsiveProp({ propName: 'grid-auto-flow', value: gridAutoFlow }) : undefined}
    ${gridAutoColumns ? resolveResponsiveProp({ propName: 'grid-auto-columns', value: gridAutoColumns }) : undefined}
    ${gridArea ? resolveResponsiveProp({ propName: 'grid-area', value: gridArea }) : undefined}
    ${gridTemplateRows ? resolveResponsiveProp({ propName: 'grid-template-rows', value: gridTemplateRows }) : undefined}
    ${gridColumnEnd ? resolveResponsiveProp({ propName: 'grid-column-end', value: gridColumnEnd }) : undefined}
    ${gridRowStart ? resolveResponsiveProp({ propName: 'grid-row-start', value: gridRowStart }) : undefined}
    ${gridColumnStart ? resolveResponsiveProp({ propName: 'grid-column-start', value: gridColumnStart }) : undefined}
    ${gap ? resolveResponsiveProp({ propName: 'gap', value: gap }) : undefined}
  `;
};
