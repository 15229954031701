import { Box, Circle, Stack } from 'components';

export interface GroupRepartitionProps {
  groups: ReadonlyArray<{
    value: string;
    name: string;
    color: string;
  }>;
}

export const GroupsRepartition = ({ groups }: GroupRepartitionProps): JSX.Element => (
  <Box display="flex" flexDirection="column" justifyContent="center">
    <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
      <Stack spacing="32px" direction="row">
        {groups.map((group, i) => (
          <Box key={i} display="flex" flexDirection="row" alignItems="center">
            <Circle size="32px" backgroundColor={group.color} fontSize="xs" color="white">
              {group.name}
            </Circle>
            <Box mL="8px" fontSize="xl">
              {group.value}
            </Box>
          </Box>
        ))}
      </Stack>
    </Box>
  </Box>
);
