import { pipe } from 'fp-ts/function';
import * as E from 'fp-ts/Either';
import * as D from 'io-ts/Decoder';
import { lazyFetch } from './lazyFetch';

/**
 * @description
 * Accept a codec and promise a return a decoded Promise
 * @example
 * ```ts
 * const user = { id: 1, name: 'stefano' };
 * const getUserFetch = (): Promise<Response> => fetch(ENDPOINT);
 *
 * const UserDecoder = D.struct({
 *  id: D.number,
 *  name: D.string,
 * });
 * const getUser = withCodec(UserDecoder)(getUserFetch);
 *
 * await expect(getUser({id: 1})).resolves.toStrictEqual(user);
 * ```
 */

export function withCodec<CodecParams, DecodedResponse>(codec: D.Decoder<D.DecodeError, DecodedResponse>) {
  return (fn: (params: CodecParams) => Promise<Response>) =>
    (params: CodecParams): Promise<DecodedResponse> =>
      lazyFetch(() => fn(params), codec)().then(
        (x: E.Either<D.DecodeError, DecodedResponse>) =>
          new Promise((res, rej) =>
            pipe(
              x,
              E.fold((err) => rej(D.draw(err)), res)
            )
          )
      );
}
