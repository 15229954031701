import moment from 'moment';
import { DateRange } from '../DatePicker.types';

export const shoulConfirmdButtonBeDisabled = (
  mainDateRange: DateRange,
  comparisonDateRange: DateRange | null,
  isComparisonActive: boolean
): boolean => {
  if (!mainDateRange.startDate || !mainDateRange.endDate) {
    return true;
  }

  if (isComparisonActive && (!comparisonDateRange?.startDate || !comparisonDateRange?.endDate)) {
    return true;
  }

  return false;
};

export const convertDateToString = (date?: moment.Moment | null): string => (date ? moment(date).format('ll') : '');

type OptionsType<T> = Array<{ label: string; value: T }>;

export const getTranslatedOptions = <T>(
  options: OptionsType<T>,
  resolverFn: (label: string) => string
): OptionsType<T> => {
  return options.reduce((newOptions: OptionsType<T>, currentOption) => {
    newOptions.push({ label: resolverFn(currentOption.label), value: currentOption.value });
    return newOptions;
  }, []);
};
