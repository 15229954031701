export const G = {
  intl: {
    UALA_LOCALE: 'uala-locale',
    DEFAULT_LOCALE: 'it',
    /**
     * Array containing list of name spaces that you want to extract from the global translations file:
     * Example:
     * 'manager' string will extract all translations keys starting with 'manager'(e.g. "manager.sidebar.title") into a folder called manager
     */
    TRANSLATIONS_FOLDERS_BY_NAME_SPACE: [],
  },
  localStorage: {
    AUTH_TOKEN: 'uala-dashboard-token',
    USERNAME: 'uala-dashboard-username',
    UALA_OVERRIDE_PARAMS: 'uala-dashboard-override',
    UALA_BRANDS_PROVIDER: 'uala-dashboard-brands-provider',
    UALA_STATISTICS_PROVIDER: 'uala-dashboard-statistics-provider',
    UALA_MANAGER_PROVIDER: 'uala-dashboard-manager-provider',
    SESSION_GROUPS: 'uala-dashboard-user-groups',
    SESSION_BRAND: 'uala-dashboard-user-brand',
  },
  dates: {
    API_DATE_FORMAT: 'Y-MM-DD',
  },
  modals: {
    rootId: 'modal-root',
    bodyPreventScrollClassName: 'prevent-scroll',
    bodyScrollbarSpaceClassName: 'scrollbar-space',
  },
  groups: {
    DEFAULT_ACTIVE_GROUP: '1',
  },
  staleTime: 1000 * 60 * 30, // default stale time before refetching: 30 minutes
} as const;

export const ROUTES = {
  HOME: '/',
  GROUPS_LIST: '/groups/:id([/^new$]+|[0-9]+)?',
} as const;

export const ENDPOINTS = {
  BRAND_GROUPS: '/brands/',
} as const;
