import { css, resolveResponsiveProp, SerializedStyles, MarginProps } from 'styled';

export const getMarginProps = (props: MarginProps): SerializedStyles => {
  const { margin, m, marginX, mX, marginY, mY, mB, marginBottom, mL, marginLeft, mR, marginRight, mT, marginTop } =
    props;

  return css`
    ${m || margin ? resolveResponsiveProp({ propName: 'margin', value: m || margin }) : undefined}
    ${mT || marginTop ? resolveResponsiveProp({ propName: 'margin-top', value: mT || marginTop }) : undefined}
    ${mR || marginRight ? resolveResponsiveProp({ propName: 'margin-right', value: mR || marginRight }) : undefined}
    ${mB || marginBottom ? resolveResponsiveProp({ propName: 'margin-bottom', value: mB || marginBottom }) : undefined}
    ${mL || marginLeft ? resolveResponsiveProp({ propName: 'margin-left', value: mL || marginLeft }) : undefined}
    ${mX || marginX
      ? css`
          ${resolveResponsiveProp({ propName: 'margin-left', value: mX || marginX })}
          ${resolveResponsiveProp({ propName: 'margin-right', value: mX || marginX })}
        `
      : undefined}
    ${mY || marginY
      ? css`
          ${resolveResponsiveProp({ propName: 'margin-top', value: mY || marginY })}
          ${resolveResponsiveProp({ propName: 'margin-bottom', value: mY || marginY })}
        `
      : undefined}
  `;
};
