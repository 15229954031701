import { FunctionComponent } from 'react';

import { ComposableElement } from 'utilityTypes';

import { Header, HeaderProps, Box, BoxProps } from 'components';

type Props = {
  header: ComposableElement<HeaderProps>;
  layoutProps?: {
    bg?: BoxProps['bgColor'];
    fullPage?: boolean;
  };
};

const LayoutWithHeader: FunctionComponent<Props> = ({
  children,
  header: { content: headerContent, ownProps: headerProps },
  layoutProps = { bg: 'gray.100', fullPage: false },
}) => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="flex-start"
    bgColor={layoutProps.bg}
    height={layoutProps.fullPage ? '100vh' : undefined}
    overflow={layoutProps.fullPage ? 'auto' : undefined}
  >
    <Header {...headerProps}>{headerContent}</Header>
    {children}
  </Box>
);

export { LayoutWithHeader };
