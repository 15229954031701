import { useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useAuth, useFilters, useIntl } from 'providers';

import { Box, Circle, Icon, LanguageSwitcher, LogoutConfirmModal, SessionInfo } from 'components';
import { ROUTES } from 'enums/global';
import { useDisclosure, useOnClickOutside } from 'hooks';
import { useTheme } from 'styled';
import * as S from './Menu.theme';

import { IconName } from 'components/icon';

export const Menu: (p: unknown) => JSX.Element = () => {
  const { formatMessage } = useIntl();
  const { boxShadows } = useTheme();
  const { location } = useHistory();
  const slug: ReadonlyArray<string> = location.pathname.split('/');

  const menuRef = useRef<HTMLDivElement>(null);
  const menuStatus = useDisclosure();

  const logoutStatus = useDisclosure();
  const { logout, username } = useAuth();
  const { removeGroupIds, filters } = useFilters();

  useOnClickOutside(menuRef, !logoutStatus.isOpen ? menuStatus.onClose : undefined);

  const MENU_ICONS_MAP: {
    [routeSlug: string]: IconName;
  } = {
    '': 'Linechart',
    groups: 'Staff',
  } as const;

  const handleLogout = (): void => {
    logout();
    removeGroupIds(filters.group_ids);
  };
  return (
    <>
      <Box mL="auto" display="inline-flex" alignItems="center">
        {username ? (
          <S.SessionUsername>
            <span>{username}</span>
          </S.SessionUsername>
        ) : null}
        <Box onClick={menuStatus.onOpen} cursor="pointer">
          <Circle size="26px" bgColor="gray.200" display="flex">
            <Icon iconName="User" size={14} color="gray.600" />
          </Circle>
        </Box>
        {!menuStatus.isOpen ? null : (
          <Box
            zIndex={1}
            position="absolute"
            right="10px"
            top="60px"
            backgroundColor="white"
            color="gray.300"
            minWidth="300px"
            border="1px solid"
            borderRadius="5px"
            display="flex"
            flexDirection="column"
            ref={menuRef}
            pT="20px"
            boxShadow={boxShadows.sm}
          >
            {Object.values(ROUTES).map((route) => {
              const currentPath = route.split('/')[1];
              const isSelected = slug[slug.length - 1] === currentPath;
              const iconName = MENU_ICONS_MAP[currentPath as keyof typeof MENU_ICONS_MAP];
              return (
                <Link key={currentPath} to={currentPath}>
                  <S.MenuItem isSelected={isSelected} onClick={menuStatus.onClose}>
                    <Box display="flex" alignItems="center">
                      <Box display="flex" mR="16px" height="40px" alignItems="center">
                        <Icon iconName={iconName} size={20} color={isSelected ? 'blue.500' : 'gray.600'} />
                      </Box>
                      <Box>{formatMessage({ id: `route-path.${currentPath || 'home'}` })}</Box>
                    </Box>
                  </S.MenuItem>
                </Link>
              );
            })}
            <S.MenuItem>
              <SessionInfo onClick={logoutStatus.onOpen} />
            </S.MenuItem>
            <S.MenuItem
              isLastItem
              isClickable={false}
              height="40px"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              pR="10px"
            >
              <Box>{process.env.REACT_APP_VERSION && `v.${process.env.REACT_APP_VERSION}`}</Box>
              <Box>
                <LanguageSwitcher />
              </Box>
            </S.MenuItem>
          </Box>
        )}
      </Box>
      <LogoutConfirmModal isOpen={logoutStatus.isOpen} onConfirm={handleLogout} onCancel={logoutStatus.onClose} />
    </>
  );
};
