import { isDevelopmentMode } from './App.settings';

const startMockServer = (): void => {
  if (isDevelopmentMode) {
    // eslint-disable-next-line no-console
    console.log('MOCK enabled. current env: ', process.env.NODE_ENV);
    import('./mock-server/browser').then(({ worker }) => worker.start({ onUnhandledRequest: 'bypass' }));
  } else {
    // eslint-disable-next-line no-console
    console.log('MOCK disabled. current env: ', process.env.NODE_ENV);
  }
};

export default startMockServer;
