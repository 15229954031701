import { css, styled } from 'styled';

const SIZE = 32;

export const Checkbox = styled.input(
  ({ theme }) => css`
    position: relative;
    display: inline-flex;
    width: ${SIZE}px;
    height: ${SIZE}px;
    opacity: 0;
    z-index: 1;

    &:hover {
      cursor: pointer;
    }

    &:checked {
      + label > span:first-of-type::after,
      + label > span:first-of-type svg {
        transform: scale(1);
      }
    }

    &:focus {
      + label > span:first-of-type {
        box-shadow: 0 0px 6px ${theme.colors['gray.300']};
      }
    }

    &:disabled {
      + label > span:first-of-type {
        cursor: default;
        border: 2px solid ${theme.colors['gray.200']};
      }
      + label > span:first-of-type::after {
        background: ${theme.colors['gray.200']};
      }

      &:hover {
        cursor: not-allowed;
        + label {
          cursor: not-allowed;
          & > span,
          & > svg {
            cursor: not-allowed;
          }
        }
      }
    }
  `
);

export type CustomInputProps = {
  shape?: 'tick' | 'circle';
};

export const CustomInput = styled.span<CustomInputProps>(
  ({ theme, shape = 'tick' }) => css`
    position: absolute;
    top: 0;
    left: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 ${SIZE}px;
    width: ${SIZE}px;
    height: ${SIZE}px;
    cursor: pointer;
    border: 1px solid ${theme.colors['gray.300']};
    background-color: ${theme.colors['white']};
    border-radius: 50%;
    z-index: 0;

    ${shape === 'tick' &&
    css`
      svg {
        transform-origin: center;
        transition: transform 0.3s ease 0s;
        transform: scale(0);
      }
    `};

    ${shape === 'circle' &&
    css`
      &::after {
        content: '';
        position: absolute;
        display: inline-block;
        border-radius: 50%;
        top: calc(50% - ${SIZE / 4}px);
        left: calc(50% - ${SIZE / 4}px);
        width: 16px;
        height: 16px;
        transform-origin: center;
        transition: transform 0.3s ease 0s;
        background: ${theme.colors['blue.500']};
        transform: scale(0);
      }
    `}
  `
);

export type LabelProps = {
  direction: 'row' | 'row-reverse';
  showCursor?: boolean;
};

export const Label = styled.label<LabelProps>(
  ({ direction = 'row', showCursor }) => css`
    display: inline-flex;
    flex-direction: ${direction};
    align-items: center;
    ${showCursor && { cursor: 'pointer' }}

    > span:first-of-type {
      ${direction === 'row' && { left: 0 }}
      ${direction === 'row-reverse' && { right: 0, left: 'unset' }}
    }

    > span:last-of-type:not(:first-of-type) {
      ${direction === 'row' && { marginLeft: '12px' }}
      ${direction === 'row-reverse' && { marginRight: '12px' }}
    }
  `
);
