import { ReactNode, FunctionComponent, useEffect } from 'react';
import { IntlProvider as ReactIntlProvider } from 'react-intl';

import { LOCALES } from './IntlProvider.data';
import { useIntlContext, IntlProvider } from './IntlContext';
import { G } from 'enums/global';

if (!LOCALES.includes(window.APP_LOCALE)) {
  window.APP_LOCALE = G.intl.DEFAULT_LOCALE;
}

type ProviderProps = {
  children: ReactNode;
};

const ProviderComponent: FunctionComponent<ProviderProps> = ({ children }) => {
  const { state, switchLanguage } = useIntlContext();

  useEffect(() => {
    switchLanguage(window.APP_LOCALE);
  }, [switchLanguage]);

  return (
    <ReactIntlProvider
      key={state.locale}
      defaultLocale={G.intl.DEFAULT_LOCALE}
      locale={state.locale}
      messages={state.messages}
    >
      <>{children}</>
    </ReactIntlProvider>
  );
};

export const CustomIntlProvider: FunctionComponent<ProviderProps> = ({ children }) => (
  <IntlProvider>
    <ProviderComponent>{children}</ProviderComponent>
  </IntlProvider>
);
