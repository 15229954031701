const delay = (ms: number): Promise<unknown> => new Promise((resolve) => setTimeout(resolve, ms));

/**
 * @description
 * Block the execution for a defined amount of milliseconds
 * ```ts
 *  await sleep(500);
 * ```
 *
 */
export const sleep = async (milliseconds: number): Promise<unknown> => await delay(milliseconds);
/**
 * @description
 * invoke a function after a defined amount of milliseconds
 * ```ts
 *     await delayed(() => alert('executed after for 2 seconds'))(2000);
 * ```
 *
 */
export const delayed =
  <A = unknown>(f: (a?: A) => void) =>
  async (milliseconds: number): Promise<unknown> => {
    await delay(milliseconds);
    return f();
  };
