import { css, styled, keyframes, Keyframes } from 'styled';
export const Overlay = styled.div`
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: white;
`;
export const Percentage = styled.div<{ percentage: number }>(
  ({ theme: { colors }, percentage }) => css`
    top: -10px;
    position: relative;
    z-index: 100;
    color: ${percentage > 100 ? colors['red.400'] : colors['black']};
    :after {
      content: '%';
      color: ${percentage > 100 ? colors['red.400'] : colors['black']};
      height: 10px;
    }
  `
);
export const Progress = styled.div`
  width: 200px;
  height: 200px;
  font-size: 30px;
  color: black;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  background: white;
  text-align: center;
  line-height: 200px;
  margin: 20px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
`;

const load1 = (deg: number): Keyframes => keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(${deg > 180 ? 180 : deg}deg);
  }
`;

const load3 = (deg: number): Keyframes => keyframes`
      ${
        deg > 180 &&
        `
      0% {
        z-index: 100;
        transform: rotate(180deg);
      }

      100% {
        z-index: 100;
        transform: rotate(${deg}deg);
      }
    `
      }
`;

export const ShadowLeft = styled.div(
  ({ theme: { colors } }) => css`
    z-index: 3;
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: 10px solid ${colors['gray.300']};
    border-radius: 100px 0px 0px 100px;
    border-right: 0;
    transform-origin: right;
  `
);
export const ShadowRight = styled.div(
  ({ theme: { colors } }) => css`
    z-index: 0;
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    border: 10px solid ${colors['gray.300']};
    border-radius: 0px 100px 100px 0px;
    border-left: 0;
    transform-origin: left;
  `
);
export const Left = styled.div<{ deg: number }>(
  ({ theme: { colors }, deg }) => css`
    z-index: 1;
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: 10px solid ${colors['blue.500']};
    border-radius: 100px 0px 0px 100px;
    border-right: 0;
    transform-origin: right;
    animation: ${load1(deg)} 1s linear forwards;
  `
);

export const Label = styled.div(
  ({ theme: { colors, typography } }) => css`
    position: absolute;
    top: 8%;
    left: 0;
    right: 0;
    color: ${colors['gray.600']};
    font-size: ${typography.fontSizes['md']}px;
    z-index: 3;
  `
);
export const Right = styled.div<{ deg: number }>(
  ({ theme: { colors }, deg }) => css`
    z-index: 2;
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: 10px solid ${colors['blue.500']};
    border-radius: 100px 0px 0px 100px;
    border-right: 0;
    transform-origin: right;
    :last-of-type,
    :first-of-type {
      animation: ${load3(deg > 360 ? 360 : deg)} 0.8s linear forwards 1s;
    }
  `
);
