/**
 * @description
 * This function takes colors in hex format (i.e. #F06D06) and lightens or darkens them with a value.
 *
 * @example
 * ```ts
 *  expect(lightenDarkenColor('#CCCD64', 30)).toBe('#eaeb82');
 *  expect(lightenDarkenColor('#CCCD64', -60)).toBe('#909128');
 * ```
 *
 * @param {String} hex The hex color code.
 * @param {Int} brightness The brigtness value.
 *
 */

export const lightenDarkenColor = (hex: string, brightness: number): `#${string}` =>
  `#${hex
    .replace(/^#/, '')
    .replace(/../g, (hexadecimal) =>
      ('0' + Math.min(255, Math.max(0, parseInt(hexadecimal, 16) + brightness)).toString(16)).substr(-2)
    )
    .toUpperCase()}` as `#${string}`;
