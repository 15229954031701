import { Text, TextProps } from 'components/typography';

export type ModalTitleProps = {
  title?: string;
  color?: TextProps['color'];
};

export const ModalTitle = ({ title, color = 'default' }: ModalTitleProps): JSX.Element => (
  <Text textTransform="uppercase" fontWeight="bold" fontSize="12px" color={color}>
    {title}
  </Text>
);
