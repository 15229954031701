import { SpacingProps } from '../types';
import { css, SerializedStyles } from 'styled';
import { getMarginProps } from './getMarginProps';
import { getPaddingProps } from './getPaddingProps';

export const getSpacingProps = (props: SpacingProps): SerializedStyles => {
  const {
    // margin
    margin,
    m,
    marginX,
    mX,
    marginY,
    mY,
    mB,
    marginBottom,
    mL,
    marginLeft,
    mR,
    marginRight,
    mT,
    marginTop,
    // padding
    padding,
    p,
    paddingX,
    pX,
    paddingY,
    pY,
    pB,
    paddingBottom,
    pL,
    paddingLeft,
    pR,
    paddingRight,
    pT,
    paddingTop,
  } = props;

  return css`
    ${getMarginProps({
      margin,
      m,
      marginX,
      mX,
      marginY,
      mY,
      mB,
      marginBottom,
      mL,
      marginLeft,
      mR,
      marginRight,
      mT,
      marginTop,
    })};
    ${getPaddingProps({
      padding,
      p,
      paddingX,
      pX,
      paddingY,
      pY,
      pB,
      paddingBottom,
      pL,
      paddingLeft,
      pR,
      paddingRight,
      pT,
      paddingTop,
    })}
  `;
};
