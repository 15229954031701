export const ACTION_TYPE = {
  SELECT_ITEM: 'SELECT_ITEM',
  REMOVE_SELECTED_ITEM: 'REMOVE_SELECTED_ITEM',
  HIGHLIGHT_ITEM: 'HIGHLIGHT_ITEM',
  HIGHLIGHT_NEXT_ITEM: 'HIGHLIGHT_NEXT_ITEM',
  HIGHLIGHT_PREV_ITEM: 'HIGHLIGHT_PREV_ITEM',
  TOGGLE_OPEN: 'TOGGLE_OPEN',
} as const;

export type State<T> = {
  items: Array<T>;
  selectedItem?: T;
  highlightedIndex?: number;
  isOpen: boolean;
};

type RemoveSelectedItemAction = {
  type: typeof ACTION_TYPE.REMOVE_SELECTED_ITEM;
};

type HighlightNextItemAction = {
  type: typeof ACTION_TYPE.HIGHLIGHT_NEXT_ITEM;
};

type HighlightPrevItemAction = {
  type: typeof ACTION_TYPE.HIGHLIGHT_PREV_ITEM;
};

type ToggleAction = {
  type: typeof ACTION_TYPE.TOGGLE_OPEN;
  payload?: {
    isOpen?: boolean;
  };
};

type HighlightItemAction<T> = {
  type: typeof ACTION_TYPE.HIGHLIGHT_ITEM;
  payload?: {
    item?: T;
  };
};

type ItemAction<T> = {
  type: typeof ACTION_TYPE.SELECT_ITEM;
  payload: {
    item: T;
  };
};

export type SelectAction<T> =
  | ToggleAction
  | ItemAction<T>
  | HighlightItemAction<T>
  | RemoveSelectedItemAction
  | HighlightNextItemAction
  | HighlightPrevItemAction;

export type SelectReducerType<T> = (state: State<T>, action: SelectAction<T>) => State<T>;

export const SelectReducer = <T>(state: State<T>, action: SelectAction<T>): State<T> => {
  switch (action.type) {
    case ACTION_TYPE.SELECT_ITEM: {
      const { item } = action.payload;
      return { ...state, selectedItem: item };
    }
    case ACTION_TYPE.TOGGLE_OPEN: {
      const { isOpen = !state.isOpen } = action.payload || {};
      let highlightedIndex;
      if (state.selectedItem) {
        highlightedIndex = state.items.indexOf(state.selectedItem);
      }
      return { ...state, highlightedIndex, isOpen };
    }
    case ACTION_TYPE.HIGHLIGHT_ITEM: {
      const { item } = action.payload || {};
      if (!item) {
        return { ...state, highlightedIndex: undefined };
      }
      const index = state.items.indexOf(item);
      return { ...state, highlightedIndex: index };
    }
    case ACTION_TYPE.HIGHLIGHT_NEXT_ITEM: {
      const { highlightedIndex = 0, items } = state;

      if (highlightedIndex < items.length - 1) {
        return { ...state, highlightedIndex: highlightedIndex + 1 };
      }

      return state;
    }
    case ACTION_TYPE.HIGHLIGHT_PREV_ITEM: {
      const { highlightedIndex = 0 } = state;

      if (highlightedIndex > 0) {
        return { ...state, highlightedIndex: highlightedIndex - 1 };
      }

      return state;
    }
    case ACTION_TYPE.REMOVE_SELECTED_ITEM: {
      return { ...state, selectedItem: undefined };
    }
    default:
      return state;
  }
};
