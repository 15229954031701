import { FunctionComponent } from 'react';
import * as S from './Heading.theme';

type Props = S.Props;

const Heading: FunctionComponent<Props> = ({ children, level = 'h1', ...rest }) => (
  <S.HeadingProxy as={level} level={level} {...rest}>
    {children}
  </S.HeadingProxy>
);

export { Heading };
