class HttpErrorResponse extends Error {
  statusCode: number | null = null;

  constructor(message: string, statusCode: number) {
    super(message);
    this.statusCode = statusCode;
  }
}

export { HttpErrorResponse };
