import { Colors } from 'styled';
import { DateRange, MainDateRangeOption, ComparisonDateRangeOption } from './DatePicker.types';
import { getDateRangeByOptionType } from './DatePicker.utils';
import { UseDatePickerParams } from './useDatePicker';

export const MAIN_DATE_RANGE_OPTIONS: Array<{ label: string; value: MainDateRangeOption }> = [
  { label: 'datepicker.period.custom', value: 'custom' },
  { label: 'datepicker.period.yesterday', value: 'yesterday' },
  { label: 'datepicker.period.lastweek', value: 'lastweek' },
  { label: 'datepicker.period.lastmonth', value: 'lastmonth' },
  { label: 'datepicker.period.last7days', value: 'last7days' },
  { label: 'datepicker.period.last30days', value: 'last30days' },
  { label: 'datepicker.period.last90days', value: 'last90days' },
];

export const COMPARISON_DATE_RANGE_OPTIONS: Array<{ label: string; value: ComparisonDateRangeOption }> = [
  { label: 'datepicker.compare.custom', value: 'custom' },
  { label: 'datepicker.compare.previousperiod', value: 'previousperiod' },
  { label: 'datepicker.compare.52weeksbefore', value: '52weeksbefore' },
];

export const DEFAULT_DATE_RANGE_OPTION: MainDateRangeOption = 'lastweek';

export const DEFAULT_DATE_RANGE_OPTIONS: Array<MainDateRangeOption> = [
  'custom',
  'yesterday',
  'lastweek',
  'lastmonth',
  'last7days',
  'last30days',
  'last90days',
];

export const DEFAULT_COMPARISON_DATE_RANGE_OPTIONS: Array<ComparisonDateRangeOption> = [
  'custom',
  'previousperiod',
  '52weeksbefore',
];

export const DEFAULT_COMPARISON_DATE_RANGE_OPTION: ComparisonDateRangeOption = 'previousperiod';

export const INIT_DATE_RANGE: DateRange = getDateRangeByOptionType(DEFAULT_DATE_RANGE_OPTION);

export const MAIN_DATE_RANGE_COLOR: Colors = 'blue.500'; // TODO: replace with primary

export const COMPARISON_DATE_RANGE_COLOR: Colors = 'orange.400';

export const DEFAULT_NUMBER_OF_VISIBLE_MONTHS = 3;

export const DATEPICKER_DEFAULT_STATE: UseDatePickerParams['datePickerState'] = {
  mainDateRange: INIT_DATE_RANGE,
  comparisonDateRange: null,
  mainDateRangeOptionValue: DEFAULT_DATE_RANGE_OPTION,
  comparisonDateRangeOptionValue: DEFAULT_COMPARISON_DATE_RANGE_OPTION,
  isComparisonActive: false,
  isComparisonDisabled: false,
};
