import { Stack, ProgressBar } from 'components';
import { Colors } from 'styled';
import { renderPercentageByRange } from 'utils/render-percentage-by-range';
import { LiteralUnion } from 'utilityTypes';

export interface MarketBenchmarkProps {
  groups: ReadonlyArray<{
    value: string;
    name: string;
    color: LiteralUnion<Colors, string>;
  }>;
  market: string;
}

export type BenchmarkThreshold = {
  minThreshold: 20;
  midThreshold: 50;
  maxThreshold: 100;
};

export const MarketBenchmark = ({ groups, market }: MarketBenchmarkProps): JSX.Element => {
  const values = groups.reduce((acc, item) => [...acc, parseInt(item.value)], [parseInt(market)]);
  const maxValue = Math.max(...values);
  const benchmarkThreshold: BenchmarkThreshold = {
    minThreshold: 20,
    midThreshold: 50,
    maxThreshold: 100,
  };
  return (
    <Stack spacing="5px" direction="column">
      {groups.map(({ color, value }, i) => {
        return (
          <ProgressBar
            key={`market-benchmark-${color}-${i}`}
            progress={renderPercentageByRange(parseInt(value), maxValue, benchmarkThreshold)}
            colorScheme={color}
          />
        );
      })}
      <ProgressBar
        progress={renderPercentageByRange(parseInt(market), maxValue, benchmarkThreshold)}
        colorScheme="gray.600"
      />
    </Stack>
  );
};
