import { forwardRef, FunctionComponent, ForwardedRef } from 'react';
import { Box, BoxProps } from './Box';

type BannedBaseProps = 'width' | 'height' | 'w' | 'h';

export interface SquareProps extends Omit<BoxProps, BannedBaseProps> {
  /**
   * The size (width and height) of the square
   */
  size: BoxProps['width'];
  /**
   * If `true`, the content will be centered in the square
   */
  centerContent?: boolean;
  ref?: ForwardedRef<HTMLDivElement>;
}

const Square: FunctionComponent<SquareProps> = forwardRef<HTMLDivElement, SquareProps>(
  ({ size, centerContent = true, ...rest }, ref) => {
    const styles = centerContent ? { display: 'flex', alignItems: 'center', justifyContent: 'center' } : {};

    return <Box width={size} height={size} {...styles} {...rest} flexShrink={0} flexGrow={0} ref={ref} />;
  }
);

export { Square };
