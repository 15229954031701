import { styled } from 'styled';

export const Breadcrumb = styled.div`
  position: relative;
  & > div:last-of-type > span {
    cursor: default;
  }
`;

export const Separator = styled.div`
  margin: auto 0;
  display: flex;
`;
