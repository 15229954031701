import { styled, css, SerializedStyles, Theme, getMarginProps, MarginProps } from 'styled';

export type StyledInputProps = MarginProps & {
  spacing?: 'sm' | 'md' | 'lg';
  width?: number | string;
  maxWidth?: number | string;
  minWidth?: number | string;
  isFullWidth?: boolean;
};

const getCssBySpacing = (theme: Theme, spacing: StyledInputProps['spacing']): SerializedStyles => {
  switch (spacing) {
    case 'md':
      return css`
        border-radius: 21px;
        padding: 7px 15px;
        font-size: ${theme.typography.fontSizes[spacing]}px;
      `;
    case 'sm':
      return css`
        border-radius: 21px;
        padding: 7px 15px;
        font-size: ${theme.typography.fontSizes[spacing]}px;
      `;
    case 'lg':
    default:
      return css`
        border-radius: 21px;
        padding: 12px 17px;
        font-size: ${theme.typography.fontSizes.md}px;
      `;
  }
};

export const Input = styled.input<StyledInputProps>`
  ${({
    theme,
    spacing,
    isFullWidth,
    width,
    maxWidth,
    minWidth,
    margin,
    m,
    marginX,
    mX,
    marginY,
    mY,
    mB,
    marginBottom,
    mL,
    marginLeft,
    mR,
    marginRight,
    mT,
    marginTop,
  }): SerializedStyles => css`
    font-family: ${theme.typography.fontFamilies.default};
    border: 1px solid ${theme.colors['gray.300']};
    color: ${theme.typography.colors.default};

    ${getCssBySpacing(theme, spacing)}

    ${getMarginProps({
      margin,
      m,
      marginX,
      mX,
      marginY,
      mY,
      mB,
      marginBottom,
      mL,
      marginLeft,
      mR,
      marginRight,
      mT,
      marginTop,
    })}

    ${isFullWidth &&
    css`
      width: 100%;
      max-width: 100%;
    `}

    ${width &&
    css`
      width: ${typeof width === 'number' ? `${width}px` : width};
    `}

    ${maxWidth &&
    css`
      max-width: ${typeof maxWidth === 'number' ? `${maxWidth}px` : maxWidth};
    `}

    ${minWidth &&
    css`
      min-width: ${typeof minWidth === 'number' ? `${minWidth}px` : minWidth};
    `}

    &:focus {
      border-color: ${theme.colors['blue.500']};
      outline: 0;
    }

    &::placeholder {
      color: ${theme.typography.colors.xlight};
    }

    &:disabled {
      color: ${theme.typography.colors.light};
    }
  `}
`;
