import { ReactNode } from 'react';
import { styled, BaseProps, getBaseProps } from 'styled';

export interface BoxProps extends BaseProps {
  children?: ReactNode;
}

const Box = styled.div<BoxProps>((props) => ({ ...getBaseProps(props) }));

export { Box };
