import { useIntl } from 'providers';

import { Box, Icon, Link } from 'components';

export const SessionInfo = ({ onClick }: { onClick: () => void }): JSX.Element => {
  const { formatMessage } = useIntl();
  return (
    <>
      <Link color="black" onClick={onClick}>
        <Box display="flex" alignItems="center" flexDirection="row" height="40px">
          <Box mR="16px">
            <Icon iconName="Logout" />
          </Box>
          <Box mL="5px">{formatMessage({ id: 'session.logout' })}</Box>
        </Box>
      </Link>
    </>
  );
};
