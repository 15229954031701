import { keyframes, Keyframes } from 'styled';

export const slideInLeft = (width: string): Keyframes => keyframes`
  from {
    width: 0;
    opacity: 0;
  }
  to {
    width: ${width};
    opacity: 1;
  }
`;
