import { Box } from 'components';
import { css, styled } from 'styled';

export const MenuItem = styled(Box)<{ isClickable?: boolean; isLastItem?: boolean; isSelected?: boolean }>(
  ({ isClickable = true, theme, isLastItem = false, isSelected }) => css`
    color: ${theme.colors['black']};
    text-transform: uppercase;
    font-size: ${theme.typography.fontSizes.xs}px;
    cursor: pointer;
    vertical-align: middle;
    &:hover {
      background: ${isClickable ? theme.colors['gray.100'] : 'transparent'};
    }
    border-top: ${isLastItem ? '1px solid' + theme.colors['gray.100'] : 'none'};
    border-left: 4px solid ${isSelected ? theme.colors['blue.500'] : 'transparent'};
    padding-left: 27px;
  `
);

export const SessionUsername = styled.div`
  text-align: right;
  margin-right: 20px;
  white-space: nowrap;
  overflow: hidden;
  display: none;
  ${({ theme }): string => `
    ${theme.media.sm([{ display: 'inline-block' }]).styles}
  `}
`;
