import { createContext, useContext, useReducer, useEffect, FunctionComponent } from 'react';
import { useQueryClient, useMutation } from 'react-query';

import { useFilters } from 'providers';

import { G } from 'enums/global';

import { AuthCredentials, requestAuth, invalidateAuth } from './AuthProvider.api';
import authReducer, { signIn, signOut, validate, error } from './AuthProvider.reducer';

type AuthContextProps = {
  authenticated: boolean;
  username?: string;
  isValidating: boolean;
  login: ({ email, password }: AuthCredentials) => void;
  logout: () => void;
};

const AuthContext = createContext<AuthContextProps | null>(null);
AuthContext.displayName = 'AuthContext';

const AuthProvider: FunctionComponent = ({ children }) => {
  const storedToken = localStorage.getItem(G.localStorage.AUTH_TOKEN);
  const storedUsername = localStorage.getItem(G.localStorage.USERNAME);
  const client = useQueryClient();
  const { setBrandId } = useFilters();

  const [{ authenticated, username, isValidating }, dispatch] = useReducer(authReducer, {
    authenticated: false,
    isValidating: false,
  });

  const handleSignIn = useMutation(requestAuth, {
    onSuccess: ({ data }) => {
      const { auth_token, full_name, resources } = data;

      const brandId = resources.find(({ type }) => type === 'Brand')?.id;

      if (brandId) {
        setBrandId(brandId);
      }

      register(auth_token, full_name);
    },
    onMutate: () => {
      dispatch(validate());
    },
    onError: () => {
      dispatch(error());
    },
  });

  const handleSignOut = useMutation(invalidateAuth, {
    onSuccess: () => {
      localStorage.removeItem(G.localStorage.AUTH_TOKEN);
      localStorage.removeItem(G.localStorage.USERNAME);
      client.clear();

      dispatch(signOut());
    },
  });

  useEffect(() => {
    if (storedToken && storedUsername && !authenticated) {
      dispatch(signIn(storedUsername));
    }
  }, [storedToken, authenticated, storedUsername]);

  const login = ({ email, password }: AuthCredentials): void => {
    handleSignIn.mutate({ email, password });
  };

  const register = (token: string, name: string): void => {
    localStorage.setItem(G.localStorage.AUTH_TOKEN, token);
    localStorage.setItem(G.localStorage.USERNAME, name);
    dispatch(signIn(name));
  };

  const logout = (): void => {
    handleSignOut.mutate(null);
  };

  return (
    <AuthContext.Provider value={{ login, logout, authenticated, username, isValidating }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = (): AuthContextProps => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth requires a valid context provider');
  }
  return context;
};

export { AuthProvider, useAuth };
