import { MouseEvent } from 'react';
import { Box, Icon } from 'components';
import { IconName } from 'components/icon';
import * as S from './Chip.theme';

interface ChipProps {
  readonly onClick?: (event: MouseEvent<HTMLDivElement>) => void;
  readonly label: string;
  readonly iconName?: IconName;
  readonly backgroundColor?: string;
  readonly color?: string;
  readonly border?: string;
}

export const Chip: (props: ChipProps) => JSX.Element = ({
  onClick = (): void => {},
  label,
  iconName,
  backgroundColor,
  color,
  border,
}) => (
  <S.ChipButton
    color={color}
    border={border}
    display="inline-flex"
    position="relative"
    flexDirection="row"
    alignItems="center"
    justifyContent="space-between"
    background={backgroundColor}
    p="6px 12px"
  >
    <Box color={color} textAlign="left" display="flex" alignItems="center">
      {label}
    </Box>
    {!iconName ? null : (
      <Box color={color} onClick={onClick} cursor="pointer" mL="6px">
        <Icon iconName={iconName} size={8} color="gray.400" />
      </Box>
    )}
  </S.ChipButton>
);
