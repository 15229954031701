type RGBA = {
  r: string;
  g: string;
  b: string;
  a: string;
};

const removeHash = (hex: string): string => (hex.charAt(0) === '#' ? hex.slice(1) : hex);

const parseHex = (hex: string): RGBA => {
  const isShort = hex.length === 3 || hex.length === 4;

  const twoDigitHexR = isShort ? `${hex.slice(0, 1)}${hex.slice(0, 1)}` : hex.slice(0, 2);
  const twoDigitHexG = isShort ? `${hex.slice(1, 2)}${hex.slice(1, 2)}` : hex.slice(2, 4);
  const twoDigitHexB = isShort ? `${hex.slice(2, 3)}${hex.slice(2, 3)}` : hex.slice(4, 6);
  const twoDigitHexA = (isShort ? `${hex.slice(3, 4)}${hex.slice(3, 4)}` : hex.slice(6, 8)) || 'ff';

  return {
    r: twoDigitHexR,
    g: twoDigitHexG,
    b: twoDigitHexB,
    a: twoDigitHexA,
  };
};

const hexToDecimal = (hex: string): number => parseInt(hex, 16);

const hexesToDecimals = ({ r, g, b, a }: RGBA): RGBA => ({
  r: hexToDecimal(r).toString(),
  g: hexToDecimal(g).toString(),
  b: hexToDecimal(b).toString(),
  a: (hexToDecimal(a) / 255).toFixed(2).toString(),
});

const isNumeric = (n: number): boolean => !isNaN(parseFloat(n.toString())) && isFinite(n);

const formatRgb = (decimalObject: RGBA, parameterA: number): string => {
  const { r, g, b, a: parsedA } = decimalObject;
  const a = isNumeric(parameterA) ? parameterA : parsedA;

  return `rgba(${r}, ${g}, ${b}, ${a})`;
};

/**
 * @description
 * convert hex color to rgba with alpha value
 *
 * @param The hex value to convert. ('123456'. '#123456', ''123', '#123')
 * @param An alpha value to apply. (optional) ('0.5', '0.25')
 * @return An rgb or rgba value. ('rgb(11, 22, 33)'. 'rgba(11, 22, 33, 0.5)')
 */
export const hexToRgba = (hex: string, a: number): string => {
  const hashlessHex = removeHash(hex);
  const hexObject = parseHex(hashlessHex);
  const decimalObject = hexesToDecimals(hexObject);

  return formatRgb(decimalObject, a);
};
