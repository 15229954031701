import { createContext, FunctionComponent, useState, useContext } from 'react';
import { useQuery } from 'react-query';

import { getFilterTypes } from './FilterTypesProvider.api';
import { FilterType } from './FilterTypesProvider.codec';

type FilterTypesContextProps = {
  filterTypes?: FilterType[];
};

const FilterTypesContext = createContext<FilterTypesContextProps | null>(null);
FilterTypesContext.displayName = 'FilterTypesContext';

const FilterTypesProvider: FunctionComponent = ({ children }) => {
  const [retry] = useState();
  const { data } = useQuery(['filter-types', retry], getFilterTypes);

  const filterTypes = data?.data.filter_types;

  return <FilterTypesContext.Provider value={{ filterTypes }}>{children}</FilterTypesContext.Provider>;
};

const useFilterTypes = (): FilterTypesContextProps => {
  const context = useContext(FilterTypesContext);

  if (!context) {
    throw new Error('useFilterTypes requires a valid context provider');
  }
  return context;
};

export { FilterTypesProvider, useFilterTypes };
