import { FunctionComponent } from 'react';
import { Box, BoxProps } from 'components';
import { ModalCloseButton, ModalCloseButtonProps } from '../ModalCloseButton';
import { ModalTitle, ModalTitleProps } from '../ModalTitle';

export type ModalHeaderProps = {
  onClose?: ModalCloseButtonProps['onClose'];
  showCloseButton?: boolean;
  title?: ModalTitleProps['title'];
  titleColor?: ModalTitleProps['color'];
  alignItems?: BoxProps['alignItems'];
  justifyContent?: BoxProps['justifyContent'];
  hasBorderBottom?: boolean;
};

export const ModalHeader: FunctionComponent<ModalHeaderProps> = ({
  children,
  onClose,
  showCloseButton,
  justifyContent = 'center',
  alignItems = 'center',
  hasBorderBottom,
  title,
  titleColor,
}) => (
  <Box
    display="flex"
    alignItems={alignItems}
    justifyContent={justifyContent}
    width="100%"
    pX={{ initial: '20px', sm: '25px' }}
    pY="20px"
    pR={showCloseButton ? '35px' : undefined}
    borderBottom={hasBorderBottom ? '1px solid' : undefined}
    borderColor={hasBorderBottom ? 'gray.300' : undefined}
  >
    {children}
    {title && (
      <Box flex="0 1 100%" display="flex" justifyContent="center" textAlign="center">
        <ModalTitle title={title} color={titleColor} />
      </Box>
    )}
    {showCloseButton && onClose && <ModalCloseButton onClose={onClose} margin="0 0 0 auto" />}
  </Box>
);
