import { useViewport } from 'hooks/useViewPort';
import { createContext } from 'react';

export const ViewportContext = createContext<{ vw: number; vh: number } | undefined>(undefined);

export const ViewportProvider: React.FC = ({ children }) => {
  const { vh, vw } = useViewport();

  return <ViewportContext.Provider value={{ vw, vh }}>{children}</ViewportContext.Provider>;
};
export { useViewportProvider } from './useViewportProvider';
