import { ResponsivePropKeys } from 'styled';
import { getSortedThemeBreakpointsArrayMap } from 'styled/utils/resolveResponsiveProp';

export const getMatchedBreakpoint = <T = string>(width: number, breakpoints: [T, number][]): T => {
  return breakpoints.reduce((prev: T, current, i) => {
    const breakpointKey = current[0]; // _ | xs | sm | ...
    const breakpointValue = current[1]; // 0 | 480 | 768, ...

    // check if the lowest breakpoint value matches
    if (i === 0 && width < breakpointValue) {
      prev = breakpointKey;
    }

    // check if the greatest breakpoint value matches
    if (i === breakpoints.length - 1 && width >= breakpointValue) {
      prev = breakpointKey;
    }

    // check if one of the intermediate breakpoints value matches
    if (width >= breakpointValue && breakpoints[i + 1] && width < breakpoints[i + 1][1]) {
      prev = breakpointKey;
    }

    return prev;
  }, breakpoints[0][0]);
};

/**
 *
 * @description
 * given a width, it returns the theme breakpoint where the width matches
 *
 * @example
 * ```ts
 * expect(getMatchedBreakpointByWidth(850)).toBe('sm');
 * ``
 */
export const getMatchedBreakpointByWidth = (width: number): ResponsivePropKeys => {
  const breakpointArrayMap = getSortedThemeBreakpointsArrayMap();

  return getMatchedBreakpoint(width, breakpointArrayMap);
};
