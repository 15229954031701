import { useState, useCallback, useEffect } from 'react';
import { sleep } from 'utils/delay';

export const useViewport = (): { vw: number; vh: number } => {
  const { innerWidth, innerHeight } = window;
  const [vh, setVH] = useState<number>(innerHeight);
  const [vw, setVW] = useState<number>(innerWidth);

  const onResize = useCallback(async (): Promise<void> => {
    await sleep(1000);
    window.innerWidth !== vw && setVW(window.innerWidth);
    window.innerHeight !== vh && setVH(window.innerHeight);
  }, [vh, vw]);

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return (): void => window.removeEventListener('resize', onResize);
  }, [onResize]);

  return { vw, vh };
};
