import { FSA } from 'utilityTypes';

const ENV_OVERRIVE_ACTION_TYPES = {
  SET_BRANDS_PROVIDER: '@envOverride/SET_BRANDS_PROVIDER',
  SET_STATISTICS_PROVIDER: '@envOverride/SET_STATISTICS_PROVIDER',
};

interface SetBrandsProvider extends FSA<string> {
  type: typeof ENV_OVERRIVE_ACTION_TYPES.SET_BRANDS_PROVIDER;
  payload: string;
}

const setBrandsProvider = (provider: string): SetBrandsProvider => ({
  type: ENV_OVERRIVE_ACTION_TYPES.SET_BRANDS_PROVIDER,
  payload: provider,
});

interface SetStatisticsProvider extends FSA<string> {
  type: typeof ENV_OVERRIVE_ACTION_TYPES.SET_STATISTICS_PROVIDER;
  payload: string;
}

const setStatisticsProvider = (provider: string): SetBrandsProvider => ({
  type: ENV_OVERRIVE_ACTION_TYPES.SET_STATISTICS_PROVIDER,
  payload: provider,
});

type EnvOverrideActions = SetBrandsProvider | SetStatisticsProvider;

export type EnvOverrideState = {
  brandsProvider?: string;
  statisticsProvider?: string;
};

const envOverrideReducer = (state: EnvOverrideState, action: EnvOverrideActions): EnvOverrideState => {
  switch (action.type) {
    case ENV_OVERRIVE_ACTION_TYPES.SET_BRANDS_PROVIDER:
      return { ...state, brandsProvider: action.payload };
    case ENV_OVERRIVE_ACTION_TYPES.SET_STATISTICS_PROVIDER:
      return { ...state, statisticsProvider: action.payload };
    default:
      return state;
  }
};

export default envOverrideReducer;
export { setBrandsProvider, setStatisticsProvider };
