export type ContentTypes =
  | 'application/json'
  | 'text/html; charset=UTF-8'
  | 'multipart/form-data'
  | 'application/x-www-form-urlencoded';

export interface HttpMethod {
  headers?: Headers;
  needsAuth?: boolean;
  authTokenProvider?: () => string;
  domain?: string;
}

export interface HttpGetMethod extends HttpMethod {
  query?: URLSearchParams;
  body?: never;
}

export interface HttpPostMethod extends HttpMethod {
  body: Record<string, unknown> | FormData;
  query?: never;
}

export const HTTP_METHODS = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
} as const;

type DefaultHandlerParams = {
  path: string;
  mediaType?: ContentTypes;
};

export type RequestHandlerParams =
  | ({
      method: typeof HTTP_METHODS.GET | typeof HTTP_METHODS.DELETE;
    } & DefaultHandlerParams &
      HttpGetMethod)
  | ({ method: typeof HTTP_METHODS.PUT | typeof HTTP_METHODS.POST } & DefaultHandlerParams & HttpPostMethod);

export type ServerResponse<Data> =
  | {
      success: true;
      data: Data;
      info?: string[];
      error?: never;
    }
  | {
      success: false;
      data?: never;
      info?: string[];
      error: {
        code: number;
        formatted_message: string;
        raw_message: string;
      };
    };
