import {
  FunctionComponent,
  createContext,
  useContext,
  ReactNode,
  useMemo,
  useReducer,
  Dispatch,
  useCallback,
} from 'react';
import { ACTION_TYPE, State, IntlReducer, IntlReducerAction } from './IntlContext.reducer';
import { messages } from './IntlProvider.translations';
import { AvailableLocales, AvailableTranslationsNameSpaces } from './types';
import { G } from 'enums/global';

type IntlProviderProps = {
  children: ReactNode;
};

type UseIntlContextShape = {
  state: State;
  switchLanguage: (locale: AvailableLocales) => void;
  addNameSpaces: (namespaces: AvailableTranslationsNameSpaces[]) => void;
  areNameSpacesAdded: (namespaces: AvailableTranslationsNameSpaces[]) => boolean;
};

const defaultState: State = {
  messages: messages.global[G.intl.DEFAULT_LOCALE],
  locale: G.intl.DEFAULT_LOCALE,
  namespaces: [],
};

const IntlContext = createContext<{ state: State; dispatch: Dispatch<IntlReducerAction> } | undefined>(undefined);

const IntlProvider: FunctionComponent<IntlProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(IntlReducer, defaultState);
  const value = useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state]
  );
  return <IntlContext.Provider value={value}>{children}</IntlContext.Provider>;
};

const useIntlContext = (): UseIntlContextShape => {
  const context = useContext(IntlContext);
  if (context === undefined) {
    throw new Error('useIntl must be used within a Provider');
  }
  const { state, dispatch } = context;

  const areNameSpacesAdded = useCallback(
    (namespaces: AvailableTranslationsNameSpaces[]): boolean => {
      let status = true;
      for (let i = 0; i < namespaces.length; i++) {
        if (!state.namespaces.includes(namespaces[i])) {
          status = false;
        }
      }
      return status;
    },
    [state]
  );

  const addNameSpaces = useCallback(
    (namespaces: AvailableTranslationsNameSpaces[]): void =>
      dispatch({ type: ACTION_TYPE.ADD_NAMESPACES, payload: { namespaces } }),
    [dispatch]
  );

  const switchLanguage = useCallback(
    (locale: AvailableLocales): void => {
      if (window.localStorage && window.localStorage.setItem) {
        window.localStorage.setItem(G.intl.UALA_LOCALE, locale);
      }
      window.APP_LOCALE = locale;
      dispatch({ type: ACTION_TYPE.SWITCH_LANGUAGE, payload: { locale } });
    },
    [dispatch]
  );

  return {
    state,
    switchLanguage,
    addNameSpaces,
    areNameSpacesAdded,
  };
};

export { IntlProvider, useIntlContext };
