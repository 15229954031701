// Sanitize doesn't export types :'(
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import sanitize from 'sanitize.css/sanitize.css';
import { FunctionComponent } from 'react';
import { Global, css } from '.';
import { theme } from './theme';
import { G } from 'enums/global';

const bodyPreventScrollClassName = `&.${G.modals.bodyPreventScrollClassName}`;
const bodyScrollbarSpaceClassName = `&.${G.modals.bodyScrollbarSpaceClassName}`;

export const GlobalStyles: FunctionComponent = () => (
  <Global
    styles={css`
      ${sanitize};

      body {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background-color: ${theme.colors['gray.100']};
        font-family: ${theme.typography.fontFamilies.default};
        font-size: ${theme.typography.fontSizes.md}px;
        color: ${theme.typography.colors.default};

        ${bodyPreventScrollClassName} {
          overflow: hidden !important;
          position: relative !important;
          padding-left: 0px;
          padding-top: 0px;
          padding-right: 0px;
          margin-left: 0;
          margin-top: 0;
        }

        ${bodyScrollbarSpaceClassName} {
          margin-right: 15px !important;
        }
      }

      a {
        text-decoration: none;
      }
    `}
  />
);
