import { css, styled } from 'styled';

export const Loading = styled.div`
  position: relative;
  display: block;
  text-align: center;
  align-self: center;
  margin: 0 auto;
`;

export const ErrorMessage = styled.div(
  ({ theme }) => css`
    text-align: center;
    color: ${theme.colors['gray.600']};
    display: block;
    position: relative;
    align-self: center;
    margin: 0 auto;
  `
);
