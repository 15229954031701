import { DetailedHTMLProps, InputHTMLAttributes } from 'react';
import { Box, Icon } from 'components';
import { TextProps, Text } from 'components/typography/Text';
import * as S from './Checkbox.theme';
import { SpacingProps } from 'styled';
import { generateId } from 'utils/generateId';

const checkboxIdGenerator = generateId();

type RadioProps = Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'children'> &
  SpacingProps & {
    labelPosition?: 'right' | 'left';
    textColor?: TextProps['color'];
    fontSize?: TextProps['fontSize'];
    id?: string;
    label?: string;
    shape?: S.CustomInputProps['shape'];
  };

export const Checkbox = ({
  shape = 'tick',
  label,
  labelPosition = 'right',
  textColor = 'default',
  fontSize,
  id = `checkbox-${checkboxIdGenerator.next().value}`,
  disabled,
  // margin
  margin,
  m,
  marginX,
  mX,
  marginY,
  mY,
  mB,
  marginBottom,
  mL,
  marginLeft,
  mR,
  marginRight,
  mT,
  marginTop,
  // padding
  padding,
  p,
  paddingX,
  pX,
  paddingY,
  pY,
  pB,
  paddingBottom,
  pL,
  paddingLeft,
  pR,
  paddingRight,
  pT,
  paddingTop,
  ...rest
}: RadioProps): JSX.Element => (
  <Box
    position="relative"
    display="inline-flex"
    alignItems="center"
    flexDirection={labelPosition === 'right' ? 'row' : 'row-reverse'}
    // margin
    margin={margin}
    m={m}
    marginX={marginX}
    mX={mX}
    marginY={marginY}
    mY={mY}
    mB={mB}
    marginBottom={marginBottom}
    mL={mL}
    marginLeft={marginLeft}
    mR={mR}
    marginRight={marginRight}
    mT={mT}
    marginTop={marginTop}
    // padding
    padding={padding}
    p={p}
    paddingX={paddingX}
    pX={pX}
    paddingY={paddingY}
    pY={pY}
    pB={pB}
    paddingBottom={paddingBottom}
    pL={pL}
    paddingLeft={paddingLeft}
    pR={pR}
    paddingRight={paddingRight}
    pT={pT}
    paddingTop={paddingTop}
  >
    <S.Checkbox type="checkbox" id={id} disabled={disabled} {...rest} />
    <S.Label htmlFor={id} direction={labelPosition === 'right' ? 'row' : 'row-reverse'} showCursor={Boolean(id)}>
      {shape === 'tick' && (
        <S.CustomInput shape={shape}>
          <Icon iconName="Tick" color={disabled ? 'gray.200' : 'blue.500'} size={15} />
        </S.CustomInput>
      )}
      {shape === 'circle' && <S.CustomInput shape={shape} />}
      {label && (
        <Text as="span" color={textColor} fontSize={fontSize}>
          {label}
        </Text>
      )}
    </S.Label>
  </Box>
);
