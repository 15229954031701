import { Colors, css, FontColors, SerializedStyles, styled, getBaseProps, BaseProps, CSSProperties } from 'styled';
import { LiteralUnion } from 'utilityTypes';
import { lightenDarkenColor } from 'utils/lighten-darken-color';

export type StyledLinkProps = Omit<
  BaseProps,
  'as' | 'color' | 'bgColor' | 'background' | 'theme' | 'backgroundColor'
> & {
  /**
   * @description
   * shorthand for textAlign
   */
  align?: CSSProperties['textAlign'];
  /**
   * @description
   * shorthand for textTransform
   */
  casing?: CSSProperties['textTransform'];
  /**
   * @description
   * use typography color scheme by default,
   * with fallback on palette and hex string
   */
  color?: LiteralUnion<FontColors, string>;
};

export const Link = styled.a<StyledLinkProps>`
  ${({ theme, color = 'primary', align, textAlign, casing, textTransform, ...rest }): SerializedStyles => css`
    color: ${theme.typography.colors[color as FontColors] || theme.colors[color as Colors] || color};
    ${(align || textAlign) && `text-align: ${align || textAlign};`};
    ${(casing || textTransform) && `text-transform: ${casing || textTransform};`};
    cursor: pointer;
    transition: 0.3s;

    ${getBaseProps({ theme, ...rest })};

    &:hover {
      color: ${lightenDarkenColor(
        theme.typography.colors[color as FontColors] || theme.colors[color as Colors] || color,
        -20
      )};
    }
  `}
`;
