import { FunctionComponent } from 'react';

import { styled, BaseProps, AsElement, CSSProperties, getBaseProps, css } from 'styled';
import { FontSizes, FontColors, Colors } from 'styled/tokens';
import { LiteralUnion } from 'utilityTypes';

export type TextProps = Omit<BaseProps, 'color'> &
  AsElement & {
    /**
     * @description
     * shorthand for textAlign
     */
    align?: CSSProperties['textAlign'];
    /**
     * @description
     * shorthand for textTransform
     */
    casing?: CSSProperties['textTransform'];
    /**
     * @description
     * shorthand for fontSize
     */
    size?: BaseProps['fontSize'];
    /**
     * @description
     * use typography color scheme by default,
     * with graceful fallback on palette and hex string
     */
    color?: LiteralUnion<FontColors, string>;
  };

const Text: FunctionComponent<TextProps> = styled.span<TextProps>(
  ({ align, casing, size, theme, color = 'default', ...rest }) =>
    css`
      ${getBaseProps({ theme, ...rest })}
      ${align && { textAlign: align }}
      ${casing && { textTransform: casing }}
      ${size && { fontSize: theme?.typography.fontSizes[size as FontSizes] || size }}
      ${color && { color: theme?.typography.colors[color as FontColors] || theme?.colors[color as Colors] || color }}
    `
);

export { Text };
