import { FunctionComponent } from 'react';
import {
  ModalHeader,
  ModalContent,
  ModalContentProps,
  ModalOverlay,
  ModalOverlayProps,
  ModalBody,
  ModalHeaderProps,
} from '.';

export type ModalProps = ModalContentProps &
  ModalOverlayProps & {
    title?: ModalHeaderProps['title'];
    titleColor?: ModalHeaderProps['titleColor'];
    showCloseButton?: ModalHeaderProps['showCloseButton'];
    headerWithBorder?: ModalHeaderProps['hasBorderBottom'];
  };

export const Modal: FunctionComponent<ModalProps> = ({
  children,
  onClose,
  title,
  titleColor,
  isOpen,
  showCloseButton = true,
  fullScreenOnMobile = false,
  minWidth = { initial: 'auto', xs: '350px', sm: '400px' },
  maxWidth = '100%',
  minHeight = '250px',
  maxHeight,
  width = { initial: '100%', xs: 'auto' },
  height,
  bgColor = 'white',
  contentPosition = 'center',
  headerWithBorder = false,
  preventOnCloseBackground = false,
}) => (
  <ModalOverlay
    isOpen={isOpen}
    onClose={onClose}
    fullScreenOnMobile={fullScreenOnMobile}
    contentPosition={contentPosition}
    preventOnCloseBackground={preventOnCloseBackground}
  >
    <ModalContent
      height={height}
      width={width}
      minHeight={minHeight}
      minWidth={minWidth}
      maxWidth={maxWidth}
      maxHeight={maxHeight}
      bgColor={bgColor}
      fullScreenOnMobile={fullScreenOnMobile}
    >
      {(title || showCloseButton) && (
        <ModalHeader
          showCloseButton={showCloseButton}
          onClose={onClose}
          hasBorderBottom={headerWithBorder}
          title={title}
          titleColor={titleColor}
        />
      )}
      <ModalBody>{children}</ModalBody>
    </ModalContent>
  </ModalOverlay>
);
