import { useContext } from 'react';
import { ViewportContext } from '.';

export const useViewportProvider = (): {
  vw: number;
  vh: number;
} => {
  const context = useContext(ViewportContext);
  if (context === undefined) {
    throw new Error('useViewportProvider must be used within ViewportContext ');
  }
  return context;
};
