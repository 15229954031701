import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';

import { Box, Text } from 'components';

import * as S from './Breadcrumb.theme';

interface Props {
  separatorIcon: JSX.Element;
  history: ReadonlyArray<{ path: string; id: string; label: string }>;
  onClick: (path: `/${string}/`) => void;
}
interface HandleClick {
  (e: { id: string; routes: Props['history'] }): void;
}

export const Breadcrumb: (p: Props) => JSX.Element = ({ history, onClick, separatorIcon }) => {
  const maybeNextPosition = (currentId: string): O.Option<number> =>
    O.fromNullable(history.map(({ id }) => id).indexOf(currentId));

  const handleClick: HandleClick = ({ id, routes }) =>
    pipe(
      maybeNextPosition(id),
      O.map((nextPosition) =>
        onClick(
          routes
            .slice(0, nextPosition + 1)
            .map(({ path }) => path)
            .join('/') as `/${string}/`
        )
      )
    );
  return (
    <S.Breadcrumb>
      {history.map(({ label, id }, i) => (
        <Box key={id} display="inline-flex">
          <Box onClick={i < history.length - 1 ? (): void => handleClick({ id, routes: history }) : (): void => {}}>
            <Text
              fontSize="xs"
              fontWeight="bold"
              textTransform="uppercase"
              padding="0px 10px"
              cursor="pointer"
              textAlign="center"
              display="flex"
            >
              {label}
            </Text>
          </Box>
          {i < history.length - 1 && <S.Separator>{separatorIcon}</S.Separator>}
        </Box>
      ))}
    </S.Breadcrumb>
  );
};
