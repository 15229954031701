import { IntlMessagesShape, AvailableTranslationsNameSpaces } from './types';

import itMessages from 'translations/it.json';
import enMessages from 'translations/en.json';
import esMessages from 'translations/es.json';
import ptMessages from 'translations/pt.json';
import elMessages from 'translations/el.json';
import frMessages from 'translations/fr.json';

type MessagesObject = Record<AvailableTranslationsNameSpaces | 'global', IntlMessagesShape>;

export const messages: MessagesObject = {
  global: {
    en: enMessages,
    it: itMessages,
    es: esMessages,
    el: elMessages,
    fr: frMessages,
    pt: ptMessages,
  },
};
