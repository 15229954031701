import { FunctionComponent } from 'react';
import { QueryClientProvider, QueryClient } from 'react-query';

import { clearClientSession, AuthProvider, ViewportProvider, FiltersProvider } from './providers';
import { ThemeProvider, theme } from './styled';
import { ReactQueryDevtools } from 'react-query/devtools';
import { G } from 'enums/global';
import { HttpErrorResponse } from 'utils/HttpErrorResponse';
import { CustomIntlProvider } from 'providers/intl-provider';

import { TooltipProvider } from '@uala/ui-treatwell-pro';

const globalErrorHandler = (error: unknown): void => {
  const handledError = error as HttpErrorResponse;

  if (handledError && handledError.statusCode && handledError.statusCode === 401) {
    clearClientSession();
    window.location.reload();
  }
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: G.staleTime,
      onError: globalErrorHandler,
    },
  },
});

const AppProviders: FunctionComponent = ({ children }) => (
  <CustomIntlProvider>
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools />
        <ViewportProvider>
          <FiltersProvider>
            <AuthProvider>
              <TooltipProvider>{children}</TooltipProvider>
            </AuthProvider>
          </FiltersProvider>
        </ViewportProvider>
      </QueryClientProvider>
    </ThemeProvider>
  </CustomIntlProvider>
);

export { AppProviders };
