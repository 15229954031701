/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { styled, css, SerializedStyles, Theme, getMarginProps, MarginProps } from 'styled';
import { lightenDarkenColor } from 'utils/lighten-darken-color';
import { hexToRgba } from 'utils/hex-to-rgba';

export type ButtonProps = MarginProps & {
  variant?: Variant;
  tone?: Tone;
  size?: Size;
  width?: string;
  maxWidth?: string;
  isFullWidth?: boolean;
  hasShadow?: boolean;
  isLoading?: boolean;
};

type Variant = 'solid' | 'outline' | 'transparent';
type Tone = 'primary' | 'danger' | 'light';
type Size = 'sm' | 'md';

const getMainColorByTone = (theme: Theme, tone?: Tone): string => {
  switch (tone) {
    case 'danger':
      return theme.colors['red.400'];
    case 'light':
      return theme.colors['gray.300'];
    case 'primary':
    default:
      return theme.colors['blue.500'];
  }
};

const getConditionalCSS = (theme: Theme, variant?: Variant, tone?: Tone): SerializedStyles => {
  const mainColor = getMainColorByTone(theme, tone);

  switch (variant) {
    case 'outline': {
      return css`
        color: ${mainColor};
        border: 1px solid ${mainColor};
        background: transparent;

        &:hover {
          color: ${lightenDarkenColor(mainColor, -20)};
          border: 1px solid ${lightenDarkenColor(mainColor, -20)};
        }

        &:active {
          color: ${lightenDarkenColor(mainColor, -25)};
          border: 1px solid ${lightenDarkenColor(mainColor, -25)};
        }

        &:focus {
          color: ${lightenDarkenColor(mainColor, -30)};
          border: 1px solid ${lightenDarkenColor(mainColor, -30)};
          background-color: ${hexToRgba(mainColor, 0.02)};
        }
      `;
    }
    case 'transparent': {
      return css`
        border: 0;
        color: ${mainColor};
        background: transparent;

        &:hover {
          color: ${lightenDarkenColor(mainColor, -20)};
        }

        &:active {
          color: ${lightenDarkenColor(mainColor, -25)};
        }

        &:focus {
          color: ${lightenDarkenColor(mainColor, -30)};
        }
      `;
    }
    case 'solid':
    default: {
      return css`
        border: 0;
        color: ${theme.colors.white};
        background-color: ${mainColor};

        &:hover {
          background-color: ${lightenDarkenColor(mainColor, -10)};
        }

        &:active {
          background-color: ${lightenDarkenColor(mainColor, -15)};
        }

        &:focus {
          background-color: ${lightenDarkenColor(mainColor, -20)};
        }
      `;
    }
  }
};

const getSizeCSS = (theme: Theme, size?: Size): SerializedStyles => {
  switch (size) {
    case 'sm': {
      return css`
        border-radius: 21px;
        font-size: ${theme.typography.fontSizes['2xs']}px;
        padding: 8px 12px;
      `;
    }
    case 'md':
    default: {
      return css`
        border-radius: 21px;
        font-size: ${theme.typography.fontSizes.xs}px;
        padding: 12px 32px;
      `;
    }
  }
};

export const Button = styled.button<ButtonProps>`
  ${({
    theme,
    variant = 'solid',
    tone = 'primary',
    size = 'md',
    maxWidth,
    width,
    hasShadow = false,
    isLoading,
    isFullWidth,
    margin,
    m,
    marginX,
    mX,
    marginY,
    mY,
    mB,
    marginBottom,
    mL,
    marginLeft,
    mR,
    marginRight,
    mT,
    marginTop,
  }) => css`
    position: relative;
    display: inline-flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-family: ${theme.typography.fontFamilies.default};
    font-weight: ${theme.typography.fontWeights['bold']};
    text-align: center;
    outline: 0;
    text-transform: uppercase;
    letter-spacing: 0.72px;

    ${getMarginProps({
      margin,
      m,
      marginX,
      mX,
      marginY,
      mY,
      mB,
      marginBottom,
      mL,
      marginLeft,
      mR,
      marginRight,
      mT,
      marginTop,
    })}

    &:hover {
      cursor: pointer;
    }

    &:disabled {
      pointer-events: none;
      opacity: 0.3;
      cursor: not-allowed;
    }

    ${isFullWidth &&
    css`
      width: 100%;
      max-width: 100%;
    `}

    ${width &&
    css`
      width: ${typeof maxWidth === 'number' ? `${width}px` : width};
    `}

    ${maxWidth &&
    css`
      max-width: ${typeof maxWidth === 'number' ? `${maxWidth}px` : maxWidth};
    `}

    ${hasShadow &&
    css`
      box-shadow: ${theme.boxShadows.md};
    `}

    ${getSizeCSS(theme, size)}
    ${getConditionalCSS(theme, variant, tone)}

    ${isLoading &&
    css`
      pointer-events: none;
      opacity: 0.65;
      color: transparent;
      &:hover {
        cursor: default;
      }
    `}
      
    

    transition: .3s;
  `}
`;
