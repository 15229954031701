import { RefObject, useEffect } from 'react';

export function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler?: (event: MouseEvent) => void
): void {
  useEffect(() => {
    if (!handler) return;
    const listener = (event: MouseEvent): void => {
      const currentElement = ref?.current;
      if (!currentElement || currentElement.contains(event.target as Node)) return;
      handler(event);
    };
    document.addEventListener(`mousedown`, listener);
    return (): void => {
      document.removeEventListener(`mousedown`, listener);
    };
  }, [ref, handler]);
}
