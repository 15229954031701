import {
  CSSProperties,
  styled,
  css,
  ResponsiveProp,
  SerializedStyles,
  resolveResponsiveProp,
  getFullResponsiveObj,
} from 'styled';

type Direction = 'row' | 'row-reverse' | 'column' | 'column-reverse';

export type StyledStackProps = {
  /**
   * @description shorthand for Shorthand for alignItems
   */
  align?: ResponsiveProp<CSSProperties['alignItems']>;
  /**
   * @description shorthand for Shorthand for flexDirection
   */
  direction?: ResponsiveProp<Direction>;
  /**
   * @description shorthand for Shorthand for justifyContent
   */
  justify?: ResponsiveProp<CSSProperties['justifyContent']>;
  /**
   * @description shorthand for Shorthand for flexWrap
   */
  wrap?: ResponsiveProp<CSSProperties['flexWrap']>;
  /**
   * @description the space between each stack item
   */
  spacing: ResponsiveProp<string>;
  width?: ResponsiveProp<string>;
};

const getSpacingCssByDirection = (direction: Direction, value: StyledStackProps['spacing']): SerializedStyles => {
  switch (direction) {
    case 'row-reverse':
      return css`
        margin-right: ${value};
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 0;
      `;
    case 'column':
      return css`
        margin-right: 0;
        margin-top: ${value};
        margin-bottom: 0;
        margin-left: 0;
      `;
    case 'column-reverse':
      return css`
        margin-right: 0;
        margin-top: 0;
        margin-bottom: ${value};
        margin-left: 0;
      `;
    case 'row':
    default:
      return css`
        margin-right: 0;
        margin-top: 0;
        margin-bottom: 0;
        margin-left: ${value};
      `;
  }
};

const spacingResolverFunction = (value: StyledStackProps['spacing'], direction: Direction): SerializedStyles => {
  return css`
    > :not(style) ~ :not(style) {
      ${getSpacingCssByDirection(direction, value)}
    }
  `;
};

export const Stack = styled.div<StyledStackProps>(({ align, direction = 'row', justify, wrap, spacing, width }) => {
  const directionObj = getFullResponsiveObj(direction);
  const spacingObj = getFullResponsiveObj(spacing);

  return css`
    display: flex;

    ${align && resolveResponsiveProp({ propName: 'align-items', value: align })}
    ${direction && resolveResponsiveProp({ propName: 'flex-direction', value: direction })}
    ${justify && resolveResponsiveProp({ propName: 'justify-content', value: justify })}
    ${wrap && resolveResponsiveProp({ propName: 'flex-wrap', value: wrap })}
    ${width && resolveResponsiveProp({ propName: 'width', value: width })}

    ${spacing &&
    resolveResponsiveProp({
      propName: '',
      value: spacingObj,
      resolverFn: (value, breakpoint) => spacingResolverFunction(value, directionObj[breakpoint]),
    })}
  `;
});
