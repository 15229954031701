import React, { useRef, useEffect, ReactElement, useCallback } from 'react';
import ReactDOMServer from 'react-dom/server';

import { Selection, pointer, scaleOrdinal, select, geoPath, geoMercator, scaleLinear, BaseType } from 'd3';
import { FeatureCollection, GeoJsonProperties, Geometry } from 'geojson';
import { COLORS } from 'styled';
import { useIntl } from 'providers';
import { ValueOf } from 'utilityTypes';
import * as S from './GeoChart.theme';

export interface GeoChartProps {
  height?: number;
  width?: number;
  activeGroups?: ReadonlyArray<string>;
  style: {
    groupsColors: ReadonlyArray<string>;
    mapStrokeColor: string;
    tooltip: Readonly<{
      backgroundColor: ValueOf<typeof COLORS>;
      boxShadow: string;
      fontSize: number;
      color: ValueOf<typeof COLORS>;
    }>;
  };
  scope: 'BE' | 'IT' | 'EU' | 'FR' | 'ES' | 'DE' | 'GB' | 'UK' | 'PT' | 'GR';
  groups: ReadonlyArray<{
    readonly selection_id: `${number}`;
    readonly regions: ReadonlyArray<{ lat: number; long: number; size: number; name: string }>;
  }>;
}

type D3Selection = Selection<BaseType, unknown, null, undefined>;

export const GeoChart: (p: GeoChartProps) => JSX.Element = ({
  height = 650,
  width = 650,
  groups,
  activeGroups,
  scope = 'IT',
  style: { groupsColors, mapStrokeColor, tooltip },
}) => {
  const { formatMessage } = useIntl();
  const svgRef = useRef<React.SVGProps<SVGSVGElement>>();

  const size = scaleLinear().domain([0, 100]).range([10, 100]);
  const color = scaleOrdinal().range(groupsColors);

  const cleanPreviousNodes = (selection: D3Selection, toBeRemoved: ReadonlyArray<string>): void => {
    toBeRemoved.map((domNode) => selection.selectAll(domNode).remove());
  };

  const getCurrentGeoJSON = useCallback(
    async (mapName: GeoChartProps['scope']): Promise<FeatureCollection<Geometry, GeoJsonProperties>> => {
      try {
        // Try to load matching map for received country
        const geoJSON = await import(`maps/${mapName.toLowerCase()}.json`);
        return geoJSON;
      } catch (_err) {
        // Gracefully fallback to europe
        const geoJSON = await import(`maps/europe.json`);
        return geoJSON as FeatureCollection<Geometry, GeoJsonProperties>;
      }
    },
    []
  );
  const mapRendering: (p: {
    currentMap: FeatureCollection<Geometry, GeoJsonProperties>;
    selection: D3Selection;
    scope: string;
  }) => void = useCallback(
    async (p) => {
      const projection = geoMercator().fitSize([width, height], p.currentMap);

      p.selection
        .append('g')
        .selectAll('path')
        .data(p.currentMap.features)
        .enter()
        .append('path')
        .attr('fill', 'white')
        .attr('d', geoPath().projection(projection) as unknown as string)
        .style('stroke', mapStrokeColor)
        .style('opacity', 0.3);
    },
    [height, mapStrokeColor, width]
  );

  const groupsRendering: (p: {
    currentMap: FeatureCollection<Geometry, GeoJsonProperties>;
    selection: D3Selection;
    onMouseOver: (this: SVGCircleElement, e: Event) => void;
    onMouseLeave: () => void;
    onMouseMove: (this: SVGCircleElement, e: Event) => void;
    activeGroups?: ReadonlyArray<string>;
  }) => void = useCallback(
    async ({ selection, onMouseLeave, onMouseMove, onMouseOver, currentMap }) => {
      const projection = geoMercator().fitSize([width, height], currentMap);

      return groups.map(({ selection_id, regions }) =>
        activeGroups?.includes(selection_id)
          ? selection
              .selectAll()
              .data(regions)
              .enter()
              .append('circle')
              .attr('cx', ({ long, lat }) => projection([long, lat])?.[0] || null)
              .attr('cy', ({ long, lat }) => projection([long, lat])?.[1] || null)
              .attr('r', (d) => size(d.size) || '')
              .style('fill', () => color(String(selection_id)) as string)
              .attr('stroke', () => color(String(selection_id)) as string)
              .on('mouseover', onMouseOver)
              .on('mousemove', onMouseMove)
              .on('mouseleave', onMouseLeave)
              .attr('stroke-width', 3)
              .transition()
              .duration(1000)
              .style('opacity', 1)
              .style('cursor', 'pointer')
              .attr('fill-opacity', 0.4)
          : selection.selectAll().attr('stroke-width', 0).transition().duration(1000).style('opacity', 0).attr('r', 0)
      );
    },
    [activeGroups, color, groups, height, size, width]
  );

  const getDataFromTooltip = (
    t: SVGCircleElement
  ): {
    lat: number;
    long: number;
    group: 'comparison';
    size: number;
    name: string;
  } => select(t).datum() as { lat: number; long: number; group: 'comparison'; size: number; name: string };

  const showTooltip = useCallback(
    (t: Selection<HTMLDivElement, unknown, HTMLElement, unknown>) =>
      function (this: SVGCircleElement, e: Event): void {
        const tooltipData = getDataFromTooltip(this);

        const Component: ReactElement = (
          <S.Tooltip
            backgroundColor={tooltip.backgroundColor}
            boxShadow={tooltip.boxShadow}
            fontSize={tooltip.fontSize}
            color={tooltip.color}
          >
            <span>
              {formatMessage({ id: 'Area' })} : {tooltipData?.name} <br />
            </span>
            <span>
              {formatMessage({ id: 'salons-number.title' })} : {tooltipData?.size}
            </span>
          </S.Tooltip>
        );

        t.style('opacity', 1)
          .html(ReactDOMServer.renderToString(Component))
          .style('left', pointer(e)[0] + 30 + 'px')
          .style('top', pointer(e)[1] + 30 + 'px')
          .style('z-index', 2);
      },
    [formatMessage, tooltip.backgroundColor, tooltip.boxShadow, tooltip.color, tooltip.fontSize]
  );

  const moveTooltip = (t: Selection<HTMLDivElement, unknown, HTMLElement, unknown>) =>
    function (this: SVGCircleElement, e: Event): void {
      t.style('left', pointer(e)[0] + 10 + 'px').style('top', pointer(e)[1] + 10 + 'px');
    };

  const hideTooltip = (t: Selection<HTMLDivElement, unknown, HTMLElement, unknown>) =>
    function (): void {
      t.selectAll('.tooltip').remove();
      t.transition().duration(200).style('z-index', -1).style('opacity', 1);
    };

  useEffect(() => {
    async function renderingTheMap(): Promise<void> {
      const mountingNode: D3Selection = select(svgRef.current as unknown as BaseType);
      const currentMap: FeatureCollection<Geometry, GeoJsonProperties> = await getCurrentGeoJSON(scope);
      cleanPreviousNodes(mountingNode, ['circle', '.tooltip']);
      mapRendering({ currentMap, selection: mountingNode, scope });

      const tooltipNode = select('#map_wrapper')
        .append('div')
        .style('opacity', 1)
        .attr('class', 'tooltip')
        .style('position', 'absolute')
        .style('top', '10px')
        .style('right', '10px')
        .style('width', '100px')
        .style('z-index', 1);
      groupsRendering({
        activeGroups,
        currentMap,
        selection: mountingNode,
        onMouseLeave: hideTooltip(tooltipNode),
        onMouseMove: moveTooltip(tooltipNode),
        onMouseOver: showTooltip(tooltipNode),
      });
    }
    renderingTheMap();
  }, [activeGroups, scope, groupsRendering, mapRendering, showTooltip, getCurrentGeoJSON]);

  return (
    <S.MapWrapper id="map_wrapper">
      <svg ref={svgRef as unknown as React.RefObject<SVGSVGElement>} />
    </S.MapWrapper>
  );
};
