import { useEffect, useRef } from 'react';
import { useSelect } from './hooks';
import { Box, Icon, Text } from 'components';
import * as S from './Select.theme';

type SelectProps<T> = S.StyledSelectProps & {
  options: {
    value: T;
    label: string;
  }[];
  defaultValue?: T;
  value?: T;
  onChange?: (value: T) => void;
  width?: string;
  maxWidth?: string;
  minWidth?: string;
  isDisabled?: boolean;
};

type SelectComponentShape = <T>(props: SelectProps<T>) => JSX.Element | null;

export const Select: SelectComponentShape = ({
  options,
  defaultValue,
  value,
  onChange = (): void => {},
  width = '100%',
  maxWidth = '200px',
  minWidth = '70px',
  margin,
  m,
  marginX,
  mX,
  marginY,
  mY,
  mB,
  marginBottom,
  mL,
  marginLeft,
  mR,
  marginRight,
  mT,
  marginTop,
  isDisabled,
}): JSX.Element => {
  const { selectedItem, highlightedIndex, isOpen, getToggleButtonProps, getListItemProps, getListProps, selectItem } =
    useSelect({
      items: options.map((option) => option.value),
      refKey: 'ref',
      defaultSelectedItem: defaultValue || value,
    });
  const valueRef = useRef(value);
  const selectedOption = options.find((option) => option.value === selectedItem);

  useEffect(() => {
    // this check is needed for controlled select
    if (selectedItem !== valueRef.current && selectedItem) {
      onChange(selectedItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  useEffect(() => {
    // controlled select
    if (value && value !== valueRef.current) {
      valueRef.current = value;
      selectItem(value);
    }
  }, [value, selectItem]);

  return (
    <Box
      position="relative"
      width={width}
      minWidth={minWidth}
      maxWidth={maxWidth}
      margin={margin}
      m={m}
      marginX={marginX}
      mX={mX}
      marginY={marginY}
      mY={mY}
      mB={mB}
      marginBottom={marginBottom}
      mL={mL}
      marginLeft={marginLeft}
      mR={mR}
      marginRight={marginRight}
      mT={mT}
      marginTop={marginTop}
    >
      <S.Button disabled={isDisabled} style={{ letterSpacing: 'initial' }} {...getToggleButtonProps()}>
        <Text size="md" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
          {selectedOption?.label}
        </Text>
        <Box as="span" display="flex" alignItems="center" marginLeft="auto" paddingLeft="8px">
          <Icon iconName="Selector" size={11} />
        </Box>
      </S.Button>
      {isOpen && (
        <S.List {...getListProps()}>
          {options.map((item, index) => (
            <S.Item
              key={`select-item-${item.value}`}
              isHighlighted={highlightedIndex === index}
              isSelected={selectedItem === item.value}
              {...getListItemProps({ item: item.value, index })}
            >
              {item.label}
            </S.Item>
          ))}
        </S.List>
      )}
    </Box>
  );
};
