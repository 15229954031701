import { DatePickerProps } from 'components/date-picker';
import { G } from 'enums/global';

export type FiltersState = {
  datePickerState: NonNullable<DatePickerProps['datePickerState']>;
  comparison_period_to?: string;
  comparison_period_from?: string;
  main_period_from: string;
  main_period_to: string;
  group_ids: ReadonlyArray<number>;
  brand_id: number;
};

export const ACTION_TYPE = {
  ADD_GROUPS: '@filters/ADD_GROUPS',
  REMOVE_GROUPS: '@filters/REMOVE_GROUPS',
  SET_GROUPS: '@filters/SET_GROUPS',
  SET_BRAND: '@filters/SET_BRAND',
  SET_DATE_RANGE: '@filters/SET_DATE_RANGE',
  SET_COMPARISON_DATE_RANGE: '@filters/SET_COMPARISON_DATE_RANGE',
  SET_DATEPICKER_STATE: '@filters/SET_DATEPICKER_STATE',
} as const;

export type DateRange = {
  startDate: string | null;
  endDate: string | null;
};

type AddGroupsAction = {
  type: typeof ACTION_TYPE.ADD_GROUPS;
  payload: {
    groupIds: FiltersState['group_ids'];
  };
};
type RemoveGroupsAction = {
  type: typeof ACTION_TYPE.REMOVE_GROUPS;
  payload: {
    groupIds: FiltersState['group_ids'];
  };
};
type SetGroupsAction = {
  type: typeof ACTION_TYPE.SET_GROUPS;
  payload: {
    groupIds: FiltersState['group_ids'];
  };
};

type SetDateRangeAction = {
  type: typeof ACTION_TYPE.SET_DATE_RANGE;
  payload: DateRange;
};

type SetComparisonDateRangeAction = {
  type: typeof ACTION_TYPE.SET_COMPARISON_DATE_RANGE;
  payload: DateRange;
};

type SetDatePickerAction = {
  type: typeof ACTION_TYPE.SET_DATEPICKER_STATE;
  payload: { datePickerState: FiltersState['datePickerState'] };
};

type SetBrandAction = {
  type: typeof ACTION_TYPE.SET_BRAND;
  payload: {
    brandId: FiltersState['brand_id'];
  };
};

export type FiltersAction =
  | AddGroupsAction
  | RemoveGroupsAction
  | SetGroupsAction
  | SetBrandAction
  | SetDateRangeAction
  | SetComparisonDateRangeAction
  | SetDatePickerAction;

export const filterReducer = (state: FiltersState, action: FiltersAction): FiltersState => {
  switch (action.type) {
    case ACTION_TYPE.ADD_GROUPS: {
      const { groupIds } = action.payload;

      const updatedGroupIds = Array.from(new Set([...state.group_ids, ...groupIds]));
      localStorage.setItem(G.localStorage.SESSION_GROUPS, updatedGroupIds.join(','));

      return {
        ...state,
        group_ids: updatedGroupIds,
        comparison_period_from: undefined,
        comparison_period_to: undefined,
        datePickerState: {
          ...state.datePickerState,
          isComparisonActive: false,
          isComparisonDisabled: true,
          comparisonDateRange: null,
        },
      };
    }
    case ACTION_TYPE.REMOVE_GROUPS: {
      const { groupIds } = action.payload;

      const { group_ids } = state;
      const filteredGroupId = group_ids.filter((groupId) => !groupIds.includes(groupId));

      // If there're no remaining groups, clear out store key
      filteredGroupId.length === 0
        ? localStorage.removeItem(G.localStorage.SESSION_GROUPS)
        : localStorage.setItem(G.localStorage.SESSION_GROUPS, filteredGroupId.join(','));

      return {
        ...state,
        group_ids: filteredGroupId,
        datePickerState: { ...state.datePickerState, isComparisonDisabled: filteredGroupId.length !== 0 },
      };
    }
    case ACTION_TYPE.SET_GROUPS: {
      const { groupIds } = action.payload;

      localStorage.setItem(G.localStorage.SESSION_GROUPS, groupIds.join(','));

      return { ...state, group_ids: groupIds };
    }
    case ACTION_TYPE.SET_BRAND: {
      const { brandId } = action.payload;

      localStorage.setItem(G.localStorage.SESSION_BRAND, brandId.toString());

      return { ...state, brand_id: brandId };
    }
    case ACTION_TYPE.SET_DATE_RANGE: {
      const { startDate, endDate } = action.payload;
      return {
        ...state,
        main_period_from: startDate || state.main_period_from,
        main_period_to: endDate || state.main_period_to,
      };
    }
    case ACTION_TYPE.SET_COMPARISON_DATE_RANGE: {
      const { startDate, endDate } = action.payload;
      return { ...state, comparison_period_from: startDate ?? undefined, comparison_period_to: endDate ?? undefined };
    }
    case ACTION_TYPE.SET_DATEPICKER_STATE: {
      const { datePickerState } = action.payload;
      return { ...state, datePickerState };
    }
    default:
      return state;
  }
};
