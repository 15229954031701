import { useCallback, useState } from 'react';

/**
 * @typedef {Object} UseDisclousureReturnType
 * @property {number} isOpen Callback function to set a falsy value for the isOpen parameter
 * @property {number} onClose Callback function to set a truthy value for the isOpen parameter
 * @property {number} onToggle Callback function to toggle the value of the isOpen parameter.
 */

/**
 *
 * @description
 * useDisclosure is a custom hook used to help handle common open, close, or toggle scenarios.
 * It can be used to control feedback component such as Modal, AlertDialog, etc.
 *
 * @example
 * ```ts
 * const { isOpen, onClose, onOpen, onToggle } = useDisclosure();
 * ```
 *
 * @param {Object} options
 * @param {boolean} options.defaultIsOpen default isOpen value
 * @param {function} options.onClose onClose callback function
 * @param {function} options.onOpen onOpen callback function
 * @returns {UseDisclousureReturnType}
 *
 */
export const useDisclosure: (params?: { defaultIsOpen?: boolean; onClose?: () => void; onOpen?: () => void }) => {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  onToggle: () => void;
} = ({ defaultIsOpen = false, onClose: onCloseProp, onOpen: onOpenProp } = {}) => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);

  const onClose = useCallback(() => {
    setIsOpen(false);
    onCloseProp && onCloseProp();
  }, [onCloseProp]);

  const onOpen = useCallback(() => {
    setIsOpen(true);
    onOpenProp && onOpenProp();
  }, [onOpenProp]);

  const onToggle = useCallback(() => setIsOpen((prev) => !prev), []);

  return {
    isOpen,
    onClose,
    onOpen,
    onToggle,
  };
};
