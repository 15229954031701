import { styled, css } from 'styled';

export const LanguageSwitcher = styled.div`
  position: relative;
  height: 26px;
  width: 80px;
`;

export const Locale = styled.div(
  ({ theme }) => css`
    width: 25px;
    font-size: ${theme.typography.fontSizes.xs}px;
    text-transform: capitalize;
  `
);

export const AngleDownIcon = styled.div`
  margin-left: 10px;
  width: 8px;
`;

export const LanguageValue = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    cursor: pointer;
    position: absolute;
    box-sizing: border-box;
    align-items: center;
    padding: 5px;
    width: 80px;
    height: 26px;
    line-height: 16px;
    background-color: ${theme.colors['gray.200']};
    border-radius: 13px;

    > svg {
      margin-right: 5px;
      width: 16px;
      height: 16px;
      border-radius: 100%;
    }
    :hover {
      background-color: ${theme.colors['gray.300']};
    }
  `
);

export const LanguageOptions = styled.div<{ position?: 'bottom' | 'top' }>(
  ({ theme, position = 'bottom' }) => css`
    position: absolute;
    z-index: 2;
    box-sizing: border-box;
    width: 80px;
    bottom: ${position === 'top' ? 0 : 'auto'};
    top: ${position === 'bottom' ? 0 : 'auto'};
    background-color: ${theme.colors['gray.200']};
    border-radius: 13px;

    &:focus {
      outline: 0;
    }
  `
);
