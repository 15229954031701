import { ChangeEvent, InputHTMLAttributes, useCallback, forwardRef, FunctionComponent, CSSProperties } from 'react';
import * as S from './Input.theme';

export type InputProps = Pick<
  InputHTMLAttributes<HTMLInputElement>,
  | 'placeholder'
  | 'disabled'
  | 'min'
  | 'max'
  | 'minLength'
  | 'maxLength'
  | 'readOnly'
  | 'required'
  | 'value'
  | 'defaultValue'
  | 'onFocus'
  | 'onBlur'
  | 'name'
  | 'autoComplete'
  | 'autoFocus'
> &
  S.StyledInputProps & {
    type?: 'password' | 'email' | 'text' | 'number';
    onChange?: (value: HTMLInputElement['value']) => void;
    style?: CSSProperties;
  };

export const Input: FunctionComponent<InputProps> = forwardRef<HTMLInputElement, InputProps>(
  ({ autoComplete = 'off', onChange = (): void => {}, name, ...rest }, ref) => {
    const handleOnChange: InputHTMLAttributes<HTMLInputElement>['onChange'] = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.value);
      },
      [onChange]
    );

    return <S.Input onChange={handleOnChange} ref={ref} name={name} id={name} {...rest} />;
  }
);
