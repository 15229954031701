import { Box } from 'components';
import { css, styled } from 'styled';

export const ChipButton = styled(Box)<{ border?: string }>(
  ({
    theme: {
      typography: { fontSizes },
    },
  }) => css`
    font-size: ${fontSizes.xs}px;
    cursor: pointer;
    border-radius: 25px;
  `
);
