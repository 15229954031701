import { FunctionComponent } from 'react';
import { ScatterChart, Scatter, XAxis, ReferenceLine, YAxis, ZAxis, CartesianGrid, Tooltip } from 'recharts';

import { COLORS, Colors } from 'styled';
import { Box } from 'components/box';
import { Text } from 'components/typography';

export type Group = {
  data: {
    x: number;
    y: number;
    z: number;
    x_label: number;
    y_label: number;
    z_label: number;
    staff_members_number: number;
  }[];
  fill: Colors;
  name: string;
};

export interface Props {
  groups: Group[];
  xAxisLabel?: string;
  axisLabelLow?: string;
  axisLabelHigh?: string;
  yAxisLabel?: string;
  tooltipContent?: FunctionComponent;
}

const DistributedScatterChart = ({
  groups,
  xAxisLabel,
  yAxisLabel,
  tooltipContent,
  axisLabelHigh,
  axisLabelLow,
}: Props): JSX.Element => {
  // Since groups are an array, we need to flat out the map result, since it will be an array of array
  const zFlattenValues = groups.map(({ data }) => data.map(({ z }) => z * 7)).flat();

  // Dynamically sets z range
  const zMax = Math.max(...zFlattenValues);
  const zMin = Math.min(...zFlattenValues);

  const TooltipContent = tooltipContent;

  return (
    <Box position="relative" height="100%" alignSelf="center">
      <ScatterChart
        style={{ width: '100%', height: '100%' }}
        width={485}
        height={450}
        margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
      >
        <CartesianGrid stroke="none" />
        <XAxis hide dataKey="x" type="number" name="x_axis" />
        <YAxis hide dataKey="y" type="number" name="y_axis" />
        <ZAxis dataKey="z" type="number" name="value" range={[zMin, zMax]} />
        {groups.map((group) => (
          <Scatter
            key={group.name}
            name={group.name}
            data={group.data}
            fill={`${COLORS[group.fill]}`}
            stroke={`${COLORS.white}`}
          />
        ))}
        <ReferenceLine y={0} stroke={`${COLORS['gray.300']}`} />
        <ReferenceLine x={0} stroke={`${COLORS['gray.300']}`} />
        {TooltipContent && <Tooltip content={<TooltipContent />} />}
      </ScatterChart>
      {yAxisLabel ? (
        <Box
          display="flex"
          justifyContent="space-around"
          position="absolute"
          top="50%"
          left="0"
          transform="translateY(-50%) scale(-1, -1)"
          margin="0"
          writingMode="vertical-rl"
          textOrientation="mixed"
          height="100%"
          pY="20px"
        >
          {axisLabelLow && (
            <Text fontSize="10px" color="gray.800">
              {axisLabelLow}
            </Text>
          )}
          <Text fontSize="10px" fontWeight="bold">
            {yAxisLabel}
          </Text>
          {axisLabelHigh && (
            <Text fontSize="10px" color="gray.800">
              {axisLabelHigh}
            </Text>
          )}
        </Box>
      ) : null}
      {xAxisLabel ? (
        <Box
          position="absolute"
          bottom="0"
          left="50%"
          transform="translateX(-50%)"
          display="flex"
          justifyContent="space-around"
          pX="20px"
          width="100%"
        >
          {axisLabelLow && (
            <Text fontSize="10px" color="gray.800">
              {axisLabelLow}
            </Text>
          )}
          <Text fontSize="10px" fontWeight="bold">
            {xAxisLabel}
          </Text>
          {axisLabelHigh && (
            <Text fontSize="10px" color="gray.800">
              {axisLabelHigh}
            </Text>
          )}
        </Box>
      ) : null}
    </Box>
  );
};

export { DistributedScatterChart };
