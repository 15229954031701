import { ResponsiveProp, ResponsivePropKeys } from 'styled';
import { getSortedThemeBreakpointsArrayMap } from '../resolveResponsiveProp';

/**
 *
 * @description
 * This function accepts an object as first param, and a list of keys as second param.
 * The result with be a full with all second params keys.
 *
 * @example
 * ```ts
 * const KEYS = ['base', 'xs', 'sm', 'md'];
 *
 * const obj = {
 *  base: '30px',
 *  sm: '50px',
 * };
 *
 * expect(composeObjectByKeys(obj, KEYS)).toMatchObject({
 *  base: '30px',
 *  xs: '30px',
 *  sm: '50px',
 *  md: '50px',
 * });
 *
 */

export const composeObjectByKeys = <ValueType = unknown, BreakpointKey = unknown>(
  responsiveProp: Partial<Record<keyof BreakpointKey, ValueType>>,
  breakpointArrayMap: Array<keyof BreakpointKey>
): Record<keyof BreakpointKey, ValueType> => {
  const result: Record<keyof BreakpointKey, ValueType> = {} as Record<keyof BreakpointKey, ValueType>;

  for (let i = 0; i < breakpointArrayMap.length; i++) {
    const key = breakpointArrayMap[i];
    const prevKey = i > 0 ? breakpointArrayMap[i - 1] : key;

    if (responsiveProp instanceof Object || typeof responsiveProp === 'object') {
      const obj = responsiveProp as Record<keyof BreakpointKey, ValueType>;
      result[key] = (obj[key] as ValueType) || (obj[prevKey] as ValueType);
    } else {
      result[key] = responsiveProp as ValueType;
    }
  }

  return result;
};

/**
 *
 * @description
 * This function accepts a responsive prop as param, and returns a responsive prop with all the theme breakpoints keys filled with their corresponding values.
 *
 * @example
 * ```ts
 * expect(getFullResponsiveObj({initial: '10px', sm: '35px'})).toEqual({initial: '10px', xs: '10px', sm: '35px', md: '35px'})
 * ```
 */
export const getFullResponsiveObj = <T>(responsiveProp: ResponsiveProp<T>): Record<ResponsivePropKeys, T> => {
  const sortedThemeBreakpointsMap = getSortedThemeBreakpointsArrayMap();
  const sortedBreakpointKeys = Array.from(sortedThemeBreakpointsMap.values()).map((item) => item[0]);

  return composeObjectByKeys(responsiveProp, sortedBreakpointKeys);
};
