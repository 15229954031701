import { FunctionComponent } from 'react';
import * as S from './Stack.theme';

type StackProps = S.StyledStackProps;

export const Stack: FunctionComponent<StackProps> = ({ children, direction = 'row', ...rest }) => (
  <S.Stack direction={direction} {...rest}>
    {children}
  </S.Stack>
);
