import { css, resolveResponsiveProp, SerializedStyles, FlexProps } from 'styled';

export const getFlexProps = (props: FlexProps): SerializedStyles => {
  const { flex, flexBasis, flexDirection, flexFlow, flexWrap, flexShrink, flexGrow, alignItems, justifyContent } =
    props;

  return css`
    ${flex ? resolveResponsiveProp({ propName: 'flex', value: flex }) : undefined}
    ${flexBasis ? resolveResponsiveProp({ propName: 'flex-basis', value: flexBasis }) : undefined}
    ${flexDirection ? resolveResponsiveProp({ propName: 'flex-direction', value: flexDirection }) : undefined}
    ${flexFlow ? resolveResponsiveProp({ propName: 'flex-flow', value: flexFlow }) : undefined}
    ${flexWrap ? resolveResponsiveProp({ propName: 'flex-wrap', value: flexWrap }) : undefined}
    ${typeof flexShrink !== 'undefined'
      ? resolveResponsiveProp({ propName: 'flex-shrink', value: flexShrink })
      : undefined}
    ${flexGrow ? resolveResponsiveProp({ propName: 'flex-grow', value: flexGrow }) : undefined}
    ${alignItems ? resolveResponsiveProp({ propName: 'align-items', value: alignItems }) : undefined}
    ${justifyContent ? resolveResponsiveProp({ propName: 'justify-content', value: justifyContent }) : undefined}
  `;
};
