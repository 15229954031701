import React, { Suspense, useEffect } from 'react';
import { NonEmptyArray } from '../../utilityTypes';
import * as S from './LanguageSwitcher.theme';
import { Icon } from '../icon/Icon';
import { AvailableLocales } from 'providers';
import { useSelect } from 'components';

type FlagName = 'ca' | 'es' | 'fr' | 'gb' | 'gr' | 'it' | 'pt';

type LanguageOptionType = { value: AvailableLocales; flag: FlagName };

export interface LanguageSwitcherProps {
  readonly position: 'top' | 'bottom';
  readonly defaultValue: AvailableLocales;
  options: NonEmptyArray<LanguageOptionType>;
  onChange: (locale: AvailableLocales) => void;
}

export const Flag: (p: { readonly flagName: FlagName }) => JSX.Element = ({ flagName }) => {
  const loadComponent = (name: FlagName): React.LazyExoticComponent<React.ComponentType<unknown>> =>
    React.lazy(() => import(`./svg/flags/${name}.tsx`));
  const Component = loadComponent(flagName);
  return (
    <Suspense fallback={null}>
      <Component />
    </Suspense>
  );
};

export const LanguageSwitcher: (p: LanguageSwitcherProps) => JSX.Element = ({
  position,
  defaultValue,
  options,
  onChange,
}) => {
  const items = options.map((option) => option.value);

  const { selectedItem, isOpen, getToggleButtonProps, getListProps, getListItemProps } = useSelect({
    items: items,
    defaultSelectedItem: defaultValue,
    refKey: 'ref',
  });

  const selectedOption = options.find((option) => option.value === selectedItem) || options[0];

  useEffect(() => onChange(selectedItem || defaultValue), [selectedItem, onChange, defaultValue]);

  return (
    <S.LanguageSwitcher>
      <S.LanguageSwitcher {...getToggleButtonProps()}>
        <S.LanguageValue>
          <Flag flagName={selectedOption.flag} />
          <S.Locale>{selectedOption.value}</S.Locale>
          <S.AngleDownIcon>
            <Icon iconName={'AngleDown'} />
          </S.AngleDownIcon>
        </S.LanguageValue>
      </S.LanguageSwitcher>
      {isOpen && (
        <S.LanguageOptions position={position} {...getListProps()}>
          {options.map((option, index) => (
            <S.LanguageSwitcher key={`language-${option.value}`} {...getListItemProps({ item: option.value, index })}>
              <S.LanguageValue>
                <Flag flagName={option.flag} />
                <S.Locale>{option.value}</S.Locale>
              </S.LanguageValue>
            </S.LanguageSwitcher>
          ))}
        </S.LanguageOptions>
      )}
    </S.LanguageSwitcher>
  );
};
