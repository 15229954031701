import {
  ResponsiveProp,
  ResponsivePropObj,
  ResponsivePropKeys,
  SerializedStyles,
  css,
  BREAKPOINTS,
  DEFAULT_BREAKPOINT_KEY,
} from 'styled';

const DEFAULT_BREAKPOINT_KEY_VALUE = 0;

type ComposePropNameWithValueParams = {
  propName: string;
  value: string | number;
  themeSlice?: Record<string, string | number>;
  unitSize?: string;
};

const composePropNameWithValue = ({
  propName,
  value,
  themeSlice,
  unitSize,
}: ComposePropNameWithValueParams): SerializedStyles => css`
  ${`${propName}: ${themeSlice ? (themeSlice[value] ? `${themeSlice[value]}${unitSize}` : value) : value};`}
`;

/**
 *
 * @returns
 * MAY BE NOT ORDERED
 * 0: {"_" => 0}
 * 2: {"sm" => 768}
 * 1: {"xs" => 480}
 * 3: {"md" => 1024}
 */
export const getThemeBreakpointsMap = (): Map<ResponsivePropKeys, number> => {
  const breakpointsMap = new Map();
  // this is needed for the default value
  breakpointsMap.set(DEFAULT_BREAKPOINT_KEY, DEFAULT_BREAKPOINT_KEY_VALUE);

  const breakpointsEntries = Object.entries(BREAKPOINTS);

  for (let i = 0; i < breakpointsEntries.length; i++) {
    breakpointsMap.set(breakpointsEntries[i][0], breakpointsEntries[i][1]);
  }

  return breakpointsMap;
};

/**
 * @returns
 * ORDERED by breakpoint value
 * 0: {"_" => 0}
 * 1: {"xs" => 480}
 * 2: {"sm" => 768}
 * 3: {"md" => 1024}
 */
export const getSortedThemeBreakpointsArrayMap = (): [ResponsivePropKeys, number][] => {
  const breakpointsMap = getThemeBreakpointsMap();
  const orderedMapBreakpoints = [...breakpointsMap.entries()].sort((a, b) => (a[1] > b[1] ? 1 : -1));
  return orderedMapBreakpoints;
};

type ResolveResponsivePropParams<T> = {
  propName: string;
  value: ResponsiveProp<T>;
  themeSlice?: Record<string, string | number>;
  unitSize?: string;
  resolverFn?: (value: T, breakpointKey: ResponsivePropKeys) => SerializedStyles;
};

/**
 *
 * @param {string} propName - css prop name in kebab-case(eg. "background-color")
 * @param {object} value - responsive object(eg. { '_': 'red', 'xs': 'green', 'md': 'yellow'})
 * @param {object} themeSlice - theme values where the responsiveProp value can be retrived from (eg. theme.colors)
 * @param {string} unitSize - css unit size if needed to be added to theme values(eg px, rem, ...)
 * @returns SerializedStyles
 *
 * @example
 * resolveResponsiveProp({ propname: "font-size", value: { _: "xs", sm: "l", md: "xl" }, themeSlice: theme.typography, unitSize: 'px' })
 *
 * resolveResponsiveProp({ propName: "width", value: { _: "100px", sm: "200px", md: "300px" }})
 */
export const resolveResponsiveProp = <T>({
  propName,
  value,
  themeSlice,
  unitSize,
  resolverFn,
}: ResolveResponsivePropParams<T>): SerializedStyles => {
  /**
   * value param can be either an object or a string,
   * checking against it if it's an object is not enough to avoid TS complaining about accessing its keys,
   * this is the reasons why in the following code there are some asserts(as in TS)
   */

  /**
   * check if value param is an object, if so it means it's a responsive props.
   *
   * example:
   * width={{ '_': '100px', 'xs': '200px', 'md': '300px' }}
   */
  if (value instanceof Object || typeof value === 'object') {
    const sortedThemeBreakpointsMap = getSortedThemeBreakpointsArrayMap();
    const cssArray: SerializedStyles[] = [];
    sortedThemeBreakpointsMap.forEach((val) => {
      const breakpointKey = val[0]; // _ | xs | sm | ...
      const breakpointValue = val[1]; // 0 | 480 | 768, ...
      const obj = value as ResponsivePropObj<T>;

      if (breakpointValue === DEFAULT_BREAKPOINT_KEY_VALUE) {
        const resolvedValue = resolverFn
          ? resolverFn(obj[breakpointKey] as T, val[0])
          : composePropNameWithValue({
              propName,
              value: obj[breakpointKey] as unknown as string | number,
              themeSlice,
              unitSize,
            });
        cssArray.push(resolvedValue);
      } else if (obj[breakpointKey]) {
        cssArray.push(css`
          @media (min-width: ${breakpointValue}px) {
            ${resolverFn
              ? resolverFn(obj[breakpointKey] as T, val[0])
              : composePropNameWithValue({
                  propName,
                  value: obj[breakpointKey] as unknown as string | number,
                  themeSlice,
                  unitSize,
                })}
          }
        `);
      }
    });

    return css`
      ${cssArray}
    `;
  }

  /**
   * if it's not an object, just return its value
   *
   * example:
   * width="100px"
   */
  return resolverFn
    ? resolverFn(value, DEFAULT_BREAKPOINT_KEY)
    : composePropNameWithValue({ propName, value: value as unknown as number | string, themeSlice, unitSize });
};
