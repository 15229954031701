import { LiteralUnion } from 'prettier';
import { lazy, Suspense } from 'react';
import { Colors } from 'styled';
import { useTheme } from 'styled';

/**
 * TODO: to be discussed, the whole implementation is temporary
 */

export type IconName =
  | 'AngleDown'
  | 'Cross'
  | 'Gear'
  | 'Selector'
  | 'Tick'
  | 'User'
  | 'Device'
  | 'ArrowUp'
  | 'ArrowDown'
  | 'ArrowRight'
  | 'Staff'
  | 'Trash'
  | 'Pencil'
  | 'Logout'
  | 'Linechart'
  | 'Plus'
  | 'Search'
  | 'Close'
  | 'Info';
export type IconSvgProps = {
  size?: number;
  color?: string;
};

type IconProps = IconSvgProps & {
  iconName: IconName;
  color?: LiteralUnion<Colors, string>;
};

export const Icon = ({ iconName, color = 'blue.900', size = 14 }: IconProps): JSX.Element => {
  const loadComponent = (name: IconName): React.LazyExoticComponent<React.ComponentType<IconSvgProps>> =>
    lazy(() => import(`./icons/${name}.tsx`));

  const Component = loadComponent(iconName);

  const { colors } = useTheme();

  return (
    <Suspense fallback={null}>
      <Component color={colors?.[color as Colors] || color} size={size} />
    </Suspense>
  );
};
