/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { styled, css, Colors } from 'styled';
import { hexToRgba } from 'utils/hex-to-rgba';

export type StyledLoaderProps = { color?: Colors; size?: number };

export const defaultProps: Required<StyledLoaderProps> = {
  size: 50,
  color: 'blue.500',
};

export const Ring = styled.div<StyledLoaderProps>`
  ${({ theme, size = defaultProps.size, color = defaultProps.color }) => css`
    position: relative;
    width: ${size}px;
    height: ${size}px;
    border: ${size / 10}px solid ${theme.colors[color]};
    border-right-color: transparent;
    border-radius: 50%;
    animation: loader-rotate 0.7s linear infinite;

    @keyframes loader-rotate {
      0% {
        transform: rotate(0);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  `}
`;

function getCircleAngle(size: number): string {
  return `${Math.round(size / 3)}px`;
}

function getCircleNormal(size: number): string {
  return `${Math.round(size / 4.2)}px`;
}

export const Roller = styled.div<StyledLoaderProps>`
  ${({ theme, size = defaultProps.size, color = defaultProps.color }) => css`
    position: relative;
    color: ${theme.colors[color]};
    width: ${Math.round(size / 5)}px;
    height: ${Math.round(size / 5)}px;
    border-radius: 50%;
    animation: loader-circles 1s linear infinite;

    @keyframes loader-circles {
      0% {
        box-shadow: 0 -${getCircleAngle(size)} 0 0 ${hexToRgba(theme.colors[color], 0.05)},
          ${getCircleNormal(size)} -${getCircleNormal(size)} 0 0 ${hexToRgba(theme.colors[color], 0.1)},
          ${getCircleAngle(size)} 0 0 0 ${hexToRgba(theme.colors[color], 0.2)},
          ${getCircleNormal(size)} ${getCircleNormal(size)} 0 0 ${hexToRgba(theme.colors[color], 0.3)},
          0 ${getCircleAngle(size)} 0 0 ${hexToRgba(theme.colors[color], 0.4)},
          -${getCircleNormal(size)} ${getCircleNormal(size)} 0 0 ${hexToRgba(theme.colors[color], 0.6)},
          -${getCircleAngle(size)} 0 0 0 ${hexToRgba(theme.colors[color], 0.8)},
          -${getCircleNormal(size)} -${getCircleNormal(size)} 0 0 ${theme.colors[color]};
      }
      12.5% {
        box-shadow: 0 -${getCircleAngle(size)} 0 0 ${theme.colors[color]},
          ${getCircleNormal(size)} -${getCircleNormal(size)} 0 0 ${hexToRgba(theme.colors[color], 0.05)},
          ${getCircleAngle(size)} 0 0 0 ${hexToRgba(theme.colors[color], 0.1)},
          ${getCircleNormal(size)} ${getCircleNormal(size)} 0 0 ${hexToRgba(theme.colors[color], 0.2)},
          0 ${getCircleAngle(size)} 0 0 ${hexToRgba(theme.colors[color], 0.3)},
          -${getCircleNormal(size)} ${getCircleNormal(size)} 0 0 ${hexToRgba(theme.colors[color], 0.4)},
          -${getCircleAngle(size)} 0 0 0 ${hexToRgba(theme.colors[color], 0.6)},
          -${getCircleNormal(size)} -${getCircleNormal(size)} 0 0 ${hexToRgba(theme.colors[color], 0.8)};
      }
      25% {
        box-shadow: 0 -${getCircleAngle(size)} 0 0 ${hexToRgba(theme.colors[color], 0.8)},
          ${getCircleNormal(size)} -${getCircleNormal(size)} 0 0 ${theme.colors[color]},
          ${getCircleAngle(size)} 0 0 0 ${hexToRgba(theme.colors[color], 0.05)},
          ${getCircleNormal(size)} ${getCircleNormal(size)} 0 0 ${hexToRgba(theme.colors[color], 0.1)},
          0 ${getCircleAngle(size)} 0 0 ${hexToRgba(theme.colors[color], 0.2)},
          -${getCircleNormal(size)} ${getCircleNormal(size)} 0 0 ${hexToRgba(theme.colors[color], 0.3)},
          -${getCircleAngle(size)} 0 0 0 ${hexToRgba(theme.colors[color], 0.4)},
          -${getCircleNormal(size)} -${getCircleNormal(size)} 0 0 ${hexToRgba(theme.colors[color], 0.6)};
      }
      37.5% {
        box-shadow: 0 -${getCircleAngle(size)} 0 0 ${hexToRgba(theme.colors[color], 0.6)},
          ${getCircleNormal(size)} -${getCircleNormal(size)} 0 0 ${hexToRgba(theme.colors[color], 0.8)},
          ${getCircleAngle(size)} 0 0 0 ${theme.colors[color]},
          ${getCircleNormal(size)} ${getCircleNormal(size)} 0 0 ${hexToRgba(theme.colors[color], 0.05)},
          0 ${getCircleAngle(size)} 0 0 ${hexToRgba(theme.colors[color], 0.1)},
          -${getCircleNormal(size)} ${getCircleNormal(size)} 0 0 ${hexToRgba(theme.colors[color], 0.2)},
          -${getCircleAngle(size)} 0 0 0 ${hexToRgba(theme.colors[color], 0.3)},
          -${getCircleNormal(size)} -${getCircleNormal(size)} 0 0 ${hexToRgba(theme.colors[color], 0.4)};
      }
      50% {
        box-shadow: 0 -${getCircleAngle(size)} 0 0 ${hexToRgba(theme.colors[color], 0.4)},
          ${getCircleNormal(size)} -${getCircleNormal(size)} 0 0 ${hexToRgba(theme.colors[color], 0.6)},
          ${getCircleAngle(size)} 0 0 0 ${hexToRgba(theme.colors[color], 0.8)},
          ${getCircleNormal(size)} ${getCircleNormal(size)} 0 0 ${theme.colors[color]},
          0 ${getCircleAngle(size)} 0 0 ${hexToRgba(theme.colors[color], 0.05)},
          -${getCircleNormal(size)} ${getCircleNormal(size)} 0 0 ${hexToRgba(theme.colors[color], 0.1)},
          -${getCircleAngle(size)} 0 0 0 ${hexToRgba(theme.colors[color], 0.2)},
          -${getCircleNormal(size)} -${getCircleNormal(size)} 0 0 ${hexToRgba(theme.colors[color], 0.3)};
      }
      62.5% {
        box-shadow: 0 -${getCircleAngle(size)} 0 0 ${hexToRgba(theme.colors[color], 0.3)},
          ${getCircleNormal(size)} -${getCircleNormal(size)} 0 0 ${hexToRgba(theme.colors[color], 0.4)},
          ${getCircleAngle(size)} 0 0 0 ${hexToRgba(theme.colors[color], 0.6)},
          ${getCircleNormal(size)} ${getCircleNormal(size)} 0 0 ${hexToRgba(theme.colors[color], 0.8)},
          0 ${getCircleAngle(size)} 0 0 ${theme.colors[color]},
          -${getCircleNormal(size)} ${getCircleNormal(size)} 0 0 ${hexToRgba(theme.colors[color], 0.05)},
          -${getCircleAngle(size)} 0 0 0 ${hexToRgba(theme.colors[color], 0.1)},
          -${getCircleNormal(size)} -${getCircleNormal(size)} 0 0 ${hexToRgba(theme.colors[color], 0.2)};
      }
      75% {
        box-shadow: 0 -${getCircleAngle(size)} 0 0 ${hexToRgba(theme.colors[color], 0.2)},
          ${getCircleNormal(size)} -${getCircleNormal(size)} 0 0 ${hexToRgba(theme.colors[color], 0.3)},
          ${getCircleAngle(size)} 0 0 0 ${hexToRgba(theme.colors[color], 0.4)},
          ${getCircleNormal(size)} ${getCircleNormal(size)} 0 0 ${hexToRgba(theme.colors[color], 0.6)},
          0 ${getCircleAngle(size)} 0 0 ${hexToRgba(theme.colors[color], 0.8)},
          -${getCircleNormal(size)} ${getCircleNormal(size)} 0 0 ${theme.colors[color]},
          -${getCircleAngle(size)} 0 0 0 ${hexToRgba(theme.colors[color], 0.05)},
          -${getCircleNormal(size)} -${getCircleNormal(size)} 0 0 ${hexToRgba(theme.colors[color], 0.1)};
      }
      87.5% {
        box-shadow: 0 -${getCircleAngle(size)} 0 0 ${hexToRgba(theme.colors[color], 0.1)},
          ${getCircleNormal(size)} -${getCircleNormal(size)} 0 0 ${hexToRgba(theme.colors[color], 0.2)},
          ${getCircleAngle(size)} 0 0 0 ${hexToRgba(theme.colors[color], 0.3)},
          ${getCircleNormal(size)} ${getCircleNormal(size)} 0 0 ${hexToRgba(theme.colors[color], 0.4)},
          0 ${getCircleAngle(size)} 0 0 ${hexToRgba(theme.colors[color], 0.6)},
          -${getCircleNormal(size)} ${getCircleNormal(size)} 0 0 ${hexToRgba(theme.colors[color], 0.8)},
          -${getCircleAngle(size)} 0 0 0 ${theme.colors[color]},
          -${getCircleNormal(size)} -${getCircleNormal(size)} 0 0 ${hexToRgba(theme.colors[color], 0.05)};
      }
      100% {
        box-shadow: 0 -${getCircleAngle(size)} 0 0 ${hexToRgba(theme.colors[color], 0.05)},
          ${getCircleNormal(size)} -${getCircleNormal(size)} 0 0 ${hexToRgba(theme.colors[color], 0.1)},
          ${getCircleAngle(size)} 0 0 0 ${hexToRgba(theme.colors[color], 0.2)},
          ${getCircleNormal(size)} ${getCircleNormal(size)} 0 0 ${hexToRgba(theme.colors[color], 0.3)},
          0 ${getCircleAngle(size)} 0 0 ${hexToRgba(theme.colors[color], 0.4)},
          -${getCircleNormal(size)} ${getCircleNormal(size)} 0 0 ${hexToRgba(theme.colors[color], 0.6)},
          -${getCircleAngle(size)} 0 0 0 ${hexToRgba(theme.colors[color], 0.8)},
          -${getCircleNormal(size)} -${getCircleNormal(size)} 0 0 ${theme.colors[color]};
      }
    }
  `}
`;
