import { FunctionComponent } from 'react';
import { ThemeProvider as Provider, ThemeProviderProps } from '@emotion/react';
import { GlobalStyles } from './GlobalStyles';

export const ThemeProvider: FunctionComponent<ThemeProviderProps> = ({ children, theme }) => (
  <Provider theme={theme}>
    <GlobalStyles />
    {children}
  </Provider>
);
