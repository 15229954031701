import { FunctionComponent } from 'react';
import { theme, BaseProps } from 'styled';
import { Box } from '../box';

export type CardProps = BaseProps;

const config: CardProps = {
  minWidth: '275px',
  borderRadius: '8px',
  boxShadow: theme.boxShadows.sm,
  bgColor: 'white',
  fontFamily: theme.typography.fontFamilies.default,
  overflow: 'hidden',
  border: `1px solid ${theme.colors['gray.300']}`,
  p: '15px',
};

const Card: FunctionComponent<CardProps> = ({ children, ...rest }) => (
  <Box {...config} {...rest}>
    {children}
  </Box>
);

export { Card };
