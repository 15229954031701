import { COLORS } from './colors';

export const TYPOGRAPHY = {
  fontFamilies: { default: "'Lato', sans-serif" },
  fontWeights: {
    normal: 400,
    bold: 700,
  },
  fontSizes: {
    '2xl': 32,
    xl: 28,
    lg: 24,
    md: 16,
    sm: 14,
    xs: 12,
    '2xs': 10,
  },
  colors: {
    default: COLORS['blue.900'],
    light: COLORS['gray.800'],
    xlight: COLORS['gray.600'],
    danger: COLORS['red.400'],
    primary: COLORS['blue.500'],
  },
} as const;

export type FontSizes = keyof typeof TYPOGRAPHY['fontSizes'];

export type FontColors = keyof typeof TYPOGRAPHY['colors'];

export type FontWeights = keyof typeof TYPOGRAPHY['fontWeights'];
