import { FunctionComponent, useMemo, useReducer } from 'react';
import { FiltersContext, FiltersContextShape } from './FiltersContext';
import { filterReducer, ACTION_TYPE, FiltersState } from './FiltersProvider.reducer';
import { getComparisonGroupColor } from './FiltersContext.utils';
import { getDateRangeByOptionType, formatDateRange, getComparisonDateRangeByOptionType } from 'components/date-picker';
import { G } from 'enums/global';

const DEFAULT_PERIOD_OPTION: FiltersState['datePickerState']['mainDateRangeOptionValue'] = 'last30days';

const DEFAULT_COMPARISON_PERIOD_OPTION: FiltersState['datePickerState']['comparisonDateRangeOptionValue'] =
  'previousperiod';

const defaultState = (): FiltersState => {
  const datePickerDateRange = getDateRangeByOptionType(DEFAULT_PERIOD_OPTION);
  const formattedDateRange = formatDateRange(datePickerDateRange);
  const brandId = Number(localStorage.getItem(G.localStorage.SESSION_BRAND));

  const storedGroups = localStorage.getItem(G.localStorage.SESSION_GROUPS);
  const groupsIds = storedGroups ? storedGroups.split(',').map((val) => parseInt(val)) : [];

  const datePickerComparisonDateRange =
    groupsIds.length > 0
      ? null
      : getComparisonDateRangeByOptionType(DEFAULT_COMPARISON_PERIOD_OPTION, datePickerDateRange);

  const formattedComparisondDateRange = formatDateRange(datePickerComparisonDateRange);

  return {
    main_period_from: formattedDateRange.startDate || '',
    main_period_to: formattedDateRange.endDate || '',
    comparison_period_from: formattedComparisondDateRange.startDate || '',
    comparison_period_to: formattedComparisondDateRange.endDate || '',
    group_ids: groupsIds,
    brand_id: brandId,
    datePickerState: {
      mainDateRange: datePickerDateRange,
      comparisonDateRange: datePickerComparisonDateRange,
      mainDateRangeOptionValue: DEFAULT_PERIOD_OPTION,
      comparisonDateRangeOptionValue: DEFAULT_COMPARISON_PERIOD_OPTION,
      isComparisonActive: datePickerComparisonDateRange ? true : false,
      isComparisonDisabled: groupsIds.length !== 0,
    },
  };
};

export const FiltersProvider: FunctionComponent = ({ children }) => {
  const [state, dispatch] = useReducer(filterReducer, defaultState());

  const value = useMemo(() => state, [state]);

  const addGroupIds: FiltersContextShape['addGroupIds'] = (groupIds) =>
    dispatch({ type: ACTION_TYPE.ADD_GROUPS, payload: { groupIds } });

  const removeGroupIds: FiltersContextShape['removeGroupIds'] = (groupIds) =>
    dispatch({ type: ACTION_TYPE.REMOVE_GROUPS, payload: { groupIds } });

  const setGroupIds: FiltersContextShape['setGroupIds'] = (groupIds) =>
    dispatch({
      type: ACTION_TYPE.SET_GROUPS,
      payload: { groupIds },
    });

  const setBrandId: FiltersContextShape['setBrandId'] = (brandId) =>
    dispatch({
      type: ACTION_TYPE.SET_BRAND,
      payload: { brandId },
    });

  const setDateRange: FiltersContextShape['setDateRange'] = (dateRange) =>
    dispatch({
      type: ACTION_TYPE.SET_DATE_RANGE,
      payload: dateRange,
    });

  const setComparisonDateRange: FiltersContextShape['setComparisonDateRange'] = (dateRange) =>
    dispatch({
      type: ACTION_TYPE.SET_COMPARISON_DATE_RANGE,
      payload: dateRange,
    });

  const setDatePickerState: FiltersContextShape['setDatePickerState'] = (datePickerState) =>
    dispatch({
      type: ACTION_TYPE.SET_DATEPICKER_STATE,
      payload: { datePickerState },
    });

  return (
    <FiltersContext.Provider
      value={{
        filters: value,
        addGroupIds,
        removeGroupIds,
        setGroupIds,
        setBrandId,
        setDateRange,
        setComparisonDateRange,
        setDatePickerState,
        getComparisonGroupColor,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};
