/**
 * @info
 * --------------------------------------
 * THIS FILE IS GENERATED AUTOMATICALLY
 * MODIFY THIS AT YOUR OWN RISK !!
 * --------------------------------------
 *
 **/
export const COLORS = {
  transparent: 'transparent',
  current: 'currentColor',
  white: '#fff',
  black: '#000',
  'cyan.500': '#2AC3EC',
  'purple.500': '#B866FF',
  'purple.800': '#7B83C3',
  'blue.500': '#4A93F9',
  'blue.900': '#283648',
  'navy.100': '#F8F8FA',
  'navy.200': '#F3F4F6',
  'navy.300': '#E6E8ED',
  'navy.400': '#B5BAC8',
  'navy.500': '#838CA3',
  'navy.600': '#515E7F',
  'navy.700': '#36456B',
  'navy.800': '#071948',
  'gray.100': '#F1F3F5',
  'gray.200': '#E9ECF2',
  'gray.300': '#DCE0E8',
  'gray.600': '#A5AABE',
  'gray.800': '#8E93AB',
  'yellow.700': '#D4DF5C',
  'orange.400': '#FDA931',
  'green.400': '#56C699',
  'red.400': '#F96B6B',
} as const;

export type Colors = keyof typeof COLORS;
