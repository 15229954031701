import { DatePickerState } from './DatePicker.types';

import { getComparisonDateRangeByOptionType, getDateRangeByOptionType } from './DatePicker.utils';

export const ACTION_TYPE = {
  SET_MAIN_DATA_RANGE_OPTION_VALUE: '@datepicker/SET_MAIN_DATA_RANGE_OPTION_VALUE',
  SET_COMPARISON_DATE_RANGE_OPTION_VALUE: '@datepicker/SET_COMPARISON_DATE_RANGE_OPTION_VALUE',
  SET_MAIN_DATE_RANGE: '@datepicker/SET_MAIN_DATE_RANGE',
  SET_COMPARISON_DATE_RANGE: '@datepicker/SET_COMPARISON_DATE_RANGE',
  SET_IS_COMPARISON_ACTIVE: '@datepicker/SET_IS_COMPARISON_ACTIVE',
  SET_FOCUSED_CALENDAR_INPUT: '@datepicker/SET_FOCUSED_CALENDAR_INPUT',
  SET_FOCUSED_DATE_INPUT: '@datepicker/SET_FOCUSED_DATE_INPUT',
  SET_ACTIVE_DATE_RANGE_TYPE: '@datepicker/SET_ACTIVE_DATE_RANGE_TYPE',
} as const;

type SetMainDataRangeOptionValueAction = {
  type: typeof ACTION_TYPE.SET_MAIN_DATA_RANGE_OPTION_VALUE;
  payload: {
    option: DatePickerState['mainDateRangeOptionValue'];
  };
};
type SetComparisonDataRangeOptionValueAction = {
  type: typeof ACTION_TYPE.SET_COMPARISON_DATE_RANGE_OPTION_VALUE;
  payload: {
    option: DatePickerState['comparisonDateRangeOptionValue'];
  };
};
type SetMainDateRangeAction = {
  type: typeof ACTION_TYPE.SET_MAIN_DATE_RANGE;
  payload: {
    dateRange: DatePickerState['mainDateRange'];
  };
};
type SetComparisonDateRangeAction = {
  type: typeof ACTION_TYPE.SET_COMPARISON_DATE_RANGE;
  payload: {
    dateRange: DatePickerState['comparisonDateRange'];
  };
};
type SetIsComparisonActiveAction = {
  type: typeof ACTION_TYPE.SET_IS_COMPARISON_ACTIVE;
  payload: {
    isEnabled: DatePickerState['isComparisonActive'];
  };
};
type SetFocusedCalendarInputAction = {
  type: typeof ACTION_TYPE.SET_FOCUSED_CALENDAR_INPUT;
  payload: {
    focusedCalendarInput: DatePickerState['focusedCalendarInput'];
  };
};
type SetFocusedDateInputAction = {
  type: typeof ACTION_TYPE.SET_FOCUSED_DATE_INPUT;
  payload: {
    focusedDateInput: DatePickerState['focusedDateInput'];
  };
};
type SetActiveDateRangeTypeAction = {
  type: typeof ACTION_TYPE.SET_ACTIVE_DATE_RANGE_TYPE;
  payload: {
    activeDateRange: DatePickerState['activeDateRangeType'];
  };
};

type DatePickerAction =
  | SetMainDataRangeOptionValueAction
  | SetComparisonDataRangeOptionValueAction
  | SetMainDateRangeAction
  | SetComparisonDateRangeAction
  | SetFocusedCalendarInputAction
  | SetFocusedDateInputAction
  | SetIsComparisonActiveAction
  | SetActiveDateRangeTypeAction;

export const DatePickerReducer = (state: DatePickerState, action: DatePickerAction): DatePickerState => {
  switch (action.type) {
    case ACTION_TYPE.SET_ACTIVE_DATE_RANGE_TYPE: {
      const { activeDateRange } = action.payload;
      return { ...state, activeDateRangeType: activeDateRange };
    }
    case ACTION_TYPE.SET_MAIN_DATE_RANGE: {
      const { dateRange } = action.payload;
      return { ...state, mainDateRange: dateRange };
    }
    case ACTION_TYPE.SET_COMPARISON_DATE_RANGE: {
      const { dateRange } = action.payload;
      return { ...state, comparisonDateRange: dateRange };
    }
    case ACTION_TYPE.SET_FOCUSED_DATE_INPUT: {
      const { focusedDateInput } = action.payload;
      return { ...state, focusedDateInput };
    }
    case ACTION_TYPE.SET_FOCUSED_CALENDAR_INPUT: {
      const { focusedCalendarInput } = action.payload;
      return { ...state, focusedCalendarInput };
    }
    case ACTION_TYPE.SET_IS_COMPARISON_ACTIVE: {
      const { isEnabled } = action.payload;
      const { comparisonDateRangeOptionValue, comparisonDateRange, mainDateRange } = state;
      if (isEnabled) {
        return {
          ...state,
          isComparisonActive: true,
          activeDateRangeType: 'comparison',
          focusedDateInput: 'startDateComparison',
          //if user enable comparison, calculate its range date
          comparisonDateRange:
            comparisonDateRangeOptionValue === 'custom'
              ? comparisonDateRange
              : getComparisonDateRangeByOptionType(comparisonDateRangeOptionValue, mainDateRange),
        };
      }

      return {
        ...state,
        isComparisonActive: false,
        activeDateRangeType: 'main',
        focusedDateInput: 'startDateMain',
        //if user disable comparison, reset its date range
        comparisonDateRange: { startDate: null, endDate: null },
      };
    }
    case ACTION_TYPE.SET_MAIN_DATA_RANGE_OPTION_VALUE: {
      const { option } = action.payload;
      let newState: DatePickerState = { ...state, mainDateRangeOptionValue: option };

      // if the date range is not custom, we need to recalculate it
      if (option !== 'custom') {
        const newDateRange = getDateRangeByOptionType(option);
        newState = { ...newState, mainDateRange: newDateRange };

        const { comparisonDateRangeOptionValue } = state;
        // if main option value changes and the comparison date range is not custom, we need to recalculate the comparison date range
        if (comparisonDateRangeOptionValue !== 'custom' && state.isComparisonActive) {
          const newComparisonDateRange = getComparisonDateRangeByOptionType(
            comparisonDateRangeOptionValue,
            newDateRange
          );
          newState = { ...newState, comparisonDateRange: newComparisonDateRange };
        }
      }

      return newState;
    }
    case ACTION_TYPE.SET_COMPARISON_DATE_RANGE_OPTION_VALUE: {
      const { option } = action.payload;
      let newState: DatePickerState = { ...state, comparisonDateRangeOptionValue: option };

      // if the comparison date range is not custom, we need to recalculate it
      if (option !== 'custom') {
        const { mainDateRange } = state;
        const newComparisonDateRange = getComparisonDateRangeByOptionType(option, mainDateRange);
        newState = { ...newState, comparisonDateRange: newComparisonDateRange };
      }
      return newState;
    }
    default:
      return state;
  }
};
