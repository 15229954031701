import { Box, Text } from 'components';
import { Icon } from 'components/icon';
import { useIntl, useViewportProvider } from 'providers';
import { useTheme } from 'styled';

interface Props {
  children: Array<JSX.Element> | JSX.Element;
  viewportTreshold: number;
}

const NotAvailableOnThisDevice = (): JSX.Element => {
  const { formatMessage } = useIntl();
  const { colors } = useTheme();
  return (
    <Box textAlign="center" display="flex" justifyContent="center" width="100%" w="100%" flexDirection="column">
      <Box textAlign="center" padding="20px">
        <Icon iconName="Device" color={colors['blue.500']} size={80} />
      </Box>
      <Text fontSize="s" color="gray.800" letterSpacing="1.2px" fontWeight={'bold'}>
        {formatMessage({ id: 'global.not-available-on-mobile.title' })}
      </Text>
      <Text fontSize="xs" color="gray.800" letterSpacing="1.2px" fontWeight={'bold'}>
        {formatMessage({ id: 'global.not-available-on-mobile.subtitle' })}
      </Text>
    </Box>
  );
};

/**
 * @description
 * Accept a "width" treshold and a children
 * conditionally render that children based on the treshold
 * @example
 * ```tsx
 *    <RenderAbove viewportTreshold={1300}>
 * ```
 */
export const RenderAbove = ({ children, viewportTreshold }: Props): JSX.Element => {
  const v = useViewportProvider();
  return <>{v?.vw && viewportTreshold < v.vw ? children : <NotAvailableOnThisDevice />}</>;
};
