import { FunctionComponent, MouseEventHandler, useEffect, useRef } from 'react';
import ReactDom from 'react-dom';
import { useTheme } from 'styled';
import { Box } from 'components';
import { hexToRgba } from 'utils/hex-to-rgba';
import { isMobile } from 'utils/isMobile';
import { G } from 'enums/global';

export type ModalOverlayProps = {
  isOpen: boolean;
  onClose: () => void;
  preventOnCloseBackground?: boolean;
  fullScreenOnMobile?: boolean;
  contentPosition?: 'top' | 'center';
};

const isScrollbarVisible = (): boolean => {
  return document.body.scrollHeight > window.innerHeight;
};

export const ModalOverlay: FunctionComponent<ModalOverlayProps> = ({
  children,
  isOpen,
  onClose,
  fullScreenOnMobile,
  contentPosition = 'center',
  preventOnCloseBackground = false,
}) => {
  const theme = useTheme();
  // close the modal when clicking outside the modal.
  const modalRef = useRef<HTMLDivElement>(null);

  const modalRootNode = document.getElementById(G.modals.rootId);

  const handleOnClose: MouseEventHandler<HTMLDivElement> = (e) => {
    if (e.target === modalRef.current && !preventOnCloseBackground) {
      onClose();
    }
  };

  useEffect(() => {
    const bodyNode = document.getElementsByTagName('body')[0];

    if (isOpen && isScrollbarVisible()) {
      if (!bodyNode.classList.contains(G.modals.bodyPreventScrollClassName)) {
        // this class prevents body to scroll
        bodyNode.classList.add(G.modals.bodyPreventScrollClassName);
      }

      if (!isMobile()) {
        if (!bodyNode.classList.contains(G.modals.bodyScrollbarSpaceClassName)) {
          // this class prevents body to shift when scrollbar disappears
          bodyNode.classList.add(G.modals.bodyScrollbarSpaceClassName);
        }
      }
    }

    return (): void => {
      if (
        bodyNode.classList.contains(G.modals.bodyPreventScrollClassName) ||
        bodyNode.classList.contains(G.modals.bodyScrollbarSpaceClassName)
      ) {
        // modalRootNode can be undefined because getElementById returns undefined if it doesn't find the element
        if (!modalRootNode) {
          bodyNode.classList.remove(G.modals.bodyPreventScrollClassName);
          bodyNode.classList.remove(G.modals.bodyScrollbarSpaceClassName);
        }

        // remove the class only if this is the last modal
        if (modalRootNode && modalRootNode?.childNodes.length === 0) {
          bodyNode.classList.remove(G.modals.bodyPreventScrollClassName);
          bodyNode.classList.remove(G.modals.bodyScrollbarSpaceClassName);
        }
      }
    };
  }, [isOpen, modalRootNode]);

  return isOpen
    ? ReactDom.createPortal(
        <Box
          display="flex"
          justifyContent="center"
          alignItems={contentPosition === 'center' ? 'center' : 'flex-start'}
          width="100vw"
          height="100vh"
          position="fixed"
          top={0}
          left={0}
          ref={modalRef}
          onClick={handleOnClose}
          zIndex={9999999}
          bgColor={hexToRgba(theme.colors['blue.900'], 0.3)}
          padding={fullScreenOnMobile ? { initial: '0', sm: '15px' } : { initial: '5px', sm: '15px' }}
        >
          {children}
        </Box>,
        modalRootNode || document.createElement('div')
      )
    : null;
};
