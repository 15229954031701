import { LOCALES } from './IntlProvider.data';
import { AvailableLocales, AvailableTranslationsNameSpaces } from './types';
import { G } from 'enums/global';
import { getMessagesByNameSpaces } from './IntlProvider.utils';

export const ACTION_TYPE = {
  SWITCH_LANGUAGE: 'SWITCH_LANGUAGE',
  ADD_NAMESPACES: 'ADD_NAMESPACE',
} as const;

export type State = {
  messages: Readonly<Record<string, string>>;
  locale: AvailableLocales;
  namespaces: AvailableTranslationsNameSpaces[];
};

type SwitchLanguageAction = {
  type: typeof ACTION_TYPE.SWITCH_LANGUAGE;
  payload: {
    locale: State['locale'];
  };
};

type AddNameSpace = {
  type: typeof ACTION_TYPE.ADD_NAMESPACES;
  payload: {
    namespaces: State['namespaces'];
  };
};

export type IntlReducerAction = SwitchLanguageAction | AddNameSpace;

export const IntlReducer = (state: State, action: IntlReducerAction): State => {
  switch (action.type) {
    case ACTION_TYPE.ADD_NAMESPACES: {
      const { namespaces } = action.payload;
      return {
        ...state,
        namespaces: [...state.namespaces, ...namespaces],
        messages: { ...state.messages, ...getMessagesByNameSpaces(namespaces, state.locale) },
      };
    }
    case ACTION_TYPE.SWITCH_LANGUAGE: {
      const { locale } = action.payload;
      // check if we support that language
      const isLanguageSupported = LOCALES.includes(locale);

      return {
        ...state,
        locale: isLanguageSupported ? locale : G.intl.DEFAULT_LOCALE,
        messages: { ...getMessagesByNameSpaces(state.namespaces, locale) },
      };
    }
    default:
      return state;
  }
};
