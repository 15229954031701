import { lazy, Suspense } from 'react';

type LogoName = 'UalaCompanyDashboard';

export type LogoComponentProps = {
  width?: number;
  height?: number;
};

type LogoProps = LogoComponentProps & {
  name: LogoName;
};

export const Logo = ({ name, width, height }: LogoProps): JSX.Element => {
  const loadComponent = (fileName: LogoName): React.LazyExoticComponent<React.ComponentType<LogoComponentProps>> =>
    lazy(() => import(`./logos/${fileName}.tsx`));
  const Component = loadComponent(name);
  return (
    <Suspense fallback={null}>
      <Component width={width} height={height} />
    </Suspense>
  );
};
