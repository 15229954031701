import { Box, Icon, Input, InputProps } from 'components';

export const InputSearch: (p: InputProps & { onChange: (value: string) => void; width: string }) => JSX.Element = ({
  value,
  onFocus,
  width,
  onChange,
  ...rest
}) => {
  const emptyWithoutFocus: boolean = !(value as string)?.length && !onFocus;
  const nonEmpty = (value as string)?.length;

  return (
    <Box display="flex" position="relative" flexDirection="row" width={width} alignItems="center">
      <Input
        style={{ paddingRight: '40px' }}
        width={width}
        value={value}
        onFocus={onFocus}
        onChange={onChange}
        {...rest}
      />
      {emptyWithoutFocus ? (
        <Box position="absolute" margin="auto" right="20px">
          <Icon iconName="Search" size={15} color="gray.300" />
        </Box>
      ) : null}
      {nonEmpty ? (
        <Box position="absolute" margin="auto" right="20px" onClick={(): void => onChange('')} cursor="pointer">
          <Icon iconName="Close" size={10} color="gray.400" />
        </Box>
      ) : null}
    </Box>
  );
};
