import { Box } from '../box';
import { Colors } from 'styled';
import { convertPercentValue } from 'utils/numbers';
import { LiteralUnion } from 'utilityTypes';
import { slideInLeft } from './ProgressBar.theme';

type Props = {
  progress: number;
  colorScheme?: LiteralUnion<Colors, string>;
};

const ProgressBar = ({ progress, colorScheme = 'blue.500' }: Props): JSX.Element => {
  const barPercentage = `${convertPercentValue(progress, true)}%`;
  return (
    <Box
      position="relative"
      borderRadius="5px"
      height="5px"
      display="inline-block"
      bgColor={'gray.200'}
      overflow="hidden"
      width="100%"
    >
      <Box
        position="absolute"
        borderRadius="5px"
        height="5px"
        display="inline-block"
        bgColor={colorScheme}
        width={barPercentage}
        transition="width 1s ease"
        animation={`${slideInLeft(barPercentage)} 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both`}
      />
    </Box>
  );
};
export { ProgressBar };
