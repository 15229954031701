import { http } from 'utils/http-request';
import { withCodec } from 'utils/decoder/withCodec';

import { CreateSessionData, createSessionDecoder, destroySessionDecoder } from './AuthProvider.codec';
import { getApiBasePath } from 'providers';

export type AuthCredentials = {
  email: string;
  password: string;
};

export const postSession = async (user: AuthCredentials): Promise<Response> =>
  http.post`/sessions.json`({
    body: { user },
    needsAuth: false,
    domain: getApiBasePath('BRANDS'),
  });

export const deleteSession = async (): Promise<Response> =>
  http.delete`/sessions.json`({ domain: getApiBasePath('BRANDS') });

export const requestAuth = withCodec<AuthCredentials, CreateSessionData>(createSessionDecoder)(postSession);

export const invalidateAuth = withCodec(destroySessionDecoder)(deleteSession);
