const APP_SETTINGS = (window && window.REACT_APP_SETTINGS) || {};

const isDevelopmentMode = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test';

if (isDevelopmentMode) {
  Object.keys(process.env).forEach((k?: string) => {
    if (k && /^REACT_APP/.test(k)) {
      const envValue: string | undefined = process.env[k];
      APP_SETTINGS[k.replace('REACT_APP_', '')] = envValue;
    }
  });
}

export { APP_SETTINGS, isDevelopmentMode };
