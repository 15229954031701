import { FunctionComponent } from 'react';
import { useTheme } from 'styled';

import { Box } from 'components';

type OverrideFabProps = {
  onClick: () => void;
};
const OverrideFab: FunctionComponent<OverrideFabProps> = ({ onClick, children }): JSX.Element => {
  const { boxShadows } = useTheme();
  return (
    <Box
      onClick={onClick}
      width="40px"
      height="40px"
      bgColor="blue.500"
      borderRadius="9999px"
      position="absolute"
      bottom="20px"
      right="20px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      boxShadow={boxShadows.md}
      cursor="pointer"
    >
      {children}
    </Box>
  );
};
export { OverrideFab };
