import { Box } from 'components';
import { DatePickerCalendar } from './date-picker-calendar';
import { DatePickerOptions } from './date-picker-options';
import { useDatePicker } from './useDatePicker';
import { useCallback } from 'react';
import { formatDateRange } from './DatePicker.utils';
import { DatePickerShape } from './DatePicker.types';
import {
  DEFAULT_DATE_RANGE_OPTIONS,
  DEFAULT_COMPARISON_DATE_RANGE_OPTIONS,
  DATEPICKER_DEFAULT_STATE,
  DEFAULT_NUMBER_OF_VISIBLE_MONTHS,
} from './DatePicker.config';

export type DatePickerProps = Partial<DatePickerShape>;

export const DatePicker = ({
  onCancel,
  onConfirm,
  dateFormat = 'Y-MM-DD',
  datePickerState = DATEPICKER_DEFAULT_STATE,
  periodOptions = DEFAULT_DATE_RANGE_OPTIONS,
  comparisonPeriodOptions = DEFAULT_COMPARISON_DATE_RANGE_OPTIONS,
  numberOfVisibleMonths = DEFAULT_NUMBER_OF_VISIBLE_MONTHS,
}: DatePickerProps): JSX.Element => {
  const {
    state,
    onCalendarDatesChange,
    onCalendarFocusChange,
    onComparisonDateRangeOptionChange,
    onComparisonActiveChange,
    onDateInputFocusChange,
    onMainDateRangeOptionChange,
  } = useDatePicker({ datePickerState: datePickerState || DATEPICKER_DEFAULT_STATE });

  const {
    mainDateRange,
    comparisonDateRange,
    activeDateRangeType,
    focusedCalendarInput,
    focusedDateInput,
    comparisonDateRangeOptionValue,
    mainDateRangeOptionValue,
    isComparisonActive,
    isComparisonDisabled,
  } = state;

  const handleOnConfirm = useCallback((): void => {
    onConfirm &&
      onConfirm({
        datePickerState: {
          isComparisonActive: state.isComparisonActive,
          isComparisonDisabled: state.isComparisonDisabled,
          mainDateRange: state.mainDateRange,
          mainDateRangeOptionValue: state.mainDateRangeOptionValue,
          comparisonDateRange: state.comparisonDateRange,
          comparisonDateRangeOptionValue: state.comparisonDateRangeOptionValue,
        },
        formattedDateRange: {
          mainDateRange: formatDateRange(mainDateRange, dateFormat),
          comparisonDateRange: formatDateRange(comparisonDateRange, dateFormat),
        },
      });
  }, [
    onConfirm,
    state.isComparisonActive,
    state.mainDateRange,
    state.mainDateRangeOptionValue,
    state.comparisonDateRange,
    state.comparisonDateRangeOptionValue,
    state.isComparisonDisabled,
    mainDateRange,
    dateFormat,
    comparisonDateRange,
  ]);

  return (
    <Box
      display="flex"
      flexDirection={{ initial: 'column', sm: 'row' }}
      alignItems={{ initial: 'center', sm: 'stretch' }}
    >
      <DatePickerCalendar
        activeDateRange={activeDateRangeType}
        onDatesChange={onCalendarDatesChange}
        focusedInput={focusedCalendarInput}
        onFocusChange={onCalendarFocusChange}
        mainDateRange={mainDateRange}
        comparisonDateRange={comparisonDateRange}
        numberOfVisibleMonths={numberOfVisibleMonths}
      />
      <Box paddingLeft={{ initial: '0', sm: '20px' }} paddingTop={{ initial: '30px', sm: '0' }}>
        <DatePickerOptions
          onMainDateRangeOptionChange={onMainDateRangeOptionChange}
          onComparisonDateRangeOptionChange={onComparisonDateRangeOptionChange}
          comparisonDateRangeOptionValue={comparisonDateRangeOptionValue}
          onInputFocusChange={onDateInputFocusChange}
          focusedDateInput={focusedDateInput}
          mainDateRange={mainDateRange}
          mainDateRangeOptionValue={mainDateRangeOptionValue}
          comparisonDateRange={comparisonDateRange}
          onComparisonActiveChange={onComparisonActiveChange}
          isComparisonActive={isComparisonActive}
          isComparisonDisabled={isComparisonDisabled}
          onCancel={onCancel}
          onConfirm={handleOnConfirm}
          periodOptions={periodOptions}
          comparisonPeriodOptions={comparisonPeriodOptions}
        />
      </Box>
    </Box>
  );
};
