import { useContext } from 'react';
import { FiltersContext, FiltersContextShape } from './FiltersContext';

export const useFilters = (): FiltersContextShape => {
  const context = useContext(FiltersContext);
  if (context === undefined) {
    throw new Error('useFilters must be used within FiltersProvider');
  }
  return context;
};
