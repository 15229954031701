import { forwardRef, MouseEventHandler, DetailedHTMLProps, ButtonHTMLAttributes } from 'react';
import * as S from './Button.theme';
import { Loader, Box } from 'components';

/**
 * TODO:
 * - add icon
 */

export type ButtonProps = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> &
  S.ButtonProps & {
    onClick?: MouseEventHandler<HTMLButtonElement>;
  };

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, isLoading, variant = 'solid', tone = 'primary', size = 'md', ...rest }, ref) => (
    <S.Button ref={ref} variant={variant} tone={tone} size={size} isLoading={isLoading} {...rest}>
      {children}
      {isLoading && (
        <Box
          as="span"
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
        >
          <Loader type="ring" color="white" size={20} />
        </Box>
      )}
    </S.Button>
  )
);
