import { Button, Loader, Box } from 'components';
import { useIntl } from 'providers';
import * as S from './ComponentFrame.theme';

type Maybe<A> = A | undefined;
interface Props {
  isLoading: Maybe<boolean>;
  error: unknown;
  errorMessage?: string;
  children: Array<JSX.Element> | JSX.Element | React.ReactNode[];
  height: string;
  onRetry?: () => void;
}
/**
 * @description
 * wrap a remote component with:
 * - error UX dedicated message
 * - loading Spinner
 */
export const RemoteStateComponent: (p: Props) => JSX.Element = ({
  isLoading,
  error,
  children,
  height,
  errorMessage,
  onRetry,
}) => {
  const { formatMessage } = useIntl();
  return (
    <Box height={isLoading || error ? height : undefined} display="flex">
      {isLoading && (
        <S.Loading>
          <Loader type="ring" />
        </S.Loading>
      )}
      {!isLoading && error && (
        <S.ErrorMessage>
          <p>{errorMessage ?? formatMessage({ id: 'global.error.fetch' })}</p>
          {error && onRetry && (
            <Button size="sm" variant="outline" onClick={onRetry}>
              {formatMessage({ id: 'global.retry' })}
            </Button>
          )}
        </S.ErrorMessage>
      )}
      {!isLoading && !error && children}
    </Box>
  );
};
