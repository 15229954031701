import { messages } from './IntlProvider.translations';
import { AvailableTranslationsNameSpaces, AvailableLocales } from './types';

export const getMessagesByNameSpaces = (
  namespaces: AvailableTranslationsNameSpaces[],
  locale: AvailableLocales
): Record<string, string> | void => {
  const generatedMessages = messages.global[locale];

  if (!namespaces.length) {
    return generatedMessages;
  }

  /**
   * we do not have any name spaces yet, comment this line in when we have some
   */
  // for (let i = 0; i < namespaces.length; i++) {
  //   generatedMessages = { ...generatedMessages, ...messages[namespaces[i]][locale] };
  // }

  return generatedMessages;
};
