import { useReducer, useCallback, useEffect } from 'react';
import { DatePickerReducer, ACTION_TYPE } from './DatePicker.reducer';
import { DATEPICKER_DEFAULT_STATE } from './DatePicker.config';
import { ComparisonDateRangeOption, DatePickerState, DatePickerControllerShape } from './DatePicker.types';

export type UseDatePickerParams = {
  datePickerState: {
    mainDateRange: DatePickerState['mainDateRange'];
    comparisonDateRange: DatePickerState['comparisonDateRange'];
    mainDateRangeOptionValue: DatePickerState['mainDateRangeOptionValue'];
    comparisonDateRangeOptionValue: DatePickerState['comparisonDateRangeOptionValue'];
    isComparisonActive: DatePickerState['isComparisonActive'];
    isComparisonDisabled: DatePickerState['isComparisonDisabled'];
  };
};

export const useDatePicker: (params?: UseDatePickerParams) => DatePickerControllerShape = (
  { datePickerState } = { datePickerState: DATEPICKER_DEFAULT_STATE }
) => {
  const [state, dispatch] = useReducer(DatePickerReducer, {
    activeDateRangeType: 'main',
    isComparisonActive: datePickerState.isComparisonActive,
    isComparisonDisabled: datePickerState.isComparisonDisabled,
    mainDateRange: datePickerState.mainDateRange,
    comparisonDateRange: datePickerState.comparisonDateRange,
    focusedCalendarInput: 'startDate',
    focusedDateInput: 'startDateMain',
    mainDateRangeOptionValue: datePickerState.mainDateRangeOptionValue,
    comparisonDateRangeOptionValue: datePickerState.comparisonDateRangeOptionValue,
  });
  const { activeDateRangeType, mainDateRangeOptionValue, comparisonDateRangeOptionValue, focusedDateInput } = state;

  const onCalendarDatesChange: DatePickerControllerShape['onCalendarDatesChange'] = useCallback(
    (arg) => {
      if (activeDateRangeType === 'main') {
        dispatch({ type: ACTION_TYPE.SET_MAIN_DATE_RANGE, payload: { dateRange: arg } });

        // if date range changes, set date range option to custom
        if (mainDateRangeOptionValue !== 'custom') {
          dispatch({ type: ACTION_TYPE.SET_MAIN_DATA_RANGE_OPTION_VALUE, payload: { option: 'custom' } });
        }
      }

      if (activeDateRangeType === 'comparison') {
        dispatch({ type: ACTION_TYPE.SET_COMPARISON_DATE_RANGE, payload: { dateRange: arg } });

        // if comparison date range changes, set date range comparison option to custom
        if (comparisonDateRangeOptionValue !== 'custom') {
          dispatch({ type: ACTION_TYPE.SET_COMPARISON_DATE_RANGE_OPTION_VALUE, payload: { option: 'custom' } });
        }
      }
    },
    [activeDateRangeType, comparisonDateRangeOptionValue, mainDateRangeOptionValue]
  );

  const onCalendarFocusChange: DatePickerControllerShape['onCalendarFocusChange'] = useCallback(
    (arg) => {
      // if it is null, set startDate value
      if (arg === 'startDate' || arg === null) {
        dispatch({
          type: ACTION_TYPE.SET_FOCUSED_DATE_INPUT,
          payload: { focusedDateInput: activeDateRangeType === 'main' ? 'startDateMain' : 'startDateComparison' },
        });
        return;
      }

      if (arg === 'endDate') {
        dispatch({
          type: ACTION_TYPE.SET_FOCUSED_DATE_INPUT,
          payload: { focusedDateInput: activeDateRangeType === 'main' ? 'endDateMain' : 'endDateComparison' },
        });
        return;
      }
    },
    [activeDateRangeType]
  );

  const onDateInputFocusChange: DatePickerControllerShape['onDateInputFocusChange'] = useCallback((arg) => {
    dispatch({ type: ACTION_TYPE.SET_FOCUSED_DATE_INPUT, payload: { focusedDateInput: arg } });
  }, []);

  const onComparisonActiveChange: DatePickerControllerShape['onComparisonActiveChange'] = useCallback((isEnabled) => {
    dispatch({ type: ACTION_TYPE.SET_IS_COMPARISON_ACTIVE, payload: { isEnabled } });
  }, []);

  const onMainDateRangeOptionChange: DatePickerControllerShape['onMainDateRangeOptionChange'] = useCallback(
    (option) => {
      dispatch({ type: ACTION_TYPE.SET_MAIN_DATA_RANGE_OPTION_VALUE, payload: { option } });
    },
    []
  );

  const onComparisonDateRangeOptionChange: DatePickerControllerShape['onComparisonDateRangeOptionChange'] = useCallback(
    (option: ComparisonDateRangeOption) => {
      dispatch({ type: ACTION_TYPE.SET_COMPARISON_DATE_RANGE_OPTION_VALUE, payload: { option } });
    },
    []
  );

  useEffect(() => {
    // check which date input is focused to determinate whether the user is selecting the current date range or the comparison one
    if (focusedDateInput === 'startDateMain' || focusedDateInput === 'endDateMain') {
      dispatch({ type: ACTION_TYPE.SET_ACTIVE_DATE_RANGE_TYPE, payload: { activeDateRange: 'main' } });
    } else {
      dispatch({ type: ACTION_TYPE.SET_ACTIVE_DATE_RANGE_TYPE, payload: { activeDateRange: 'comparison' } });
    }

    // set calendar focused input based on our custom date inputs(current & comparison)
    if (focusedDateInput === 'startDateMain' || focusedDateInput === 'startDateComparison') {
      dispatch({ type: ACTION_TYPE.SET_FOCUSED_CALENDAR_INPUT, payload: { focusedCalendarInput: 'startDate' } });
    } else {
      dispatch({ type: ACTION_TYPE.SET_FOCUSED_CALENDAR_INPUT, payload: { focusedCalendarInput: 'endDate' } });
    }
  }, [focusedDateInput]);

  return {
    state,
    onCalendarDatesChange,
    onCalendarFocusChange,
    onDateInputFocusChange,
    onComparisonActiveChange,
    onMainDateRangeOptionChange,
    onComparisonDateRangeOptionChange,
  };
};
