import type { CSSInterpolation, SerializedStyles } from '@emotion/serialize';
import { CSSObject, CSSProperties, Keyframes } from '@emotion/serialize';

import { LiteralUnion, UnionStringArray } from 'utilityTypes';

import { Theme } from '../theme';
import { Breakpoint, Colors, FontSizes } from '../tokens';
import { SpacingProps, OmitCssSpacingProps } from './spacingProps';
import { FlexProps, OmitFlexProps } from './flexProps';
import { GridProps, OmitGridProps } from './gridProps';
import { SizeProps, OmitSizeProps } from './sizeProps';

interface OverridedProps {
  theme?: Theme;
  color?: LiteralUnion<Colors, string>;
  backgroundColor?: LiteralUnion<Colors, string>;
  fontSize?: ResponsiveProp<LiteralUnion<FontSizes, string>>;
  display?: ResponsiveProp<CSSProperties['display']>;
  borderColor?: LiteralUnion<Colors, string>;
  borderRadius?: ResponsiveProp<string>;
}

/*
|--------------------------------------------------------------------------
| SHORTHAND PROPS
|--------------------------------------------------------------------------
*/

interface ShorthandProps {
  // background
  bgColor?: LiteralUnion<Colors, string>;
  bg?: string;

  // Width
  w?: ResponsiveProp<string>;

  // Height
  h?: ResponsiveProp<string>;
}

/*
|--------------------------------------------------------------------------
| BASE PROPS
|--------------------------------------------------------------------------
*/

export interface BaseProps
  extends OverridedProps,
    ShorthandProps,
    SpacingProps,
    FlexProps,
    GridProps,
    SizeProps,
    Omit<
      CSSProperties,
      | OmitCssSpacingProps
      | OmitFlexProps
      | OmitGridProps
      | OmitSizeProps
      | 'translate'
      | 'color'
      | 'backgroundColor'
      | 'fontSize'
      | 'width'
      | 'height'
      | 'display'
      | 'borderColor'
      | 'borderRadius'
    > {}

export type { CSSInterpolation, SerializedStyles, CSSObject, CSSProperties };

/**
 * @description
 * Provide a list of props unrelated to styling, such: `onClick`, `as`, `children` and so on.
 * Mainly for preventing unwanted variables to be rendered on CSS.
 */
export const SYSTEM_RESERVED_PROPS_LIST = [
  'className',
  'data-testid',
  'children',
  'theme',
  'onClick',
  'theme',
  'as',
  'level',
  'ref',
] as const;

/**
 * @description
 * Define `SystemReservedProps` type.
 */
export type SystemReservedProps = UnionStringArray<typeof SYSTEM_RESERVED_PROPS_LIST>;

export interface AsElement {
  as?:
    | keyof Pick<HTMLElementTagNameMap, 'span' | 'div' | 'section' | 'p' | 'article' | 'address' | 'label'>
    | keyof Pick<SVGElementTagNameMap, 'text'>;
}

export const DEFAULT_BREAKPOINT_KEY = 'initial';

export type ResponsivePropObj<T = unknown> = {
  [DEFAULT_BREAKPOINT_KEY]: T;
} & { [key in Breakpoint]?: T };

export type ResponsiveProp<T = unknown> = ResponsivePropObj<T> | T;

export type ResponsivePropKeys = keyof ResponsivePropObj<unknown>;

export type { Keyframes };
