/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { styled, css, getMarginProps, MarginProps } from 'styled';
import { lightenDarkenColor } from 'utils/lighten-darken-color';

type SizeProp = {
  size?: 'sm' | 'md' | 'lg';
};

export type StyledSelectProps = MarginProps & SizeProp;

const mapSizeToPadding = {
  sm: '6px 12px',
  md: '12px',
  lg: '12px',
};

export const Button = styled.button<StyledSelectProps>`
  ${({
    theme,
    size = 'md',
    margin,
    m,
    marginX,
    mX,
    marginY,
    mY,
    mB,
    marginBottom,
    mL,
    marginLeft,
    mR,
    marginRight,
    mT,
    marginTop,
  }) => css`
    position: relative;
    display: inline-flex;
    align-items: center;
    font-family: ${theme.typography.fontFamilies.default};
    outline: 0;
    font-size: ${theme.typography.fontSizes.md}px;
    letter-spacing: 0.72px;
    color: ${theme.typography.colors.default};
    border-radius: 21px;
    padding: ${mapSizeToPadding[size]};
    width: 100%;
    border: 1px solid ${theme.colors['gray.300']};
    background: ${theme.colors['white']};

    ${getMarginProps({
      margin,
      m,
      marginX,
      mX,
      marginY,
      mY,
      mB,
      marginBottom,
      mL,
      marginLeft,
      mR,
      marginRight,
      mT,
      marginTop,
    })};

    transition: 0.3s;

    &:hover {
      cursor: pointer;
      color: ${lightenDarkenColor(theme.typography.colors.default, -20)};
      border: 1px solid ${lightenDarkenColor(theme.colors['gray.300'], -20)};
    }

    &:active {
      color: ${lightenDarkenColor(theme.typography.colors.default, -25)};
      border: 1px solid ${lightenDarkenColor(theme.colors['gray.300'], -25)};
    }

    &:focus {
      color: ${lightenDarkenColor(theme.typography.colors.default, -30)};
      border: 1px solid ${lightenDarkenColor(theme.colors['gray.300'], -30)};
    }

    &:disabled {
      pointer-events: none;
      opacity: 0.3;
      cursor: not-allowed;
    }
  `}
`;

export const List = styled.ul`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    border-radius: 16px;
    padding: 8px 12px;
    border: 1px solid ${theme.colors['gray.300']};
    z-index: 100;
    background-color: ${theme.colors.white};

    > * + * {
      padding-top: 8px;
    }

    &:focus {
      outline: 0;
    }
  `}
`;

type ItemProps = {
  isSelected?: boolean;
  isHighlighted?: boolean;
};

export const Item = styled.li<ItemProps>`
  ${({ theme, isSelected, isHighlighted }) => css`
    width: 100%;
    font-size: ${theme.typography.fontSizes.md}px;
    color: ${theme.typography.colors.default};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
      cursor: pointer;
    }

    ${(isSelected || isHighlighted) &&
    css`
      /* TODO: replace with primary */
      color: ${theme.colors['blue.500']};
    `}
  `}
`;
