import { BREAKPOINTS } from './breakpoints';
import { css } from '../lib';
import { CSSInterpolation, SerializedStyles } from '../types';

export const MEDIA = {
  xs: (...args: Array<CSSInterpolation>): SerializedStyles => css`
    @media (min-width: ${BREAKPOINTS.xs}px) {
      ${css(...args)}
    }
  `,
  sm: (...args: Array<CSSInterpolation>): SerializedStyles => css`
    @media (min-width: ${BREAKPOINTS.sm}px) {
      ${css(...args)}
    }
  `,
  md: (...args: Array<CSSInterpolation>): SerializedStyles => css`
    @media (min-width: ${BREAKPOINTS.md}px) {
      ${css(...args)}
    }
  `,
};
